import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

/**
 * Component for informartion error
 */
export default function NoInfo({refreshFunction = null}){
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        text: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: 190,
            fontSize: 20,
            backgroundColor: 'transparent',
            color: 'rgb(var(--primary-text-color))'
        }
    }));
    const classes = useStyles();

    return (
        <p className={classes.text} >
            {t('dataFound')} <br/>
            <button style={{
                fontSize: 15,
                backgroundColor: '#ff7600',
                color: 'rgb(var(--primary-color))',
                border: 'none',
                borderRadius: 5,
                padding: 5,
                cursor: 'pointer'
            }}
            onClick={()=>{window.location.reload()}}>{t('retry')}</button> 
        </p>
    );
}