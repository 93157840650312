import React from 'react';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import NoInfo from '../utilities/NoInfo';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SurveyView from './SurveyView';

const SurveysContent = ({ data, refresh, evaluate }) => {
    const { t } = useTranslation();
    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <Container style={{marginTop: 30}}>
                {
                    (data.surveys.length > 0)?(
                        <Grid key={`gridSurveyTabContent`} container spacing={3} direction="row" justify="center" >
                        {
                            data.surveys.map((survey, i) => {
                                return (
                                    <Grid item key={`gridSurveyCardContent${i}`} index={i} xs={12} sm={6} md={4} lg={4}  >
                                        <SurveyCard key={`survey${i}`} data={survey} information={data.course_data} evaluate={evaluate} />
                                    </Grid>
                                );
                            })
                        }
                        </Grid>
                    ):null
                }
            </Container>
        </div>
    );
}

const SurveyCard = ({ data, evaluate, information }) => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        text: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: 190,
            fontSize: 20,
            color: '#37474f'
        },
        large: {
            width: 90,
            height: 90,
        },
    }));
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <Grid key={`gridCard${data.survey_registration_id}`} container spacing={3} direction="row" justify="center" >
                    {/* <Grid item xs={4} sm={5} md={4} lg={4} container alignContent="center" >
                        <Avatar className={classes.large}
                            src={Constants.base_site + 'public/img/profile/thumbnails/' + data.picture}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12}  >
                        <Typography component="h5" variant="h5">
                            {`${data.survey_name}`}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {information.teacher_name}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {information.course_name}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button fullWidth={true} variant="contained" onClick={() => evaluate(data, information.course_name)} style={{ margin: '15px auto 0px', background: '#ff7600', color: 'rgb(var(--primary-text-color))' }}>
                    {t('teacherEvaluationBtn')}
                </Button>
            </CardActions>
        </Card>
    );
}

class AttendeeSurveysView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            error: false,
            loading: true,
            status: '',
            surveys: [],
            evaluating: false,
            information: {},
            survey_body: [],
            survey_body_information: {}
        }
    }

    get_pending_surveys = () => {
        this.setState({...this.state, error: false, loading: true, surveys: [], evaluating: false, information: {},
            survey_body: [], survey_body_information: {}});
        fetch(Constants.api + 'webApp/student/get_attendee_pending_surveys', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state, loading: false, surveys: json.surveys, information: json.course_information});
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    get_survey = (survey_data, description) => {
        this.setState({...this.state, error: false, loading: true});
        fetch(Constants.api + 'webApp/student/get_survey_information', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                id_survey: survey_data.survey_id
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state,
                loading: false,
                survey_body: json,
                evaluating: true,
                survey_body_information: {
                    survey_name: survey_data.survey_name,
                    description: description,
                    registration_id: survey_data.survey_registration_id,
                    survey_id: survey_data.survey_id
                }
            });
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    setLoading = (status) => {
        this.setState({...this.state, loading: status });
    }

    componentDidMount() {
        this.get_pending_surveys();
    }

    render(){
        return (
            <div style={{ background: 'rgb(var(--secondary-color))', color: 'rgb(var(--primary-text-color))' }}>
                {
                    (this.state.loading) ? <Loading />:
                    (this.state.error) ? <ErrorOnLoading type={this.state.status} />:
                    (this.state.surveys.length > 0) ?
                    (this.state.evaluating) ? 
                        <SurveyView data={{questions: this.state.survey_body, survey_information: this.state.survey_body_information, save_results_url: 'webApp/student/save_course_survey_results'}} refresh={this.get_pending_surveys} />:(
                        <SurveysContent data={{surveys: this.state.surveys, course_data: this.state.information}} refresh={this.get_pending_surveys} evaluate={this.get_survey} />
                        ):<NoInfo />
                    }
            </div>
        );
    }
}
// FVDA0001
export {AttendeeSurveysView}