import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import NoInfo from '../utilities/NoInfo';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {AppContext} from '../utilities/app-context';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650
    },
    cell: {
        borderRight: '0.05em solid #e0e0e0',
        padding: '0.8em',
        paddingRight: 4,
        paddingLeft: 5
    },
    thead: {
        fontWeight: 600,
        borderRight: '0.05em solid #e0e0e0'
    },
});

const AccountsTable = ({data}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const context = React.useContext(AppContext);

    let status = 0;

    const onChangeUser = (user) => {
        console.log(user);
        fetch(Constants.api + 'webApp/user/changeAccount', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                user: user
            })
        })
        .then(response => {
            if(!response.ok) {
                status = response.status;
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            context.verifySession();
        }).catch(error => {
            console.log("Error");
            console.log(error);
            if(status === 401){
                context.setAuthentication(false);
            }
        });
    }
    return (
        <Container style={{marginTop: 20}}>
            <Grid container spacing={3} direction="row" justify="center" >
                <Grid item xs={12} sm={12} md={10} lg={10} >
                    <Table size="medium" stickyHeader >
                        <TableHead>
                            <TableRow >
                                <TableCell align="center" className={classes.thead}><b>{t('changeAccountUser')}</b></TableCell>
                                <TableCell align="center" className={classes.thead}><b>{t('changeAccountDetails')}</b></TableCell>
                                <TableCell align="center" className={classes.thead}><b>{t('changeAccountChangeTitle')}</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(row => (
                                <TableRow key={row.idUser}>
                                    <TableCell align="center" className={classes.cell}><b>{row.user}</b></TableCell>
                                    <TableCell align="center" className={classes.cell}>{row.details}</TableCell>
                                    <TableCell align="center" className={classes.cell}>
                                        <Button variant="contained" color="primary" onClick={() => {onChangeUser(row.user)}}>
                                            {t('changeAccountChangeAction')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Container>
    );
}

/**
 * Component for balance react component
 */
class ChangeAccountView extends React.Component {
    /**
     * constructor
     * @param {object} props 
     */
    constructor(props) {
        super(props);
        this.state = { accounts: [], error: false, loading: true, status: '' };
    }

    /**
     * Init state
     */
    componentDidMount() {
        fetch(Constants.api + 'webApp/user/getOtherAccounts', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({ ...this.state, loading: false });
            if (json.length > 0) {
                this.setState({
                    ...this.state,
                    accounts: json
                });
            } else {
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            this.setState({ ...this.state, error: true, status: error});
        });
    }

    render() {
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                {
                    (this.state.error) ? <ErrorOnLoading type={this.state.status} /> :
                        (this.state.loading) ? <Loading /> :
                            (this.state.accounts.length > 0) ? (
                                <AccountsTable data={this.state.accounts} />
                            ) : <NoInfo />
                }
            </div>
        );
    }
};

export { ChangeAccountView };
