import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import { Constants } from '../utilities/constants';
import swal from 'sweetalert';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import {showError} from '../utilities/errorOnFetch';
//import NoInfo from '../utilities/NoInfo';
/**
 * Styles for support
 */
const useStyles = makeStyles(theme => ({
    formControl: {
        marginLeft: '25%',
        marginTop: '2%',
        minWidth: '50%',
    },
    textarea: {
        background: 'rgb(var(--secondary-color))',
        padding: 10,
        fontSize: 15
    },
    info_message: {
        marginLeft: '25%',
        marginTop: '1%',
        minWidth: '50%',
        maxWidth: '50%'
    }
}));


/**
 * Support component
 */
const SupportView = ({data}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState({
        subject: 'Eduscore',
        comment: ''
    })
    const [open, setOpen] = React.useState(false);

    /**
     * handle changue objects
     * @param {object} event 
     */
    const handleChange = event => {
        const { name, value } = event.target
        setState({
            ...state, [name]: value
        })
    };

    /**
     * Topic close
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Topic open
     */
    const handleTopic = () => {
        setOpen(true);
    };

    /**
     * Submit
     * @param {object} evt 
     */
    const handleSubmit = (evt) => {
        evt.preventDefault();
        fetchSupport();
        setState({
            ...state,
            comment: ''
        });
    }

    /**
     * Fetch support API
     */
    const fetchSupport = () => {
        fetch(Constants.api + 'webApp/support', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(state)
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {swal(t('modalSuccessTitle'), t('messageEmail'), "success")})
        .catch(error => {
            //swal(t('modalErrorTitle'), t('requestError'), "error")
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        })
    }

    /**
     * Return view
     */
    return (
        <div style={{ backgroundColor: 'rgb(var(--secondary-color))' }}>
            <Alert severity="info" className={classes.info_message}>
                <AlertTitle>{t('supportAlertTitle')}</AlertTitle>
                <Typography>
                    {t('supportAlertContentEmail')}: <b>{data.email}</b>
                </Typography>
                <Typography>
                    {t('supportAlertContent')}
                </Typography>
            </Alert>
            <form onSubmit={handleSubmit} >
                <FormControl className={classes.formControl}  >
                    <InputLabel id="demo-controlled-open-select-label">{t('selectTopic')}</InputLabel>
                    <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        name="subject"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleTopic}
                        value={state.subject}
                        onChange={handleChange}
                        style={{ backgroundColor: 'rgb(var(--secondary-color))' }}
                    >
                        <MenuItem value={'General'}>General</MenuItem>
                        <MenuItem value={'UNIAT'}>UNIAT</MenuItem>
                        <MenuItem value={'Academia'}>Escolar/Académico</MenuItem>
                        <MenuItem value={'Eduscore'}>Eduscore</MenuItem>
                    </Select>
                    <TextareaAutosize
                        style={{ backgroundColor: 'rgb(var(--secondary-color))' }}
                        className={classes.textarea}
                        rows={15}
                        placeholder={t('comment')}
                        value={state.comment}
                        name='comment'
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <Button variant="contained"
                        color="primary"
                        type='submit'
                        value='Submit'
                        style={{ background: '#ff7600', color: 'white' }}
                        size='large'
                    >
                        {t('resetSendUser')}
                    </Button>
                </FormControl>
            </form>
        </div>
    );
}

class Support extends React.Component{
    constructor(props){
        super(props);
        this.state = {error: false, loading: true, user: {}};
    }

    getUserInfo = () => {
        this.setState({...this.state, error: false, loading: true});
        fetch(Constants.api + 'webApp/support/getUserInfo', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            return response.json()
        })
        .then(json => {
            this.setState({...this.state, error: false, loading: false});
            if(json.length > 0){
                this.setState({
                    ...this.state,
                    user: json[0]
                });
                console.log(json[0]);
            }else{
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, user: {}});
            console.log("Error");
            console.log(error);
        });
    }

    componentDidMount() {
        this.getUserInfo();
    }

    render(){
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                {
                    (this.state.loading) ? <Loading />:
                    (this.state.error) ? <ErrorOnLoading />:
                    <SupportView data={this.state.user} />
                }
            </div>
        );
    }
}
export {Support};