import React from 'react'
import * as views from './../../views/viewLoader'

/**
 * Component loader for drawer
 */
class ComponentLoader extends React.Component {
  render() {
    const TagName = views[ this.props.tag ]
    return <TagName />
  }
}

export default ComponentLoader