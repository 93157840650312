import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import { Alert } from '@material-ui/lab';
import swal from '@sweetalert/with-react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import NoInfo from '../utilities/NoInfo';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import 'moment/min/locales';

const useStyles = makeStyles(theme => ({
    text: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        marginTop: 190,
        fontSize: 20,
        color: '#37474f'
    },
    tabTitle: {
        color: 'black',
        fontWeight: "bold"
    },
    tabTitleText: {
        color: 'black',
        fontWeight: 600
    },
    formControl: {
        marginTop: '2%',
        minWidth: '50%'
    }
}));

function ActivityDetailsTable({data, handleUpdateOptionsBtn}){
    const { t } = useTranslation();
    const classes = useStyles();
    const statusTxt = (status) => {
        let txt = "";
        switch (status) {
            case 0:
                txt = <Typography variant="overline" display="block" style={{color: "green"}} gutterBottom>{t('teacherAbsenceAdminModalTableStatusAttendance')}</Typography>;
            break;
            case 1:
                txt = <Typography variant="overline" display="block" style={{color: "red"}} gutterBottom>{t('teacherAbsenceAdminModalTableStatusAbsence')}</Typography>;
            break;
            case 2:
                txt = <Typography variant="overline" display="block" style={{color: "#ff9800"}} gutterBottom>{t('teacherAbsenceAdminModalTableStatusDelay')}</Typography>;
            break;
            default:
                txt = <Typography variant="overline" display="block" gutterBottom>{t('teacherAbsenceAdminModalTableStatusNotDefined')}</Typography>;
            break;
        }
        return txt;
    }
    return (
        <div className={classes.table}>
            <Table size="medium" stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className={classes.thead}><b>{t('teacherAbsenceAdminModalTableName')}</b></TableCell>
                        <TableCell align="center" className={classes.thead}><b>{t('teacherAbsenceAdminModalTableStatus')}</b></TableCell>
                        <TableCell align="center" className={classes.thead}><b>{t('teacherAbsenceAdminModalTableActions')}</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.students.map((activity,i) => {
                            return (
                                <TableRow key={`class${data.idClass}Activity${i}`} className={classes.tr} hover>
                                    <TableCell align="center" className={classes.thead}>{activity.studentName}</TableCell>
                                    <TableCell align="center" className={classes.thead}>{statusTxt(activity.status)}</TableCell>
                                    <TableCell align="center" className={classes.thead}>
                                        <IconButton aria-label="delete" className={classes.margin} onClick={() => handleUpdateOptionsBtn(activity)} >
                                            <EditIcon fontSize="large" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </div>
    );
}

function ActivityTable({data}){
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openUpdateOption, setOpenUpdateOption] = React.useState(false);
    const [openSelectOption, setOpenSelectOption] = React.useState(false);
    const [actualActivity, setActivity] = React.useState({students: []});
    const [selectStatus, setSelectStatus] = React.useState({status: 0, name: '', data : {}});

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleOpenUpdateOption = () => {
        setOpenUpdateOption(true);
    };

    const handleCloseUpdateOption  = () => {
        setOpenUpdateOption(false);
    };

    const handleSelectClose = () => {
        setOpenSelectOption(false);
    };

    const handleSelectOpen = () => {
        setOpenSelectOption(true);
    };

    const handleSelectChange = (event) => {
        const { value } = event.target;
        setSelectStatus({...selectStatus, status: value});
    };

    const handleUpdateOptionsBtn = (data) => {
        setSelectStatus({status: data.status!==null?data.status:'', name: data.studentName, data: data});
        handleOpenUpdateOption();
    }

    moment.locale(t('discordRegisterDate'));

    const activityDetails = (activity) => {
        fetch(Constants.api + 'webApp/absence/getActivityDetails', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                idAbsenceActivity: activity.idAbsenceActivity,
                idClass: activity.idClass
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            if (json.length > 0) {
                activity.students = json;
            } else {
                activity.students = [];
            }
            setActivity(activity);
            handleOpen();
        })
        .catch(error => {
            console.log("Error al obtener los detalles de la actividad");
            console.log(error);
        });
    }

    const saveAttendanceStatus = () => {
        console.log(selectStatus);
        console.log(actualActivity);
        fetch(Constants.api + 'webApp/absence/saveAbsence', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                idAbsence: selectStatus.data.idAbsence,
                status: selectStatus.status
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            swal(t('modalSuccessTitle'), t('teacherAbsenceAdminModalUpdateSuccess'), "success");
            handleCloseUpdateOption();
            handleClose();
            activityDetails(actualActivity);
        })
        .catch(error => {
            console.log("Error al guardarla información");
            console.log(error);
            swal("Error", t('teacherAbsenceAdminModalUpdateError'), "error");
        });
    }
    
    return (
        <div className={classes.table}>
            <Table size="medium" stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className={classes.thead}><b>{t('teacherAbsenceAdminTableTitleDate')}</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((activity,i) => {
                            return (
                                <TableRow key={`class${data.idClass}Activity${i}`} className={classes.tr} hover onClick={() => activityDetails(activity)}>
                                    <TableCell align="center" className={classes.thead}>{moment(activity.date).format("LLLL")}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                <DialogTitle id="form-dialog-title"><b>{t('teacherAbsenceAdminModalTitle')}</b></DialogTitle>
                <DialogContent dividers>
                    <Grid key={`modalContentAlert${actualActivity.idClass}`} container spacing={1} direction="row" justify="center" >
                        <Grid item key={`gridModalContentAlert${actualActivity.idClass}`} index={actualActivity.idClass} xs={12} sm={12} md={12} lg={12} >
                            <Alert severity="info"><b>{t('teacherAbsenceAdminTableTitleDate')}:</b> {moment(actualActivity.date).format("LLLL")}</Alert>
                        </Grid>
                    </Grid>
                    <Grid key={`modalContentTable${actualActivity.idClass}`} container spacing={1} direction="row" justify="center" >
                        <Grid item key={`gridModalContentTable${actualActivity.idClass}`} index={actualActivity.idClass} xs={12} sm={12} md={12} lg={12} >
                            <ActivityDetailsTable data={actualActivity} handleUpdateOptionsBtn={handleUpdateOptionsBtn}  />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        {t('teacherAbsenceAdminModalCloseBtn')}
                    </Button>
                </DialogActions>
            </Dialog>
            
            <Dialog open={openUpdateOption} onClose={handleCloseUpdateOption} aria-labelledby="form-dialog-title" fullWidth={true}>
                <DialogTitle id="form-dialog-title"><b>{t('teacherAbsenceAdminModalTitle')}</b></DialogTitle>
                <DialogContent dividers>
                    <Grid key={`modalUpdateContentAlert${actualActivity.idClass}`} container spacing={1} direction="row" justify="center" >
                        <Grid item key={`gridModalUpdateContentAlert${actualActivity.idClass}`} index={actualActivity.idClass} xs={12} sm={12} md={12} lg={12} >
                            <Alert severity="info"><b> {selectStatus.name}</b></Alert>
                        </Grid>
                    </Grid>
                    <Grid key={`modalUpdateContentForm${actualActivity.idClass}`} container spacing={1} direction="row" justify="center" >
                        <Grid item key={`gridModalUpdateContentForm${actualActivity.idClass}`} index={actualActivity.idClass} xs={12} sm={12} md={12} lg={12} >
                            <FormControl className={classes.formControl}  >
                                <InputLabel id="attendace_status">{t('teacherAbsenceAdminModalSelectStatus')}</InputLabel>
                                <Select
                                    labelId="attendace_status"
                                    id="attendace_status_select"
                                    name="attendace_status_select"
                                    open={openSelectOption}
                                    onClose={handleSelectClose}
                                    onOpen={handleSelectOpen}
                                    value={selectStatus.status}
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value={0}>{t('teacherAbsenceAdminModalTableStatusAttendance')}</MenuItem>
                                    <MenuItem value={1}>{t('teacherAbsenceAdminModalTableStatusAbsence')}</MenuItem>
                                    <MenuItem value={2}>{t('teacherAbsenceAdminModalTableStatusDelay')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpdateOption} variant="contained">
                        {t('teacherAbsenceAdminModalCloseBtn')}
                    </Button>
                    <Button onClick={saveAttendanceStatus} variant="contained" color="primary" >
                        {t('teacherAbsenceAdminModalSelectStatusSave')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function AbsenceTabs({data}){
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFFFFF'
            }
        }
    });

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" style={{ background: '#ff7600' }}>
                    <Tabs
                    value={value}
                    onChange={handleTabChange}
                    variant="scrollable"
                    /* scrollButtons="on" */
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable auto tabs"
                    className={classes.tabTitle}
                    >
                    {
                        data.map((classVal, classIndex) => {
                            return (
                                <Tab key={`tab${classIndex}`} label = {classVal.subjectName + ' - ' + classVal.idClass} className={classes.tabTitleText} />
                            );
                        })
                    }
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <Container style={{marginTop: 20}}>
                {
                    data.map((classVal, classIndex) => {
                        return (
                            <div key={`divClass_${classIndex}`} style={{ background: 'rgb(var(--secondary-color))', ...value !== (classIndex)?{display:"none"}:{}}} index={classIndex} >
                                <Grid key={`gridClassTabContentAlert${classVal.idClass}`} container spacing={3} direction="row" justify="center" >
                                    <Grid item key={`gridClassTabScheduleContentAlert${classVal.idClass}`} index={data.idClass} xs={12} sm={12} md={10} lg={10} >
                                        <Alert severity="info">{classVal.schedule}</Alert>
                                    </Grid>
                                </Grid>
                                <Grid key={`gridClassTabButton${classVal.idClass}`} container spacing={3} direction="row" justify="center" >
                                    <Grid item key={`gridClassExcelTabButtonContent${classVal.idClass}`} index={classVal.idClass} xs={12} sm={6} md={4} lg={4}>
                                        <Button fullWidth={true} className={`${classes.saveBtn}`} variant="contained" href={`${Constants.api}webApp/absence/downloadClassList/${classVal.idClass}`} color="primary">
                                            {`${t('studentGradesList')}`}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid key={`gridClassTabContentTable${classVal.idClass}`} container spacing={3} direction="row" justify="center" >
                                    <Grid item key={`gridClassTabScheduleContentTable${classVal.idClass}`} index={data.idClass} xs={12} sm={12} md={10} lg={10} >
                                        { <ActivityTable data={classVal.activities} />}
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    })
                }
            </Container>
        </div>
    );
}

class TeacherAbsenceAdminView extends React.Component {
    /**
     * constructor
     * @param {object} props 
     */
    constructor(props) {
        super(props);
        this.state = { classes: [], error: false, loading: true, status: '' };
    }

    /**
     * Init state
     */
    componentDidMount() {
        fetch(Constants.api + 'webApp/absence/getClassesActivities', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({ ...this.state, loading: false });
            if (json.length > 0) {
                this.setState({
                    ...this.state,
                    classes: json
                });
            } else {
                console.log("Sin información");
                console.log(json);
            }
        })
        .catch(error => {
            this.setState({ ...this.state, error: true, status: error});
        });
    }

    render() {
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                {
                    (this.state.error) ? <ErrorOnLoading type={this.state.status} /> :
                    (this.state.loading) ? <Loading /> :
                        (this.state.classes.length > 0) ? (
                            <AbsenceTabs data={this.state.classes} />
                        ) : <NoInfo />
                }
            </div>
        );
    }
};

export {TeacherAbsenceAdminView};