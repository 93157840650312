import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constants } from '../utilities/constants';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import 'moment/min/locales';
import ReactPlayer from 'react-player/youtube';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import logo_eduscore from '../utilities/images/logo_eduscore.png';
import { makeStyles, withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

moment.tz.setDefault(Constants.timeZone);

const useStyles = makeStyles(theme => ({
    text: {
        color: '#7c7c7c',
        padding: "5px 10%"
    },
    text_orange: {
        color: 'orange',
        padding: "5px 10%"
    },
    video: {
        padding: "10px 10%"
    }
}));

const VideoPlayer = (prop) => {
    return (
        <ReactPlayer url={prop.link}
        controls
        width='100%'
        height='500px'
        style={{margin: "auto"}}
        />
    );
}

function IntroductionVideos(){
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <Container className={classes.text} style={{marginTop: 20, background: 'rgb(var(--secondary-color))'}}>
                <img style={{width:'35%'}}src={"https://admin.eduscore.net/public/img/logo_eduscore.png"} alt="Logo Eduscore"></img>
                <Typography variant='h6' style={{marginTop: "20px"}}><b>Conoce EDUSCORE:</b></Typography>
                <Typography style={{textAlign: "justify"}}>
                    Plataforma desarrollada por UNIAT, permite llevar el control administrativo de los aspirantes, alumnos, egresados, profesores e instituciones en convenio con la Universidad. Se conforma por diferentes módulos los cuales permiten llevar el expediente académico-administrativo del alumno durante toda su estadía en la Universidad.<br /> Dentro de esta plataforma el alumno puede:<br/>
                    <ul>
                        <li>Editar datos de cuenta como correo y contraseña.</li>
                        <li>Contactos de emergencia.</li>
                        <li>Ver su horario escolar, calificaciones y faltas.</li>
                        <li>Realizar pagos en línea y descargar sus facturas.</li>
                        <li>Consultar la Bolsa de trabajo.</li>
                        <li>Dar seguimiento a su Servicio Social y Servicio Becario.</li>
                        <li>Descargar archivos como Reglamentos y Calendario Escolar.</li>
                        <li>Realizar encuestas y evaluaciones.</li>
                        <li>Enviar mensajes a las distintas áreas para recibir soporte.</li>
                    </ul>
                </Typography>
            </Container>
            <Container className={classes.video} style={{marginTop: 20}}>
                <VideoPlayer link = {Constants.introduction_video_eduscore} />
            </Container>
            <Container className={classes.text} style={{marginTop: 20}}>
                <Typography variant='h6'><b>Conoce como pagar en línea:</b></Typography>
                <Typography style={{textAlign: "justify"}}>
                    El siguiente video te indica como realizar un pago en línea.
                </Typography>
            </Container>
            <Container className={classes.video} style={{marginTop: 20}}>
                <VideoPlayer link = {Constants.online_payment_video} />
            </Container>
            <Container className={classes.text} style={{marginTop: 20}}>
                <Typography variant='h6'><b>Conoce como Facturar:</b></Typography>
                <Typography style={{textAlign: "justify"}}>
                    La facturación la pueden realizar de manera automática en EDUSCORE, es importante que la factura la realicen dentro del mes que hacen el pago, ya que no se podrán generar facturas de meses anteriores.
                </Typography>
            </Container>
            <Container className={classes.video} style={{marginTop: 20}}>
                <VideoPlayer link = {Constants.introduction_video_invoicing} />
            </Container>
            <Container className={classes.text} style={{marginTop: 20}}>
                <Typography variant='h6'><b>Como conectar tu Cuenta de Discord:</b></Typography>
                <Typography style={{textAlign: "justify"}}>
                    El siguiente video te indica como conectar EDUSCORE y tu cuenta de Discord.
                </Typography>
            </Container>
            <Container className={classes.video} style={{marginTop: 20}}>
                <VideoPlayer link = {Constants.introduction_video_connect_discord} />
            </Container>
            <Container className={classes.text} style={{marginTop: 60}}>
                <img style={{width:'35%'}}src={"https://admin.eduscore.net/public/img/logo_Google.png"} alt="Logo Eduscore"></img>
                <Typography variant='h6'><b>Conoce Google Workspace:</b></Typography>
                <Typography style={{textAlign: "justify"}}>
                Plataforma de seguimiento y acompañamiento académico entre alumno, docente, enlace estudiantil y coordinación académica. Las herramientas que nos ayudan al estudio remoto-presencial son:<br/><br/>
                <b>Gmail y correo electrónico educativo:</b> Herramienta de correo electrónico estudiantil, permite al usuario mantener comunicación con personal docente y administrativo, así como también avala al alumno ante empresas de software para conseguir licenciamiento gratuito o bien descuentos en el mismo.<br/><br/>
                <b>Google Drive:</b> Herramienta de almacenamiento en la nube, donde los alumnos pueden almacenar su trabajo. Pudiendo acceder a él desde cualquier equipo de cómputo en cualquier parte.<br/><br/>
                <b>Documentos de Google:</b> Herramienta de procesamiento de texto, hojas de cálculo y presentaciones en línea. El alumno podrá crear y editar documentos en línea desde cualquier lugar y cualquier equipo que tenga conexión a internet.<br/><br/>
                <b>Google Meet:</b> Herramienta para realizar reuniones virtuales. El alumno podrá crear reuniones para trabajos en grupo en tiempo real desde cualquier lugar y equipo de cómputo o smartphone que tenga conexión a internet.<br/><br/>
                <b>Google Calendar:</b> Herramienta de calendario en línea. El alumno podrá agendar en el calendario actividades o clases y así tener recordatorios de sus actividades.<br/><br/>
                <b>Google Formularios:</b> Herramienta de creación de formularios. El alumno podrá crear documentos tipo formularios para trabajos mismos que se pueden acceder en línea desde cualquier equipo que tenga conexión a internet.<br/><br/>
                <b>Google Sitios:</b> Herramienta de creación en línea de páginas web. El alumno podrá crear sitios web en línea desde cualquier equipo de cómputo con conexión a internet.<br/><br/>
                <b>Jamboard:</b> pizarra digital para conceptualización de ideas.
                </Typography>
            </Container>

            <Container className={classes.text} style={{marginTop: 15}}>
                <Typography variant='h6'><b>Acceso correo institucional - @uniat.edu.mx</b></Typography>
                <Typography style={{textAlign: "justify"}}>
                    Tu correo de @uniat.edu.mx te da <b>acceso a las aplicaciones educativas de Google Workspaces.</b><br />
                    <ol>
                        <li>Para conocer tu correo debes ingresar a tu perfil de estudiante.</li>
                        <li>Dentro del apartado Perfil / Correo electrónico podrás conocer tu nuevo correo.</li>
                        <li>Para ingresar a tu correo debes hacerlo de la siguiente manera:</li>
                        <li style={{listStyle: 'none'}}>
                            <ul>
                                <li><b>Plataforma:</b> https://www.gmail.com</li>
                                <li><b>Correo:</b> nombre.apellido@uniat.edu.mx</li>
                                <li><b>Contraseña:</b> correo.personal@gmail.com (Correo que compartiste al departamento de Admisiones de UNIAT)</li>
                                <li><b>Nota:</b> te sugerimos cambiar la contraseña de tu nuevo correo.</li>
                            </ul>
                        </li>
                    </ol>
                </Typography>
            </Container>

            <Container className={classes.video} style={{marginTop: 20}}>
                <VideoPlayer link = {Constants.introduction_video_google_workspace} />
            </Container>
            
            <Container className={classes.text} style={{marginTop: 60}}>
                <img style={{width:'35%'}}src={"https://admin.eduscore.net/public/img/logo_Discord.png"} alt="Logo Eduscore"></img>
                <Typography variant='h6' style={{marginTop: "20px"}}><b>Conoce Discord:</b></Typography>
                <Typography style={{textAlign: "justify"}}>
                    Plataforma de interacción en tiempo real donde la comunidad UNIAT puede estudiar y convivir a distancia.  Esta herramienta se especializa en la creación de comunidades en línea, la cual cuenta con las mejores tecnologías que permiten establecer una comunicación efectiva y de gran calidad a través de audio, video y texto.<br/>
                    Esta plataforma abarca 3 áreas de la vida estudiantil:<br/>
                    <ol>
                        <li><b>Clases remoto-presencial:</b> sesiones en vivo donde los alumnos y maestros realizan videollamadas para la impartición de los contenidos de las clases.</li>
                        <li><b>Canales de comunicación oficial con la Universidad:</b> Soporte Técnico, Coordinación, Control Escolar, Enlace Estudiantil, Finanzas y Dirección.</li>
                        <li><b>Comunidad UNIAT:</b> Cuenta con espacios virtuales para desarrollar el ámbito social de nuestra comunidad con espacios para: networking, actividades extracurriculares, entretenimiento y esparcimiento, colaboración e intercambio de ideas.</li>
                    </ol>
                </Typography>
            </Container>
            <Container className={classes.video} style={{marginTop: 20, paddingBottom: 80, background: 'rgb(var(--secondary-color))'}}>
                <VideoPlayer link = {Constants.introduction_video_discord} />
            </Container>
        </div>
    )
}

export function IntroductionView(){
    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <Container style={{marginTop: 20}}>
                <IntroductionVideos />
            </Container>
        </div>
    )
}
