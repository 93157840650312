import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Constants } from '../utilities/constants';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import { showError } from '../utilities/errorOnFetch';
import swal from '@sweetalert/with-react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';

/**
 * Styles for the card 
 */
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        borderColor: 'white',
        display: 'none',
    }, bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    textField: {
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: 10,
        width: '90%'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            padding: '2px 0'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0)'
            //outline: '1px solid slategrey'
        }
    }
}));


/**
 * Styles for textfield
 */
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiOutlinedInput-root': {
            outlineColor: 'orange',
            '&.Mui-focused fieldset': {
                borderColor: 'orange',
            },
        },
    },
})(TextField);

/**
 * Upload cv
 */
function UploadCV(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [state, setState] = useState({
        status:(props.fileCV !== null) ? true : false,
        cv: props.fileCV
    });
    // if there is a cv is true if not is false
    const { t } = useTranslation(); //Translation

    /**
     * Buttons option
     */
    const ButtonsOptions = () => {
        return (
            <div>
                <Button
                    variant="contained"
                    size="large"
                    href={Constants.base_site + Constants.downloadPDF
                        + 'files/CV/' + props.idPerson + '_' + state.cv + '/' + state.cv}
                    download={state.cv}
                    style={{ backgroundColor: '#5AB5C9', color: 'white', float: 'right' }}>
                    {t('downloadCV')}<GetAppIcon />
                </Button>
                <input
                    className={classes.input}
                    id="update-button-file"
                    multiple
                    type="file"
                    onChange={onFileChange}
                />
                <label htmlFor="update-button-file">
                    <Button variant="contained" size="large" component = "span"
                        style={{ backgroundColor: '#40BFA4', color: 'white', float: 'right', marginRight: 10 }}
                    >
                        {t('update_cv')}<RefreshIcon />
                    </Button>
                </label>
            </div>
        );
    }

    /**
     * Button upload
     */
    const ButtonUpload = () => {
        return (
            <Button variant="contained" size="large" style={{ backgroundColor: '#75C166', color: 'white', float: 'right' }} component="span">
                {t('uploadCV')}<PublishIcon />
            </Button>
        );
    }

    //Upload file
    const onFileUpload = () => {
        // Create an object of formData 
        const formData = new FormData();
        // Update the formData object 
        formData.append(
            "pdfFile",
            file.selectedFile,
            props.name //save file by the name
        );

        // Details of the uploaded file 
        setLoading(true);
        // Request made to the backend api 
        axios.post(Constants.base_site + Constants.uploadPDF + props.idPerson, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            if (response.data.error === false) {
                swal(t('titleJobOffer'), t('publishCV'), 'success');
                setState({
                    ...state,
                    status: true,
                    cv:props.name//Save file by name of the person
                });// there is a CV
            } else {
                switch (response.data.message) {
                    case 'Exists':
                        swal('Error', t('existsCV'), 'error');
                        break;
                    case 'Format':
                        swal('Error', t('formatCV'), 'error');
                        break;
                    default:
                        break;
                }
            }
            setLoading(false);
        }).catch(error => {
            swal('Error', t('errorData'), 'error');
            setLoading(false);
        });
    };

    const onFileChange = event => {
        setFile({ selectedFile: event.target.files[0] });
        event.target.value = "";
        // Update the state 
    };

    /**
     * On update component
     */
    useEffect(() => {
        if (file !== null) {
            swal({
                text: t('alertTitleJob'),
                icon: "warning",
                buttons: [t('cancel'), true],
                dangerMode: true,
            })
                .then((accept) => {
                    if (accept) {
                        onFileUpload();
                    }
                });
        }
        // eslint-disable-next-line
    }, [file])

    return (
        (loading) ? <div style={{ padding: 50 }}><CircularProgress style={{ float: 'right' }} /></div> :
            <div style={{ padding: 30, background: 'rgb(var(--secondary-color))' }}>
                <input
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onFileChange}
                />
                <label htmlFor="contained-button-file">
                    {(state.status) ?
                        <ButtonsOptions /> :
                        <ButtonUpload />
                    }
                </label>
                <br /> <br />
            </div>
    );
}

/**
 * Items for show job offer
 */
function Items(props) {
    const [filter, setFilter] = useState('');
    const [expanded, setExpanded] = useState(props.flags);
    const [state, setState] = useState(props.checkbox);
    const { t } = useTranslation(); //Translation
    const lowercaseFilter = filter.toLowerCase();

    const filteredData = (props.offers === 'empty') ? '' : props.offers.filter(item => {
        return item.name.toLowerCase().includes(lowercaseFilter);
    });
    //const [check, setCheck] = useState(props.postulate);
    const classes = useStyles();

    /**
     * Count clicks by offer API
     */
    const updateCountClicks = (idJobs) => {
        fetch(Constants.api + 'webApp/jobOffer/updateCountClicks', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idJobs: idJobs,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(data => { })
            .catch(error => { });
    }

    /**
     * Add postulation in the job
     */
    const setPostulation = (idPerson, idJobs) => {
        fetch(Constants.api + 'webApp/jobOffer/setPostulation', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idPerson: idPerson,
                idJobs: idJobs,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                if (data['affectedRows'] > 0) {
                    swal(t('titleJobOffer'), t('addPostulation'), 'success');
                } else {
                    swal('Error', t('requestError'), 'error')
                }
            })
            .catch(error => {
                swal("Error",
                    showError(t('errorData'), error, t),
                    "error");
            });
    }

    /**
     * delete postulation API
     */
    const deletePostulation = (idPerson, idJobs) => {
        fetch(Constants.api + 'webApp/jobOffer/deletePostulation', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idPerson: idPerson,
                idJobs: idJobs,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                if (data['affectedRows'] > 0) {
                    swal(t('titleJobOffer'), t('deletePostulation'), 'success');
                } else {
                    swal('Error', t('requestError'), 'error')
                }
            })
            .catch(error => {
                swal("Error",
                    showError(t('errorData'), error, t),
                    "error");
            });
    }



    /**
     * Expanded bottom
     * @param {Integer} index 
     */
    const handleExpandClick = (index, idJobs) => () => {
        let newArray = [...expanded];
        newArray[index] = !expanded[index];
        if (newArray[index]) updateCountClicks(idJobs)
        setExpanded(newArray)
    };

    /**
     * handle change
     * @param {Object} event 
     */
    const handleChange = (id) => event => {
        let check = event.target.checked;
        const newData = state.map((item, index) => {
            if (item[id] !== undefined) {
                item[id] = check
                if (check) {
                    updateCountClicks(id);//update count clicks if is true
                    setPostulation(props.idPerson, id);
                } else {
                    deletePostulation(props.idPerson, id);
                }
                //console.log(check)
                //console.log(id)
                //console.log(props.idPerson)
            }
            return item;
        });
        setState(newData);
    };

    /**
     * Text field filter
     */
    const handleChangeFilter = (evt) => {
        setFilter(evt.target.value);
    }

    /**
     * Get the item by id
     */
    const itemCheckbox = (id) => {
        let aux;
        // eslint-disable-next-line
        state.filter(item => {
            if (item.id === id) {
                aux = item[item.id]
            }
        });
        return aux;
    }

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <CssTextField
                className={classes.textField}
                label={t('search')}
                variant="outlined"
                id="custom-css-outlined-input"
                value={filter}
                onChange={handleChangeFilter}
            />
            <div style={{ marginLeft: '5%', marginRight: '5%', overflowY: 'scroll', height: 500, background: 'rgb(var(--secondary-color))' }}>
                {
                    (props.offers === 'empty') ? <div style={{ color: 'rgb(var(--primary-text-color))' }}><br /><center>{t('emptyOffers')}</center></div> :
                        filteredData.map((item, index) => (
                            <Card className={classes.root} key={index} style={{ marginBottom: 20 }}>
                                <CardContent>
                                    <Typography className={classes.title} color="rgb(var(--primary-text-color))" gutterBottom>
                                        {item.company}
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                        {item.name}
                                    </Typography>
                                    <Typography className={classes.pos} color="rgb(var(--primary-text-color))">
                                        {t('contactJob')}:{item.email}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {item.description}
                                    </Typography>
                                </CardContent>
                                <CardActions disableSpacing>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={itemCheckbox(item.idJobs) || false}
                                                onChange={handleChange(item.idJobs)}
                                                color="primary" />
                                        }
                                        label={t('apply')}
                                    />
                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: expanded[index],
                                        })}
                                        onClick={handleExpandClick(index, item.idJobs)}
                                        aria-expanded={expanded[index]}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </CardActions>
                                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        <Typography paragraph>{t('career')}:&nbsp;{(item.career === null) ? t('nothing') : item.career}</Typography>
                                        <Typography paragraph>{t('temporality')}:&nbsp;{(item.temporality === null) ? t('nothing') : item.temporality}</Typography>
                                        <Typography>
                                            {t('telephones')}: {(item.telephone === null) ? t('nothing') : item.telephone}
                                        </Typography>
                                    </CardContent>
                                </Collapse>
                            </Card>
                        ))
                }
            </div>
            <br />
        </div>
    );
}

export function JobOfferView() {
    const [checkbox, setCheckbox] = useState([]);
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState([]);
    const [state, setState] = useState([]);
    const [error, setError] = useState({
        flag: false,
        status: ''
    });

    /**
     * Fetch data using the API
     */
    const fetchData = () => {
        fetch(Constants.api + 'webApp/jobOffer/getJobs', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                //console.log(data)
                setState(data);
                if (data.jobs !== 'empty') {
                    buildIdJobs(data.jobs);
                    setCheckbox(buildCheckbox(data.jobs, data.postulate));
                }
                setLoading(false);
            })
            .catch(error => {
                setError({
                    ...error,
                    flag: true,
                    status: error
                });
            });
    }

    /**
     * init expanded button for the cards
     * @param {Object} offers 
     */
    const buildIdJobs = (offers) => {
        const aux = [];
        // eslint-disable-next-line
        offers.map(() => {
            aux.push(false);
        })
        setItem(aux);
        //setExpanded(idJobs);
    }

    /**
     * This function build the checkbox depending if the user checked
     * @param {Object} items 
     * @param {Object} postulate 
     */
    const buildCheckbox = (items, postulate) => {
        const aux = [];
        //new data
        let newD = [];
        // eslint-disable-next-line
        items.map((item) => {
            aux.push({
                [item.idJobs]: false,
                'id': item.idJobs
            });
        })
        if (postulate.length === 0) return aux;
        // eslint-disable-next-line
        postulate.map((item, index) => {
            let idJobs = item.idJobs;
            newD = aux.map((item2) => {
                if (item2.id === idJobs) {
                    item2[idJobs] = true;
                }
                return item2;
            });
        })
        return newD;
    }

    //On init
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line 
    }, [])

    return (
        (error.flag) ? <ErrorOnLoading type={error.status} /> :
            (loading) ? <Loading /> :
                <div style={{ background: 'rgb(var(--secondary-color))' }}>
                    <UploadCV
                        idPerson={state.data[0].idPerson}
                        fileCV={state.file[0].fileCV}
                        name = {state.data[0].name}
                    />
                    <Items
                        offers={state.jobs}
                        flags={(state.jobs === 'empty') ? '' : item}
                        checkbox={(state.jobs === 'empty') ? '' : checkbox}
                        idPerson={(state.jobs === 'empty') ? '' : state.data[0].idPerson}
                    />
                </div>
    );
}