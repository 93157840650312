import React,{useEffect, useState} from 'react';
import {DynamicSchedule as DynamicScheduleScreen} from '../components/Schedule/DynamicSchedule'
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import RestrictMessage from '../utilities/RestrictMessage';

/**
 * Component for balance react component
 */
class DynamicScheduleView extends React.Component {
    /**
     * constructor
     * @param {object} props 
     */
    constructor(props) {
        super(props);
        this.state = { flag: false, error: false, loading: true, status: '', restrict: null, schedule: {schedule: [], period: ""}};
    }

    /**
     * Fetch data using the API
     */
    fetchSchedule(){
        fetch(Constants.api + 'webApp/dynamic_schedule', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data.message !== undefined){
                this.setState({
                    ...this.state,
                    flag: true,
                    loading: false
                });
            }
            let filtered_data = [];
            let filter_aux = [];
            for(let index = 0; index < data.response.length; index++){
                const element = data.response[index];
                let schedule_datetime = `${element.day} | ${element.timeLines}`;
                element.schedule_datetime = schedule_datetime;
                if(!filter_aux.includes(schedule_datetime)){
                    filtered_data.push(element);
                }else if(element.classroom_campus === element.studentCampus){
                    let position = filtered_data.findIndex((val, index) => {
                        return val.schedule_datetime === schedule_datetime;
                    });
                    filtered_data[position] = element;
                }
                filter_aux = schedule_datetime;
            }
            this.setState({
                ...this.state,
                loading: false,
                schedule: {
                    schedule: filtered_data,
                    period: data.period
                }
            });
        })
        .catch(error => {
            this.setState({
                ...this.state,
                flag: true,
                status: error
            });
        });
    }

    verifyBlackList(){
        fetch(Constants.api + 'webApp/user/checkBlackList', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data.restrict === true){
                this.setState({
                    ...this.state,
                    restrict: data.result[0],
                    loading: false
                });
            }else{
                this.setState({
                    ...this.state,
                    restrict: null,
                    loading: false
                });
                this.fetchSchedule();
            }
        })
        .catch(error => {
            this.setState({
                ...this.state,
                flag: true,
                status: error
            });
            //swal("Error", t('informationError'), "error");
        });
    }

    /**
     * Init state
     */
    componentDidMount() {
        this.verifyBlackList();
    }

    render() {
        return (
            (this.state.flag) ? <ErrorOnLoading type={this.state.status}/> :
            (this.state.loading)? <Loading /> :
            (this.state.restrict !== null)? <RestrictMessage restrict={this.state.restrict} /> :
            <DynamicScheduleScreen
                hours={this.state.schedule.schedule}
                period={this.state.schedule.period}
                //loading = {loading}
            />
        );
    }
};

export { DynamicScheduleView };