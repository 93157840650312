import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import swal from '@sweetalert/with-react'
import classNames from 'classnames';
import { Constants } from '../../utilities/constants';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';

moment.tz.setDefault(Constants.timeZone);
/**
 *  Const for show each subject by color. 
*/
const colorClass = {
    1: "#F95959",
    2: "#FACF5A",
    3: "#2471A3",
    4: "#2C3E50",
    5: "#05A19C",
    6: "#8559A5",
    7: "#424949",
    8: "#E67E22",
    9: "#0B5345",
    10: "#AA255B",
};

/**
 * Style for show components
 */
const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650
    },
    cell: {
        borderRight: '0.05em solid #e0e0e0',
        padding: '0.8em',
        paddingRight: 4,
        paddingLeft: 5,
        color: '#707372'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        //width: '50%',
    },
    btnColor: {
        backgroundColor: 'orange',
        "&:hover": {
          background: "orange"
        }
    },
    toggleRow: {
        backgroundColor: '#f0f0f0'
    },
    schedulePeriod:{
        marginTop: "0.35em"
    },
    timezone_text: {
        marginTop: "0.35em",
        fontSize: "1.1rem"
    },
    table_container: {
        padding: "15px"
    }
});

/**
 * Component dot material
 * @param {object} props
 */
function DotMaterial(props) {
    const { t } = useTranslation();
   // const classes = useStyles();
    return (
        <div onClick={() => {
            swal({
                buttons: (props.url == null)?
                {
                    primary:"Ok"
                }:
                {
                    //primary: "Ok",
                    catch: {
                        text: t('classOnline'),
                        value: "go",
                        //className: classes.btnColor
                    },
                    cancel: "Ok",
                },
                content: (
                    <div>
                        <br/>
                        {/*<div style={{backgroundColor:'orange', width:'100%', height:50}}>&nbsp;</div>*/}
                        <p style={{ fontSize: 20, color: '#37474f' }}><b>{t('teacher')}: </b>{props.teacher}</p>
                        {props.teacherEmail!==undefined?<p style={{ fontSize: 20, color: '#37474f' }}><b>{props.teacherEmail}</b></p>:null}
                        <p style={{ fontSize: 20, color: '#37474f' }}><b>{t('class')}: </b>{props.subject}</p>
                        <p style={{ fontSize: 20, color: '#37474f' }}><b>{t('classroom')}: </b>{props.classRoom}</p>
                        {props.groups!==undefined?<p style={{ fontSize: 20, color: '#37474f' }}><b>{t('schedule_groups')}: </b>{props.groups}</p>:null}
                        {props.careers!==undefined?<p style={{ fontSize: 20, color: '#37474f' }}><b>{t('schedule_careers')}: </b>{props.careers}</p>:null}
                        {props.campus!==undefined?<p style={{ fontSize: 20, color: '#37474f' }}><b>{t('schedule_campus')}: </b>{props.campus}</p>:null}
                        <p style={{ fontSize: 20, color: '#37474f' }}><b>ID: </b>{props.idClass}</p>
                    </div>
                )
            }).then((value)=>{
                switch(value){
                    case "go":
                        window.open(
                             props.url,
                            '_blank' // <- This is what makes it open in a new window.
                        );
                    break;
                    default: break;
                }
            })
        }}>
            <div
                style={{
                    backgroundColor: props.color,
                    margin: "auto",
                    width: '100%',
                    color: 'white',
                    height: 80,
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                    cursor: "pointer",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10,
                }}
            >
                {props.subject}
            </div>
        </div>
    )
}

/**
 * Create data in the table
 * @param {string} hour 
 * @param {object} mon 
 * @param {object} tue 
 * @param {object} wed 
 * @param {object} thu 
 * @param {object} fri 
 * @param {object} sat 
 */
function createData(hour, mon, tue, wed, thu, fri, sat) {
    return { hour, mon, tue, wed, thu, fri, sat };
}

/**
 * Return a array depending the hours example if is 07:00:00 - 09:00:00 = [7,8,9]
 * @param {int} startHour 
 * @param {int} endHour 
 */
/*function getHours(startHour, endHour) {
    let total = endHour - startHour
    let hours = [];
    let firstHour = parseInt(startHour)
    hours.push(firstHour)
    for (let i = 0; i < total; i++) {
        hours.push(firstHour += 1)
    }
    return hours;
}*/

/**
 * Build the schedulewith the json data 
 * @param {object} data 
 */
function buildSchedule(data, hours) {
    let subjectColor = new Map()
    let auxColor = 1
    for (let i = 0; i < data.hours.length; i++) {
        
        let startTime = moment(data.hours[i]['startTime'], 'HH:mm:ss').tz(moment.tz.guess(true)).format("HH:mm");
        let endTime = moment(data.hours[i]['endTime'], 'HH:mm:ss').tz(moment.tz.guess(true)).format("HH:mm");

        let day = data.hours[i]['day'];
        let classSchool = data.hours[i]['subjectName'];
        let classRoom = data.hours[i]['classroomName'];
        let teacher = data.hours[i]['teacherName'];
        let idClass = data.hours[i]['idClass']
        let url = data.hours[i]['onlineClassURL'];
        let campus = data.hours[i]['class_campus'];
        let careers = data.hours[i]['class_careers'];
        let groups = data.hours[i]['class_groups'];
        let color;
        let teacherEmail = data.hours[i]['teacherEmail'];
        if(teacherEmail === undefined || teacherEmail === "" ){
            teacherEmail = null;
        }
        if (subjectColor.has(classSchool)) {
            color = subjectColor.get(classSchool);
        } else {
            color = colorClass[auxColor];
            auxColor++;
            subjectColor.set(classSchool, color);
        }
        let key = `${day}_${startTime}-${endTime}`;

        hours[key] =
        <DotMaterial
            color={color}
            teacher={teacher}
            subject={classSchool}
            classRoom={classRoom}
            idClass={idClass}
            url = {url}
            teacherEmail = {teacherEmail}
            groups = {groups}
            careers = {careers}
            campus = {campus}
        />;
    }
}

/**
 * Component for show the schedule
 * @param {object} props 
 */
export function DynamicSchedule(props) {
    const hours = {};
    let rows = [];
    const { t } = useTranslation();
    const classes = useStyles();
    let toggleRow = false;
    let uniqueHours = [];
    let current_timezone = moment.tz.guess(true);
    let current_time = moment().tz(moment.tz.guess(true)).format("HH:mm:ss");
    buildSchedule(props, hours);
    console.log(current_timezone);
    console.log(current_time);

    //Build the schedule with the new datas
    for (let index = 0; index < props.hours.length; index++) {
        const element = props.hours[index];
        let start_time = moment(element.startTime, 'HH:mm:ss').tz(moment.tz.guess(true)).format("HH:mm");
        let end_time = moment(element.endTime, 'HH:mm:ss').tz(moment.tz.guess(true)).format("HH:mm");
        let hoursTxt = `${start_time}-${end_time}`;
        let rowHourIndex = `${start_time} - ${end_time}`;

        if(!uniqueHours.includes(rowHourIndex)){
            uniqueHours.push(rowHourIndex);
            rows.push(createData(rowHourIndex, hours[`Mon_${hoursTxt}`], hours[`Tue_${hoursTxt}`], hours[`Wed_${hoursTxt}`], hours[`Thu_${hoursTxt}`], hours[`Fri_${hoursTxt}`], hours[`Sat_${hoursTxt}`]));
        }
    }

    return (
        //(props.loading) ? <img className={classes.image} src={logo_eduscore} alt="Logo Eduscore"></img> :
            <Paper className={classes.root} style={{ marginRight: 10 }} >
                {
                    props.period !== "" && props.period?
                    <Typography variant="h3" align="center" className={classes.schedulePeriod} gutterBottom>
                        {props.period}
                    </Typography>:null
                }
                <Typography variant="h4" align="left" className={classes.timezone_text} gutterBottom>
                    {`${t('time_detected')}: ${current_time}`}
                </Typography>
                <Typography variant="h4" align="left" className={classes.timezone_text} gutterBottom>
                    {`${t('timezone_detected')}: ${current_timezone}`}
                </Typography>
                <TableContainer className={classes.table_container}>
                    <Table className={classes.table} >
                        <TableHead>
                            <TableRow >
                                <TableCell align="center" style={{ width: "14%", fontSize: 15 }} className={classes.cell}><b>{t('hour')}</b></TableCell>
                                <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('monday')}</b></TableCell>
                                <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('tuesday')}</b></TableCell>
                                <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('wednesday')}</b></TableCell>
                                <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('thursday')}</b></TableCell>
                                <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('friday')}</b></TableCell>
                                <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('saturday')}</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.map(row => {
                                    toggleRow = !toggleRow;
                                    return (
                                        <TableRow key={row.hour} className={classNames(toggleRow?classes.toggleRow:'')}>
                                            <TableCell align="center" style={{ fontSize: 15, height: 100 }} className={classes.cell}><b>{row.hour}</b></TableCell>
                                            <TableCell align="center" className={classes.cell}>{row.mon}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{row.tue}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{row.wed}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{row.thu}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{row.fri}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{row.sat}</TableCell>
                                        </TableRow>
                                    )}
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
    );
}