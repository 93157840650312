import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Alert } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import swal from '@sweetalert/with-react';
import Loading from '../utilities/Loading';
import EvaluationQuestionView from './EvaluationQuestionView';

const TeacherInfo = ({data}) => {
    const useStyles = makeStyles(theme => ({
        text: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: 190,
            fontSize: 20,
            color: '#37474f'
        },
        large: {
            width: 90,
            height: 90,
        },
    }));
    const classes = useStyles();
    return (
        <Table size="medium" stickyHeader >
            <TableBody>
                <TableRow className={classes.tr} hover >
                    <TableCell align="center" className={classes.thead}>
                        <Avatar className={classes.large} src={Constants.base_site + 'public/img/profile/thumbnails/' + data.picture} />
                    </TableCell>
                    <TableCell align="center" className={classes.thead}>
                        <Typography>
                            {`${data.teacherName} ${data.lastName}`}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.thead}>
                        <Typography>
                            {`${data.nameSubject}`}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.thead}>
                        <Typography>
                            {`${data.periodName}`}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default function TeacherEvaluationView ({data, refresh}) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(0);
    const [enableSaving, setUpdateSaving] = React.useState(false);
    const [formEvaluation, setFormEvaluation] = React.useState({
        loader: false,
        comment: ""
    });
    const [isActive, setActive] = React.useState({planning: true, personality: false, impartation: false, general: false});
    const questions_order = [
        { start: 0, end: 4 },
        { start: 5, end: 17 },
        { start: 18, end: 25 },
        { start: 26, end: 27 },
    ];

    const useStyles = makeStyles(theme => ({
        tabTitle: {
            color: 'black',
            fontWeight: "bold"
        },
        tabTitleText: {
            color: 'black',
            fontWeight: 600
        },
        panelTitle: {
            backgroundColor: "rgb(var(--secondary-color))"
        }
    }));
    
    const classes = useStyles();

    const [radios, setRadio] = React.useState({
        radios: {},
        radiosLength: 0
    });

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleRadioChange = (event) => {
        let radioControl = radios.radios;
        let radioControlLength = radios.radiosLength;
        radioControlLength = (radioControl[event.target.name] === undefined)?radioControlLength + 1:radioControlLength;
        radioControl[event.target.name] = event.target.value;
        setRadio({...radios,radios: radioControl, radiosLength: radioControlLength});
        switch (radioControlLength) {
            case questions_order[1].start:
                setActive({...isActive,personality: true});
            break;
            case questions_order[2].start:
                setActive({...isActive,impartation: true});
            break;
            case questions_order[3].start:
                setActive({...isActive,general: true});
            break;
            default:break;
        }
        if(radioControlLength == (questions_order[questions_order.length - 1].end + 1)){
            setUpdateSaving(true);
        }else{
            setUpdateSaving(false);
        }
    };

    const radioControlFunction = (name, value) => {
        let radio = radios.radios[name];
        if(radio == value){
            return true;
        }
        return false;
    };

    const questionSection = (info, limit) => {
        let data = [];
        for (let index = limit.start; index <= limit.end; index++) {
            data.push(info[index]);
        }
        return data;
    }

    const saveEvaluation = () => {
        if(radios.radiosLength === (questions_order[questions_order.length - 1].end + 1)){
            let comment = document.querySelector("textarea#teacher_evaluation_comment").value;
            setFormEvaluation({
                ...formEvaluation, loader: true
            });
            fetch(Constants.api + 'webApp/student/saveTeacherEvaluation', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    idEvaluationTeacherAssigned: data.teacher.idEvaluationTeacherAssigned,
                    comment: comment.trim(),
                    questions: JSON.stringify(radios.radios)
                })
            })
            .then(response => {
                return response.json()
            })
            .then(json => {
                setFormEvaluation({
                    ...formEvaluation, loader: false, comment: comment
                });
                if(json.success === true){
                    refresh();
                    swal(t('successText'), t('teacherEvaluationSuccess'), "success");
                }else{
                    swal('Error', t('teacherEvaluationFail'), "error");
                }
            }).catch(error => {
                console.log(error);
                setFormEvaluation({
                    ...formEvaluation, loader: false, comment: comment
                });
                swal('Error', t('messageError'), "error");
            });
        }else{
            swal(t('teacherEvaluationSavingErrorTitle'), t('teacherEvaluationSavingErrorTxt'), "warning");
        }
    };

    const teacherAnswers = [
        { answer: t("teacherEvaluationAnswer1"), value: 1},
        { answer: t("teacherEvaluationAnswer2"), value: 2},
        { answer: t("teacherEvaluationAnswer3"), value: 3},
        { answer: t("teacherEvaluationAnswer4"), value: 4},
        { answer: t("teacherEvaluationAnswer5"), value: 5},
    ];

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>

        {
            formEvaluation.loader ? <Loading /> :
            <Container style={{marginTop: 20, background: 'rgb(var(--secondary-color))'}}>
                <Grid key={`gridTeachersEvaluations`} container spacing={3} direction="row" justify="center" >
                    <Grid item key={`gridTeachersEvaluationsTeacher`} xs={12} sm={12} md={12} lg={12}  >
                        <TeacherInfo data={data.teacher} />
                    </Grid>
                    <Grid item key={`gridTeachersEvaluationsContent`} xs={12} sm={12} md={12} lg={12} >
                        <ExpansionPanel expanded={expanded === 0} onChange={handleChange(0)} disabled={!isActive.planning} style={{ background: 'rgb(var(--secondary-color))'}}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelTitle} style={{ background: 'rgb(var(--secondary-color))' }}>
                                <Typography className={classes.heading} style={{ background: 'rgb(var(--secondary-color))'}}>
                                    <b style={{ background: 'rgb(var(--secondary-color))', display: 'block' }}>{t("teacherEvaluationPlanning")}</b>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ background: 'rgb(var(--primary-color))' }}>
                                    {
                                        questionSection(data.questions,questions_order[0]).map((question, q) => {
                                            return (
                                                <EvaluationQuestionView question={question} key={`gridTeachersEvaluationsQuestions${question.idQuestion}`} radioControlFunction={radioControlFunction} handleRadioChange={handleRadioChange} answers={teacherAnswers} />
                                            );
                                        })
                                    }
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 1} onChange={handleChange(1)} disabled={!isActive.personality} >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelTitle} >
                                <Typography className={classes.heading} style={{ background: 'rgb(var(--secondary-color))' }}>
                                    <b style={{ background: 'rgb(var(--secondary-color))' }}>{t("teacherEvaluationPersonality")}</b>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ background: 'rgb(var(--primary-color))' }}>
                                    {
                                        questionSection(data.questions,questions_order[1]).map((question, q) => {
                                            return (
                                                <EvaluationQuestionView question={question} key={`gridTeachersEvaluationsQuestions${question.idQuestion}`} radioControlFunction={radioControlFunction} handleRadioChange={handleRadioChange} answers={teacherAnswers} />
                                            );
                                        })
                                    }
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 2} onChange={handleChange(2)} disabled={!isActive.impartation} >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelTitle} >
                                <Typography style={{ background: 'rgb(var(--secondary-color))'}} className={classes.heading}>
                                    <b style={{ background: 'rgb(var(--secondary-color))'}}>{t("teacherEvaluationImpartation")}</b>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ background: 'rgb(var(--primary-color))' }}>
                                    {
                                        questionSection(data.questions,questions_order[2]).map((question, q) => {
                                            return (
                                                <EvaluationQuestionView question={question} key={`gridTeachersEvaluationsQuestions${question.idQuestion}`} radioControlFunction={radioControlFunction} handleRadioChange={handleRadioChange} answers={teacherAnswers} />
                                            );
                                        })
                                    }
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 3} onChange={handleChange(3)} disabled={!isActive.general} >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelTitle} >
                                <Typography style={{ background: 'rgb(var(--secondary-color))'}} className={classes.heading}>
                                    <b style={{ background: 'rgb(var(--secondary-color))'}}>{t("teacherEvaluationGeneral")}</b>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ background: 'rgb(var(--primary-color))' }}>
                                    {
                                        questionSection(data.questions,questions_order[3]).map((question, q) => {
                                            return (
                                                <EvaluationQuestionView question={question} key={`gridTeachersEvaluationsQuestions${question.idQuestion}`} radioControlFunction={radioControlFunction} handleRadioChange={handleRadioChange} answers={teacherAnswers} />
                                            );
                                        })
                                    }
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}  >
                        <Alert severity="info">
                            <Typography>
                                {t('teacherEvaluationCommentInfo')}
                            </Typography>
                        </Alert>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}  >
                        <TextField
                            id="teacher_evaluation_comment"
                            label={t("teacherEvaluationCommentLabel")}
                            multiline
                            variant="outlined"
                            fullWidth
                            defaultValue={formEvaluation.comment}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}  >
                        <Button variant="contained" color="primary" fullWidth disabled={!enableSaving} onClick={saveEvaluation} >
                            {t("teacherEvaluationSend")}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        }
        </div>
    );
}