import React, { useState, useEffect } from 'react';
import {Availability as AvailabilityScreen} from '../components/Availability/Availability.js';
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import { useTranslation } from 'react-i18next';
import ErrorOnLoading from '../utilities/ErrorOnLoading';

export function Availability(){
    const [error, setError] = useState({
        flag: false,
        status: ''
    });
    const [loading,setLoading] = useState(true);
    const [state, setState] = useState();
    const { t } = useTranslation();

    const fetchData = () =>{
        fetch(Constants.api + 'webApp/availability/getAvailability', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            setState(data);
            setLoading(false);
        })
        .catch(error => {
            setError({
                ...error,
                flag: true,
                status: error
            })
        });
    }

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line
    }, [])

    return(
        (error.flag) ? <ErrorOnLoading type={error.status}/> :
        (loading)? <Loading /> :
        (state.status === 1)?
            <AvailabilityScreen data={state.data} period={state.period}/>:
            <p style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                marginTop: 190,
                fontSize: 20,
                color: 'rgb(var(--primary-text-color))'
            }} >{t('noAccess')}</p>

    );
}
