import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ErrorIcon from '@material-ui/icons/Error';
import { Constants } from '../utilities/constants';
import logo_eduscore from '../utilities/images/logoloader.gif';
import { useTranslation } from 'react-i18next';
import { ListItem } from '@material-ui/core';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';
import TelegramIcon from '@material-ui/icons/Telegram';
import { myFirestore } from '../utilities/firebase';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
//import Linkify from 'react-linkify';

/**
 * Styles for view screen in te chat screen
 */
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: 'rgb(var(--secondary-color))'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        //width: '50%',
    },
    text: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        marginTop: 190,
        fontSize: 20,
        color: 'rgb(var(--primary-text-color))'
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        backgroundColor: 'rgb(var(--secondary-color))'
    },
    drawer: {
        maxHeight: '90vh',
        paddingTop: 10,
        display: 'flex',
        flex: 0.25,
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: 'rgb(var(--secondary-color))'
    },
    viewListContentChat: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        overflowY: 'auto',
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 50,
        paddingRight: 50,
        height: 1000,
        backgroundColor: 'rgb(var(--secondary-color))'
    },
    viewListContent: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        overflowY: 'auto',
        paddingTop: 10,
        paddingBottom: 20,
        height: 1000,
        backgroundColor: 'rgb(var(--secondary-color))'
    },
    header: {
        paddingBottom: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        borderBottom: '1px solid rgba(var(--primary-text-color), 0.1)',
        backgroundColor: 'rgb(var(--secondary-color))'
    },
    input: {
        backgroundColor: 'rgb(var(--secondary-color))',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        paddingTop: 12,
        borderTop: '1px solid red',
    },
    viewBoard: {
        backgroundColor: 'rgb(var(--secondary-color))',
        display: 'flex',
        flex: 1,
        maxHeight: '90vh'
    },
    search: {
        height: 90,
        padding: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: 'rgb(var(--secondary-color))'
    },
    margin: {
        margin: theme.spacing(1),
    },
    avatar: {
        margin: 10,
        backgroundColor: 'rgba(var(--primary-text-color), 0.2)'
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            padding: '2px 0'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0)'
            //outline: '1px solid slategrey'
        }
    }
}));

/**
 * Styles for textfield
 */
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'rgb(var(--primary-color))',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'orange',
            },
        },
    },
})(TextField);


/**
 * View the complete screen
 */
export function Chat() {
    return (
        <div>
            <UsersView />
        </div>
    );
}

/**
 * The first screen for show when the screen was loaded
 * @param {object} props 
 */
function UsersView(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({
        flag: false,
        status: ''
    });
    const [filter, setFilter] = useState('');
    const [init, setInit] = useState(true);
    const [name, setName] = useState('');
    const [idUser, setIdUser] = useState('');
    const isCancelled = React.useRef(false);
    const [chat, setChat] = useState({
        name: '',
        idFirebaseChat: 0
    });

    /**
     * Filter by name
     */
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = state.filter(item => {
        return Object.keys(item).some(key =>
            item[key].name.toLowerCase().includes(lowercasedFilter)
        );
    });


    /**
    * Fetch data using the API
    */
    const fetchChat = () => {
        fetch(Constants.api + 'webApp/getUsersChat', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response =>{
                if(!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                if (!data.error) {
                    if (!isCancelled.current) {
                        setIdUser(data.idUser);
                        setName(data.name);
                        setState(data.groups);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                    setError({
                        ...error,
                        flag: true,
                    });
                }
            })
            .catch(error => {
                setError({
                    ...error,
                    flag: true,
                    status: error
                });
            });
    }

    /**
     * Set online or offline user
     * @param {int} status 
     */
    const setOnline = (status) => {
        fetch(Constants.api + 'webApp/setStatus', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                status: status
            })
        })
            .then(response => { })
            .catch(error => {console.log(error) })
    }

    /**
     * Init state for online or offline
     */
    useEffect(() => {
        setOnline(1); //online
        return () => {
            setOnline(0); //offline
        };
        // eslint-disable-next-line
    }, [])

    /**
     * Init state
     */
    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchChat();
        }, 1000);
        return () => {
            isCancelled.current = true;
            clearInterval(intervalId);
        }
    // eslint-disable-next-line
    }, [])

    /**
     * handleChange for the search
     * @param {object} event 
     */
    const handleChange = event => {
        setFilter(event.target.value);
    };

    /**
     * Render the view
     */
    return (
        (error.flag) ? <ErrorOnLoading type={error.status}/> :
            (loading) ? <Loading /> :
                <div style={{ display: 'flex', background: 'rgb(var(--secondary-color))' }}>
                    <div className={classes.drawer}>
                        <CssTextField
                            className={classes.margin}
                            label={t('search')}
                            variant="outlined"
                            id="custom-css-outlined-input"
                            style={{ boxShadow: '5px 5px 10px rgba(0,0,0,0.05)' }}
                            value={filter}
                            onChange={handleChange}
                        />
                        {/** USERS */}
                        <div className={classes.viewListContent} >
                            <List>
                                {filteredData.map((item, index) => (
                                    <ListItem key={index}>
                                        <Button style={{
                                            fontSize: 11,
                                            width: '100%',
                                            textTransform: 'none',
                                            fontWeight: 'bold',
                                            color: 'orange',
                                            backgroundColor: 'rgb(var(--primary-color))',
                                            display: 'flex',
                                            boxShadow: '5px 5px 10px rgba(0,0,0,0.10)'
                                        }}
                                            onClick={
                                                //Set data for show the chat screen for users
                                                () => {
                                                    setInit(false);
                                                    setChat({
                                                        ...chat,
                                                        name: item[0].name,
                                                        idFirebaseChat: item[0].idFirebaseChat
                                                    });
                                                }
                                            }
                                        >
                                            <div>
                                                <Avatar className={classes.avatar}
                                                    src={Constants.base_site + 'public/img/profile/' + item[0].picture}
                                                />
                                            </div>
                                            <div style={{ width: 110 }}>
                                                {item[0].name}
                                            </div>
                                            {
                                                (item[0].notification === 0) ?
                                                    <div style={{ color: 'white' }} ><ArrowForwardIosIcon /></div> :
                                                    <div style={{ color: 'red', marginBottom: 50 }}><ErrorIcon /></div>
                                            }
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </div>
                    {(init) ? <WelcomeScreen name={name} /> :
                        <ChatScreen
                            chat={chat}
                            name={name}
                            idUser={idUser}
                        />
                    }
                </div>
    )
}

/**
 * Chat screen for show the messages
 * @param {object} props
 */
function ChatScreen(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [message, setMessage] = useState('');
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const idFirebaseChat = props.chat.idFirebaseChat;
    const CurrentTimestamp = Date.now();
    const myName = props.name;
    // Variable for down scroll by index
    let elem = document.getElementById(`i${content.length - 1}`);

    /**
     * Style for the recipent
     */
    const styleRecipent = {
        borderRadius: '10px 0px 10px 10px',
        backgroundColor: 'rgb(var(--complementary))',
        //marginRight: 50,
        padding: '1.5vh',
        fontWeight: 'bold',
        color: 'rgb(var(--primary-color))',
        fontSize: 13,
        border: 'none',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.10)'
    }

    /**
     * Style for the msg 
     */
    const styleSender = {
        borderRadius: '0px 10px 10px 10px',
        backgroundColor: 'rgb(var(--primary-color))',
        padding: '1.5vh',
        //marginLeft: 50,
        fontWeight: 'bold',
        color: 'rgb(var(--primary-text-color))',
        fontSize: 13,
        border: 'none',
        boxShadow: '-5px -5px 5px  rgba(0,0,0,0.15)',
    }

    /**
     * Send message and save in the database
     */
    const sendMessage = () => {
        //Don't send if there is a space
        if (message.trim().length !== 0) {
            myFirestore
                .collection('messages')
                .doc(idFirebaseChat.toString())
                .collection(idFirebaseChat.toString())
                .add({
                    'text': message,
                    'senderName': myName,
                    'idUser': props.idUser.toString(),
                    'timestamp': CurrentTimestamp
                });
            sendNotification(message);
            setMessage('');

        }
    }

    /**
     * Set notification
     * @param {string} idUser 
     * @param {string} idFirebaseChat 
     * @param {string} status 
     */
    const setNotification = (idUser, idFirebaseChat, status) => {
        fetch(Constants.api + 'webApp/setNotification', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idUser: idUser,
                idFirebaseChat: idFirebaseChat,
                flagNotification: status
            })
        })
            .then(response => { })
            .catch(error => { })
    }

    /**
     * Send notification
     * @param {string} msg 
     */
    const sendNotification = (msg) => {
        fetch(Constants.api + 'webApp/sendNotification', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idUser: props.idUser.toString(),
                idFirebaseChat: idFirebaseChat,
                sender: props.name,
                msg: msg
            })
        })
            .then(response => { })
            .catch(error => { })
    }

    /**
     * Convert timestamp to hour
     * @param {int} number 
     */
    const timestamp = (number) => {
        return new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' })
            .format(number)
    }

    /**
     * set status depending last message
     * @param {object} info 
     */
    const setStatus = (info, firstState) => {
        if (info.length !== 0) {
            let lastUser = info[info.length - 1].idUser;
            let currentUser = props.idUser;
            // eslint-disable-next-line
            if ((currentUser == lastUser) && !firstState) {
                //Put notification message in my user
                setNotification(currentUser, props.chat.idFirebaseChat, '1');
            } else {
                // nothing notification in my user
                setNotification(lastUser, props.chat.idFirebaseChat, '0');
            }

        }

    }

    /**
     * Use effect init state
     */
    useEffect(() => {
        // first state in true
        let firstState = true;
        //Save data for show in the chat
        let data = [];
        const unsubscribe = myFirestore
            .collection('messages')
            .doc(idFirebaseChat.toString())
            .collection(idFirebaseChat.toString())
            .orderBy("timestamp")
            .onSnapshot(
                snapshot => {
                    snapshot.docChanges().forEach(change => {
                        data.push(change.doc.data());
                    })
                    // Second state
                    setStatus(data, firstState);
                    setContent(data);
                    setLoading(false);
                    firstState = false;
                },
                error => {
                    setError(true);
                }
            )
        return () => {
            unsubscribe();
            setLoading(true);
        }
        // eslint-disable-next-line 
    }, [idFirebaseChat])

    /**
     * Scroll down when send a message
     */
    useEffect(() => {
        // eslint-disable-next-line
        elem = document.getElementById(`i${content.length - 1}`);
        elem && elem.scrollIntoView();
    }, [elem, content])

    /**
     * Render view
     */
    return (
        // ternary operators for show the loading or the error
        (error) ?
            <p style={{
                fontSize: 20,
                fontWeight: 'bold',
                color: '#37474f',
                marginTop: '30%',
                marginLeft: '25%'
            }}>
                <center>Error : {t('informationError')} </center></p> :
            (loading) ?
                <div className={classes.image}>
                    <img src={logo_eduscore} alt="Logo Eduscore"></img>
                </div> :
                <div className={classes.viewBoard}>
                    <div className={classes.content}>
                        <div className={classes.header}>
                            <div style={{
                                paddingTop: '3%',
                                fontWeight: 'bold',
                                backgroundColor: 'rgb(var(--secondary-color))'
                            }}>
                                <center>{props.chat.name}</center>
                            </div>
                        </div>

                        {/**Content */}
                        <div className={classes.viewListContentChat}  >
                            {
                                content.map((item, index) => (
                                    <div key={index} id={'i' + index} style={{ backgroundColor: 'rgb(var(--secondary-color))' }}>
                                        <p style={{ textAlign: (item.senderName === myName) ? 'right' : 'left' }}>
                                            <button style={(item.senderName === myName) ? styleRecipent : styleSender}  disabled>
                                                <p style={{
                                                    textAlign: (item.senderName === myName) ? 'right' : 'left',
                                                    fontSize: 9,
                                                    marginBottom: -5
                                                }}>
                                                    {timestamp(item.timestamp)}
                                                </p>
                                            </button>
                                        </p>
                                    </div>
                                ))
                            }
                        </div>

                        {/* INPUTS */}
                        {/*elem && elem.scrollIntoView()*/}
                        <div className={classes.input}>
                            <Grid container spacing={0} style={{ marginLeft: '15%' }}>
                                <Grid item xs={9}>
                                    <input
                                        placeholder={t('messageChat')}
                                        type='text'
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: 10,
                                            backgroundColor: 'rgb(var(--primary-color))',
                                            fontSize: 13,
                                            color: 'rgb(var(--primary-text-color))',
                                            outline: 'none',
                                            padding: 10
                                        }}
                                        name="message"
                                        value={message}
                                        autoComplete="off"
                                        onChange={(event) => { setMessage(event.target.value) }}
                                        onKeyDown={
                                            (e) => {
                                                if (e.key === 'Enter') {
                                                    sendMessage();
                                                }
                                            }}
                                    >
                                    </input>
                                </Grid>
                                <Grid item xs={1}>

                                    <button style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        outline: 'none',
                                        cursor: 'pointer'
                                    }}
                                        onClick={sendMessage}
                                    >
                                        <TelegramIcon fontSize="large" style={{ color: 'rgb(var(--primary-text-color))', marginLeft: 5 }} />
                                    </button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
    )
}

/**
 * Welcome screen
 * @param {object} props 
 */
function WelcomeScreen(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.content}>
            <center style={{
                fontSize: 20,
                fontWeight: 'bold',
                color: 'rgb(var(--primary-text-color))',
                marginTop: '30%'
            }}>
                {t('welcome') + ', ' + props.name}
            </center>
        </div>
    )
}
