import React, {useEffect} from 'react';
import { Drawer as DrawerScreen } from "../components/Drawer/Drawer";
import { Constants } from '../utilities/constants';
import { useTranslation } from 'react-i18next';
import swal from '@sweetalert/with-react'
import {showError} from '../utilities/errorOnFetch';
import { TranslationTitle } from '../utilities/routes';
/**
 * Build the drawer component
 * @param {object} props
 */
export default function Drawer(props) {
    //const context = React.useContext(AppContext);
    const { t } = useTranslation();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        name: '',
        email: '',
        picture: '',
        modules: []
    });

    /**
     * Init function
     */
    useEffect(() => {
        fetchDrawer()
    // eslint-disable-next-line
    }, [])

    /**
     * Fetch data using the API
     */
    const fetchDrawer = () => {
        fetch(Constants.api + 'webApp/drawer', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            const info = data.response[0].resultInfo[0];
            data.response[0].items.map((item, index) => {
                return item.translation = TranslationTitle(item.name, t);
            });
            setState({
                ...state,
                name: info.name,
                email: info.email,
                picture: Constants.base_site + 'public/img/profile/thumbnails/' + info.picture,
                modules: data.response[0].items //items for the drawer
            })
        })
        .catch(error => {
            //three parameters, original msg, num of error and t, traduction
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    /**
     * Open the drawer
     * @param {object} side 
     * @param {object} open 
     */
    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        /*if(open){
            props.onSelectModule({name: 'Perfil', route:'/profile'})
            console.log()
        }*/
        setState({ ...state, [side]: open });
    };

    return (
        <DrawerScreen 
            toggleDrawer = {toggleDrawer}
            state = {state}
            opener = {props.opener}
            onSelectModule = {props.onSelectModule}
        />
    );
}