import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Constants } from '../utilities/constants';
import { useTranslation } from 'react-i18next';
import { Person as PersonIcon, Lock as LockIcon, LockOpen as LockOpenIcon, 
    VpnKey as KeyIcon, Email as EmailIcon, MailOutline as MailOutlineIcon,
     Drafts as MailOpenIcon, School as SchoolIcon, Policy as PolicyIcon,
    Info as InfoIcon, Delete as DeleteIcon,
    ContactPhone as ContactIcon, VerifiedUser as VerifiedUserIcon } from '@material-ui/icons';
import swal from '@sweetalert/with-react';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo_eduscore from '../utilities/images/logo_eduscore.png';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {showError} from '../utilities/errorOnFetch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert, AlertTitle } from '@material-ui/lab';

/**
 * Component for the tab panel
 * @param {object} props 
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

/**
 * Select the index to change
 * @param {int} index 
 */
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/**
 * Styles for components
 */
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'red',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 50
    },
    appBar: {
        backgroundColor: 'rgb(var(--primary-color))',
        zIndex: 2,
        color: 'white'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        //width: '50%',
    },
    text: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        marginTop: 190,
        fontSize: 20,
        color: 'rgb(var(--primary-text-color))'
    },
    textColor: {
        color: 'rgb(var(--primary-text-color))'
    },
    mailSchool: {
        color: '#888888',
        marginTop: 10
    },
    titles: {
        backgroundColor: '#e6e6e6'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'red',
    },
    table: {
        //minWidth: 650,
    },
}));

/**
 * Styles for textfield
 */
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'rgb(var(--primary-text-color))',
        },
        '& label.Mui': {
            color: 'rgb(var(--primary-text-color))',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(var(--primary-text-color))',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(var(--primary-text-color))',
            },
            borderColor: 'rgb(var(--primary-text-color))',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '2px solid rgb(var(--primary-text-color))'
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            color: 'rgb(var(--primary-text-color))'
        },
        '& .MuiInputBase-root::placeholder': {
            color: 'rgb(var(--primary-text-color))'
        }
    },
})(TextField);

/**
 * Main view profile
 * @param {Objects} props
 */
function ProfileMainview(props){
    const classes = useStyles();
    return (
        <div style={{ background: 'rgba(0,0,0,0)' }}>
            <div style={{ background: "rgba(var(--primary-text-color), 0.1)", height: 200, margin: -24}}>&nbsp;</div>
            <div style={{ background: 'transparent', maxWidth: 830, width: '100%', margin: '0 auto' }}>
                <Avatar className={classes.avatar} style={{
                    width: 200, maxWidth: 250, height: 200, maxHeight: 250,
                    margin: '-100px auto 0px auto', backgroundColor: 'rgba(var(--primary-color), 1)',
                }}
                src={props.picture}
            />
            </div>
            <div style={{ background: 'rgba(0,0,0,0)' }}>
                <Typography variant="h5" className={classes.textColor} gutterBottom style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 15 }}>
                    {props.name}
                </Typography>
                <Typography variant="h6" className={classes.textColor} gutterBottom style={{ textAlign: 'center', marginTop: 15 }}>
                    {props.career}
                </Typography>
                <Typography variant="h6" className={classes.textColor} gutterBottom style={{ textAlign: 'center', marginTop: 'calc(5% + 28vh)' }}>
                    {props.campus}
                </Typography>
            </div>
        </div>
    );
}

/**
 * View change password
 * @param {Object} props 
 */
function ProfileChangePassword(props){
    const classes = useStyles();
    const { t } = useTranslation();
    return(
        <div style={{ background: 'transparent' }}>
            <div style={{ background: 'transparent', maxWidth: 730, width: '100%', margin: '0 auto'}}>
                <form className={classes.container} autoComplete="off" onSubmit={props.handleSubmit} >
                    <Typography variant="h5" className={classes.textColor} gutterBottom style={{ textAlign: 'center', width: '100%', marginTop: 15, fontWeight: 'bold', }}>
                        {t('changePassword')}
                    </Typography>
                    <div style={{ background: 'transparent', width: '100%' }}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={1}>
                                <KeyIcon className={classes.textColor} />
                            </Grid>
                            <Grid item xs={10}>
                                <CssTextField
                                    value={props.currentPassword}
                                    onChange={props.handleOnChange}
                                    name="currentPassword"
                                    id="custom-css-standard-input"
                                    label={t('currentPassword')}
                                    margin="normal"
                                    type="password"
                                    fullWidth={true}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ background: 'transparent', width: '100%' }}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={1}>
                                <LockOpenIcon className={classes.textColor} />
                            </Grid>
                            <Grid item xs={10}>
                                <CssTextField
                                    value={props.newPassword}
                                    onChange={props.handleOnChange}
                                    name="newPassword"
                                    label={t('newPassword')}
                                    margin="normal"
                                    type='password'
                                    fullWidth={true}
                                    required
                                />
                            </Grid>
                            {
                                (props.validate) ?
                                    <div style={{ color: 'red', marginLeft: '10%', fontSize: 12 }}>{t('requireCharacter')}</div> : <p>{}</p>
                            }
                        </Grid>
                    </div>
                    <div style={{ background: 'transparent', width: '100%' }}>
                        <Grid container spacing={1} alignItems="flex-end" style={{ width: '100%' }}>
                            <Grid item xs={1}>
                                <LockIcon className={classes.textColor} />
                            </Grid>
                            <Grid item xs={10}>
                                <CssTextField
                                    value={props.repeatPassword}
                                    onChange={props.handleOnChange}
                                    name="repeatPassword"
                                    label={t('repeatNewPassword')}
                                    margin="normal"
                                    type='password'
                                    fullWidth={true}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '15px auto 0px', background: '#ff7600', color: 'rgb(var(--primary-color))' }}
                        size='large'
                        type='submit'
                        value='Submit'
                    >
                        {t('changePassword')}
                    </Button>
                </form>
            </div>
        </div>
    );
}

function ProfileChangeEmail(props){
    const { t } = useTranslation();
    const matches = useMediaQuery('(min-width:600px)'); //using media query for view by smartphone
    const classes = useStyles();
    const [state, setState] = useState(""); //variable aux save the new email
    const [email, setEmail] = useState("");   //Show the current email
    const [password, setPassword] = useState(""); // save the password
    /**
     * Event for change state
     * @param {object} event 
     */
    const handleOnChange = event => {
        const {name,value} = event.target
        switch (name) {
            case 'validate':
                setState(value); // variable is has data show the password field or not
                break;
            case 'password':
                setPassword(value); // save the password
                break;
            default:
                break;
        }
    }

    /**
     * Change email API
     */
    const changeEmail = ()=>{
        fetch(Constants.api + 'webApp/profile/changeEmail', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: state,
                password: password
            })
        })
        .then(response =>{
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data['affectedRows'] > 0){
                setEmail(state); //change the current email
                setState('');
                setPassword('');
                swal(t('modalSuccessTitle'), t('successEmailChange'), "success");
            }else{
                swal('ERROR', t('errorCurrentPasswordWrong'), "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    /**
     * Handle submit
     * @param {Object} evt 
     */
    const handleSubmit = (evt) => {
        evt.preventDefault();
        changeEmail();
    }

    return(
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <div style={{ maxWidth: 730, width: '100%', margin: '0 auto', background: 'rgb(var(--secondary-color))'}}>
                <form className={classes.container} autoComplete="off" onSubmit={handleSubmit} >
                    <Typography variant="h5" className={classes.textColor} gutterBottom style={{ textAlign: 'center', width: '100%', marginTop: 15, fontWeight: 'bold', }}>
                        {t('email')}
                    </Typography>
                    <div style={{ width: '100%', background: 'rgb(var(--secondary-color))' }}>
                        <Grid container spacing={1} alignItems="flex-end" >
                            <Grid item xs={(matches)?6:12} className={classes.titles}>
                                <div className={classes.textColor} style={{ background: 'rgb(var(--secondary-color))' }}>
                                    <center>
                                        <SchoolIcon className={classes.mailSchool} />&nbsp;{t('schoolEmail')}
                                    </center>
                                </div>
                                <div style={{ background: 'rgb(var(--secondary-color))' }}><center>{(props.schoolEmail===null)?t('noEmail'):props.schoolEmail}</center></div>
                            </Grid>
                            <Grid item xs={(matches)?6:12} className={classes.titles}>
                                <div className={classes.textColor} style={{ background: 'rgb(var(--secondary-color))' }}>
                                    <center>
                                        <MailOutlineIcon className={classes.mailSchool} />&nbsp;{t('ownEmail')}
                                    </center>
                                </div>
                                <div style={{ background: 'rgb(var(--secondary-color))' }}><center>{(email==="")?props.ownEmail:email}</center></div>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ width: '100%', background: 'rgb(var(--secondary-color))' }}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={1}>
                                <MailOpenIcon className={classes.textColor} />
                            </Grid>
                            <Grid item xs={10}>
                                <CssTextField
                                    value={state}
                                    onChange={handleOnChange}
                                    name = "validate"
                                    margin="normal"
                                    type="email"
                                    label= {t('newEmail')}
                                    fullWidth={true}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ width: '100%', background: 'rgb(var(--secondary-color))' }}>
                        {
                        (state === "")? null : 
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={1}>
                                <LockIcon className={classes.textColor} />
                            </Grid>
                            <Grid item xs={10}>
                                <CssTextField
                                    value={password}
                                    onChange={handleOnChange}
                                    name = "password"
                                    margin="normal"
                                    type='password'
                                    autoComplete="new-password"
                                    label = {t('currentPassword')}
                                    fullWidth={true}
                                    required
                                />
                            </Grid>
                        </Grid>
                        }
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '15px auto 0px', background: '#ff7600', color: 'rgb(var(--primary-color))' }}
                        size='large'
                        type='submit'
                        value='Submit'
                    >
                        {t('changeEmail')}
                    </Button>
                </form>
            </div>
        </div>
    );
}

/**
 * Show the policies 
 */
function PrivacyPolicy(){
    const { t } = useTranslation();
    return(
        <div style={{background: 'transparent', marginRight:'15%', marginLeft:'15%'}}>
           <h2 style={{color:'#ff7600'}}><center>{t('policy')}</center></h2>
           <center><img style={{width:'35%'}}src={logo_eduscore} alt="Logo Eduscore"></img></center>
           <br/>
           <p>De acuerdo a la Ley Federal de Protección de Datos Personales en Posesión de Particulares, 
           EDUSCORE, con domicilio en Chimalhuacán #20 Int. 2-B, Col. Ciudad del Sol, Zapopan, Jalisco, México,
            C.P. 45050, como responsable del tratamiento de sus datos personales, le notifica que los datos que nos proporciona serán 
            utilizados para proveerle de los servicios e información que nos ha solicitado.</p>
            <br />
            <p>
            Hacemos de su conocimiento que los datos personales que usted libre y voluntariamente 
            proporcione a Eduscore a través de los diferentes portales de internet, mediante la utilización 
            de nuestros servicios en línea, por escrito, vía telefónica y/o por otros medios distintos serán 
            conservados por el tiempo que sea estrictamente necesario y podrán ser transferidos a otros 
            departamentos de esta misma institución y con la Secretaria de Educación Pública (SEP) con los 
            siguientes fines:
            </p>
            <br />
            <ul>
                <li>Para proveer los servicios, productos e información que nos ha solicitado.</li>
                <li>Comunicarle sobre los cambios en los servicios.</li>
                <li>Evaluar la calidad del servicio que le brindamos.</li>
            </ul>
            <br />
            <p>
            Es importante informarle que tiene derecho al acceso, rectificación y cancelación de sus datos, 
            así como de oponerse al tratamiento de los mismos y revocar el consentimiento que para tal fin 
            nos haya otorgado. Para conocer los procedimientos, requisitos y plazos que hemos implementado 
            para estos fines, puede dirigir su solicitud a nuestro departamento de datos personales en 
            <a title="informes UNIAT" href= "mailto:informes@uniat.edu.mx"> informes@uniat.edu.mx.</a>
            </p>
            <br />
            <p>
            Este Aviso de Privacidad fue modificado por última vez el 27 de Marzo del 2015.
            </p>
        </div>
    )
}

function AutocompleteSelect({options_data, select_value, on_change, select_name, default_option}){
    let options = [default_option];
    for (let index = 0; index < options_data.length; index++) {
        const element = options_data[index];
        options.push({value: element.id, label: element.name});
    }
    return (
        <Autocomplete
        options={options}
        value={select_value}
        onChange={(event, value) => {
            on_change(event, value, select_name);
        }}
        disableClearable
        getOptionLabel={(option) => {
            return option.label?option.label:option.toString();
        }}
        getOptionSelected={(option, value) => {
            return option.value === value.value ? true : false;
        }}
        getOptionDisabled={(option) => {
            return option.value === 0 ? true : false;
        }}
        renderInput={(params) => <TextField {...params} margin="normal" placeholder='Test' />}
      />
    );
}

/**
 * Show the complete info
 */
function Info(props){
    const classes = useStyles();
    const { t } = useTranslation();
    let default_select_option = {value: 0, label: t("default_select_value")};
    const [number, setNumber] = useState('');
    const telephone = (props.telephone === null)? '' : props.telephone.split(','); // split numbers by ','
    //Data for show in the table telephone
    const [row, setRow] = useState([]);
    const [user_address, setUserAddress] = useState({
        street: props.street,
        neighborhood: props.neighborhood,
        numberExt: props.numberExt,
        numberInt: props.numberInt,
        cp: props.zip
    });

    const [states, set_states] = useState({
        options: props.regions_select,
        value: props.region.value === 0 ? default_select_option : props.region,
        update: false
    });
    const [cities, set_cities] = useState({
        options: props.cities_select,
        value: props.city.value === 0 ? default_select_option : props.city,
        update: false
    });
    const [countries, set_countries] = useState({
        options: props.countries_select,
        value: props.country.value === 0 ? default_select_option : props.country,
        update: false
    });

    
    /**
     * Get telephones
     */
    const getTelephones=()=>{
        const aux = []; //aux for save the number
        for(let i= 0; i<telephone.length; i++){
            aux.push({
                'number': telephone[i]
            });
        }
        return aux;
    }

    /**
     * Function on init
     */
    useEffect(() => {
        setRow(getTelephones());
    // eslint-disable-next-line
    }, [])

    /**
     * Event for change state
     * @param {object} event 
     */
    const handleOnChange = event => {
        const re =/^[0-9\b]+$/;
        const {value} = event.target
        if(value === '' || re.test(value)){
            setNumber(value)
        }
    }

    /**
     * insert telephone in the database
     * @param {Integer} number
     */
    const insertTelephone = (number)=>{
        fetch(Constants.api + 'webApp/profile/insertTelephone', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idPerson: props.idPerson,
                number: number,
                type: (number.length === 10)? 'Celular': 'Casa',
                lead: 'Propio'
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data.status === 1){
                setRow([
                    ...row,
                    {'number': number}
                ]);
                setNumber('');
            }else if(data.status === 0){
                swal('Error', t('profile_personal_duplicate_telephone_swal'), "warning");
            }else{
                swal('Error', t('showError'), "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    /**
     * Delete telephone number just change the status 1 to 0
     * @param {Integer} number
     */
    const deleteTelephone = (number)=>{
        fetch(Constants.api + 'webApp/profile/deleteTelephone', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idPerson: props.idPerson,
                number: number
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            setRow(row.filter(item=> item.number !== number));
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    /**
     * When click the button
     */
    const addTelephone = () =>{
        console.log(number.length)
        if(number.length < 11 && number.length > 0){
            insertTelephone(number)
        } else if(number.length === 0){
            swal("Error", t('nullNum'), "error");
        }else{
            swal("Error", t('errorTelephone'), "error");
        }
    }

    const handle_address_change = event => {
        const {name, value} = event.target;
        setUserAddress({...user_address, [name]: value});
    }

    const handle_autocomplete_address_change = (event, data, select_name) => {
        if(data === null){
            data = default_select_option;
        }
        switch (select_name) {
            case "country":
                set_countries({...countries, value: data, update: true});
            break;
            case "region":
                set_states({...states, value: data, update: true});
            break;
            case "city":
                set_cities({...cities, value: data, update: true});
            break;
            default:
            break;
        }
    }

    const save_address = () => {
        let data = user_address;
        let show_error = false;
        let error_text = "";
        data.city = cities.value.value;
        data.region = states.value.value;
        data.country = countries.value.value;
        if(data.country === 0){
            show_error = true;
            error_text = t("profile_missing_country");
        }
        if(data.region === 0){
            show_error = true;
            error_text = t("profile_missing_region");
        }
        if(data.city === 0){
            show_error = true;
            error_text = t("profile_missing_city");
        }
        if(!show_error){
            fetch(Constants.api + 'webApp/profile/save_address', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: data
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                if(data.status === 1){
                    swal(t('successText'), t("success_save_info"), "success");
                }else{
                    swal('Error', t('showError'), "error");
                }
            })
            .catch(error => {
                swal("Error",
                showError(t('errorData'), error, t) ,
                "error");
            });
        }else{
            swal('Error', error_text, "error");
        }
    }

    const get_country_states = (country_data) => {
        fetch(Constants.api + 'webApp/profile/get_country_states', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                country: country_data.value
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data.status === 1){
                set_states({...states, value: default_select_option, options: data.data, update: true});
            }else{
                swal('Error', t('showError'), "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    const get_state_cities = (state) => {
        fetch(Constants.api + 'webApp/profile/get_state_cities', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                state: state.value
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data.status === 1){
                set_cities({...cities, value: default_select_option, options: data.data, update: true});
            }else{
                swal('Error', t('showError'), "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    useEffect(() => {
        if(countries.update){
            get_country_states(countries.value);
        }
        // eslint-disable-next-line
    }, [countries]);

    useEffect(() => {
        if(states.update){
            get_state_cities(states.value);
        }
        // eslint-disable-next-line
    }, [states]);

    return(
        <div style={{background: 'transparent', marginRight:'15%', marginLeft:'15%'}} >
            <h3 className={classes.textColor} ><center>{t('personalInformation')}</center></h3>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Alert severity="info" className={classes.info_message}>
                        <AlertTitle>{t('important_text')}</AlertTitle>
                        <Typography>
                            {t('profile_change_information_text')}
                        </Typography>
                    </Alert>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('gender')}</center></label>
                    <Paper className={classes.paper}>{props.gender}</Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('maritalStatus')}</center></label>
                    <Paper className={classes.paper}>{props.maritalStatus}</Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('occupation')}</center></label>
                    <Paper className={classes.paper}>{props.occupation}</Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('RFC')}</center></label>
                    <Paper className={classes.paper}>{props.rfc}</Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('curp')}</center></label>
                    <Paper className={classes.paper}>{props.curp}</Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('dateBirth')}</center></label>
                    <Paper className={classes.paper}>{props.dateBirth}</Paper>
                </Grid>
            </Grid>
            <br/>
            <h3 className={classes.textColor} ><center>{t('contact')}</center></h3>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('country')}</center></label>
                    <Paper className={classes.paper}>
                        <AutocompleteSelect options_data={countries.options} select_value={countries.value} on_change={handle_autocomplete_address_change} select_name="country" default_option={default_select_option} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('stateCity')}</center></label>
                    <Paper className={classes.paper}>
                        <AutocompleteSelect options_data={states.options} select_value={states.value} on_change={handle_autocomplete_address_change} select_name="region" default_option={default_select_option} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('city')}</center></label>
                    <Paper className={classes.paper}>
                        <AutocompleteSelect options_data={cities.options} select_value={cities.value} on_change={handle_autocomplete_address_change} select_name="city" default_option={default_select_option} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('street')}</center></label>
                    <Paper className={classes.paper}>
                        <CssTextField
                            value={user_address.street}
                            margin="normal"
                            type="text"
                            name="street"
                            onChange={handle_address_change}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('suburb')}</center></label>
                    <Paper className={classes.paper}>
                        <CssTextField
                            value={user_address.neighborhood}
                            margin="normal"
                            type="text"
                            name="neighborhood"
                            onChange={handle_address_change}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('numOutdoor')}</center></label>
                    <Paper className={classes.paper}>
                        <CssTextField
                            value={user_address.numberExt}
                            margin="normal"
                            type="text"
                            name="numberExt"
                            onChange={handle_address_change}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('numInterior')}</center></label>
                    <Paper className={classes.paper}>
                        <CssTextField
                            value={user_address.numberInt}
                            margin="normal"
                            type="text"
                            name="numberInt"
                            onChange={handle_address_change}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.textColor} ><center>{t('cp')}</center></label>
                    <Paper className={classes.paper}>
                        <CssTextField
                            value={user_address.cp}
                            margin="normal"
                            type="text"
                            name="cp"
                            onChange={handle_address_change}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        onClick={save_address}
                        style={{marginTop:30,background: '#ff7600', color: 'white',width:'45%' }}>
                        {t("saveBtn")}
                    </Button>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} sm={6}>
                    <CssTextField
                        value ={number}
                        onChange={handleOnChange}
                        margin="normal"
                        type="text"
                        label= {t('telephone')}
                        style={{width:'50%'}}
                    />
                    &nbsp;&nbsp;
                    <Button
                        onClick={addTelephone}
                        style={{marginTop:30,background: '#ff7600', color: 'white',width:'45%' }}>
                        +
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="center">{t('telephone')}</TableCell>
                                <TableCell align="center">{t('action')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                row.map((r, index)=>(
                                    <TableRow key={index}>
                                        <TableCell align="center">{r.number}</TableCell>
                                        <TableCell align="center">
                                        <Button onClick={()=>{
                                            deleteTelephone(r.number)
                                        }}>
                                            <DeleteIcon style={{color:'red'}} />
                                        </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
}

/**
 * Contact emergency
 */
function EmergencyContact(props){
    const classes = useStyles();
    const { t } = useTranslation();
    const [row,setRow]= useState([]);
    const [data, setData] = useState({
        name: '',
        lastName: '',
        telephone: '',
        email: '',
        relationship: ''
    });

    /**
     * On init component
     */
    useEffect(() => {
        fetchContacts();
    }, [])

    /**
     * Fetching data
     */
    const fetchContacts = () => {
        fetch(Constants.api + 'webApp/profile/getContacts', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data['data']!=='empty'){
                setRow(data);
            }
        })
        .catch(error => {});
    }

    /**
     * insert contacts
     * @param {Integer} number
     */
    const insertContacts = ()=>{
        fetch(Constants.api + 'webApp/profile/insertContacts', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data:{
                    idPerson: props.idPerson,
                    name: data.name,
                    lastName: data.lastName,
                    telephone: data.telephone,
                    email: data.email,
                    relationship: data.relationship
                }
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(d => {
            if(d.status === 1){
                setRow([
                    ...row,
                    { // filter by name and lastName
                        'name':data.name+" "+data.lastName,
                        'lastName': data.lastName,
                        'telephone': data.telephone,
                        'email': data.email,
                        'relationship': data.relationship,
                    }
                ]);
                //Clear data
                setData({
                    ...data,
                    name: '',
                    lastName: '',
                    telephone: '',
                    email: '',
                    relationship: ''
                });
            }else if(d.status === 0){
                swal('Error', t('profile_contact_duplicate_telephone_swal'), "warning");
            }else{
                swal('Error', t('showError'), "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    /**
     * delete contacts
     * @param {Integer} number
     */
    /*const deleteContacts = (name,lastName)=>{
        fetch(Constants.api + 'webApp/profile/deleteContacts', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data:{
                    idPerson: props.idPerson,
                    //the name calls with lastName concated so i just need the name
                    //Example: Juan Perez
                    //whit split the result is only Juan
                    name: name.split(' ')[0],
                    lastName: lastName,
                }
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            console.log('Deleted')
            setRow(row.filter(item=> item.name !== name));
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }*/

    /**
     * Event for change state
     * @param {object} event 
     */
    const handleOnChange = event => {
        const re =/^[0-9\b]+$/;
        const { name, value } = event.target
        switch(name){
            case 'telephone':
                if(value === '' || re.test(value)){
                    setData({
                        ...data, [name]: value
                    })
                }
                break;
            default:
                setData({
                    ...data, [name]: value
                })
                break;
        }
    }

    /**
     * Handle submit
     * @param {Object} evt 
     */ 
    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(data.name.length>150 || data.lastName.length>150 
            || data.email.length> 150 || data.relationship.length>150){
                swal("Error", t('validationLength'), "error");
        }
        else if(data.telephone.length > 10){
                swal("Error", t('errorTelephone'), "error");
        }else{
            insertContacts();
        }
    }

    return(
        <div style={{background: 'transparent', marginRight:'15%', marginLeft:'15%'}} >
            <h3 className={classes.textColor}><center>{t('emergencyContact')}</center></h3><br/>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <CssTextField
                                value ={data.name}
                                name= "name"
                                onChange={handleOnChange}
                                margin="normal"
                                type="text"
                                label= {t('name')}
                                fullWidth={true}
                                required
                                //style={{width:'50%'}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CssTextField
                                value = {data.lastName}
                                name = "lastName"
                                onChange = {handleOnChange}
                                margin="normal"
                                type="text"
                                label= {t('lastName')}
                                fullWidth={true}
                                required
                                //style={{width:'50%'}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CssTextField
                                value = {data.telephone}
                                name = "telephone"
                                onChange = {handleOnChange}
                                margin="normal"
                                type="text"
                                label= {t('telephone')}
                                fullWidth={true}
                                required
                                //style={{width:'50%'}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CssTextField
                                value = {data.email}
                                name = "email"
                                onChange = {handleOnChange}
                                margin="normal"
                                type="email"
                                label= {t('email')}
                                fullWidth={true}
                                required
                                //style={{width:'50%'}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CssTextField
                                value = {data.relationship}
                                name = "relationship"
                                onChange = {handleOnChange}
                                margin="normal"
                                type="text"
                                label= {t('relationship')}
                                fullWidth={true}
                                required
                                //style={{width:'50%'}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                variant="contained"
                                color="primary"
                                type='submit'
                                value='Submit'
                                style={{ background: '#ff7600', color: 'white', width:'100%' }}
                                size='large'
                            >
                                {t('add')}
                            </Button>
                        </Grid>
                    <Grid item xs={12}>
                    <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t('name')+' '+t('lastName')}</TableCell>
                                    <TableCell align="center">{t('telephone')}</TableCell>
                                    <TableCell align="center">{t('email')}</TableCell>
                                    <TableCell align="center">{t('relationship')}</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        row.map((r, index)=>(
                                            <TableRow key={index}>
                                                <TableCell align="center">{r.name}</TableCell>
                                                <TableCell align="center">{r.telephone}</TableCell>
                                                <TableCell align="center">{r.email}</TableCell>
                                                <TableCell align="center">{r.relationship}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

/**
 * MFA Section
 */
function Mfa(props){
    const classes = useStyles();
    const { t } = useTranslation();
    const [mfa_code, set_mfa_code] = useState('');
    const [mfa_data, set_mfa_data] = useState({
        verified: 0,
        secure: 0,
        factor: ''
    });
    const [mfa_show_qr, set_mfa_show_qr] = useState({
        show: false,
        qr: ''
    });

    /**
     * On init component
     */
     useEffect(() => {
        fetch_mfa_data();
    }, [])

    /**
     * Event for change state
     * @param {object} event 
     */
    const handle_code_change = event => {
        set_mfa_code(event.target.value);
    }

    const fetch_mfa_data = () => {
        fetch(Constants.api + 'getMfaData', {
            method: 'GET',
            credentials: 'include'
        })
        .then((response) => response.json())
        .then(data => {
            if(data.status === "success"){
                set_mfa_data(data.data);
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    const create_mfa_qr = () => {
        fetch(Constants.api + 'generateFactor', {
            method: 'POST',
            credentials: 'include'
        })
        .then((response) => response.json())
        .then(data => {
            if(data.status === "success"){
                set_mfa_show_qr({
                    show: true,
                    qr: data.data
                });
            }else{
                swal("Error",
                showError("Error al generar QR", t) ,
                "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    const send_mfa_code_verification = () => {
        fetch(Constants.api + 'verifyFactor', {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: mfa_code
            })
        })
        .then((response) => response.json())
        .then(data => {
            if(data.status === "success" && data.data === true){
                set_mfa_data({...mfa_data, verified: 1});
                set_mfa_show_qr({show: false, qr: ''});
            }else{
                swal("Error",
                showError("Error al verificar el código", t) ,
                "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    const delete_mfa = () => {
        fetch(Constants.api + 'deleteFactors', {
            method: 'DELETE',
            credentials: 'include'
        })
        .then((response) => response.json())
        .then(data => {
            if(data.status === "success" && data.data === true){
                set_mfa_data({...mfa_data, verified: 0});
                swal(t('successText'), t('register_deleted_success'), "success");
            }else{
                swal("Error",
                showError("Error al intentar borrar configuración", t) ,
                "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    return(
        <div style={{background: 'transparent', marginRight:'15%', marginLeft:'15%'}}>
           <h2 style={{color:'#ff7600'}}><center>{t('profile_mfa_tab')}</center></h2>
            <center>
                {!mfa_data.verified && !mfa_show_qr.show?
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '10px auto 0px', background: '#ff7600', color: 'white' }}
                        size='large'
                        onClick={create_mfa_qr}
                    >
                    {t('profile_mfa_create_btn_text')}
                    </Button>
                : null}
                {mfa_show_qr.show?
                <div>
                    <div style={{ width: '100%' }}>
                        <Grid container spacing={1} alignItems="flex-end" style={{ width: '100%' }}>
                            <img src={mfa_show_qr.qr} alt={"QR"} style={{margin: '30px auto 5px'}} />
                        </Grid>
                    </div>
                    <div style={{ width: '100%' }}>
                        <Grid container spacing={1} alignItems="flex-end" style={{ width: '80%' }}>
                            <Grid item xs={1}>
                                <KeyIcon className={classes.textColor} />
                            </Grid>
                            <Grid item xs={10}>
                                <CssTextField
                                    value={mfa_code}
                                    onChange={handle_code_change}
                                    name="repeatPassword"
                                    label={t('profile_mfa_verify_text')}
                                    margin="normal"
                                    type='text'
                                    fullWidth={true}
                                    required
                                />
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ margin: '10px auto 0px', background: '#ff7600', color: 'white' }}
                                size='large'
                                onClick={send_mfa_code_verification}
                            >
                                {t('profile_mfa_verify_btn_text')}
                            </Button>
                        </Grid>
                    </div>
                </div> : null}
                {mfa_data.verified?
                <div>
                    <div style={{ width: '100%', marginTop: '10px' }}>
                        <Alert severity="info" style={{ marginBottom: 20 }}>
                            <AlertTitle><b>{t('profile_mfa_success_alert_title_text')}</b></AlertTitle>
                            <Typography>
                                {t('profile_mfa_success_alert_text')}
                            </Typography>
                        </Alert>
                    </div>
                    <div style={{ width: '100%' }}>
                        <Grid container spacing={1} alignItems="flex-end" style={{ width: '80%' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: '10px auto 0px' }}
                                size='large'
                                onClick={delete_mfa}
                            >
                                {t('profile_mfa_delete_btn_text')}
                            </Button>
                        </Grid>
                    </div>
                </div> : null}
            </center>
        </div>
    );
}

function ProfileScreen(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    /**
    * Create theme
    */
    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFF'
            }
        }
    });

    /**
     * New value for navbar
     * @param {object} event 
     * @param {int} newValue 
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" className= {classes.appBar} >
                    <Tabs indicatorColor="secondary" value={value} onChange={handleChange} style={{ margin: 'auto' }} >
                        <Tab icon={<PersonIcon />}  {...a11yProps(0)} />
                        <Tab icon={<LockIcon />} {...a11yProps(1)} />
                        <Tab icon={<EmailIcon />} {...a11yProps(2)} />
                        <Tab icon={<InfoIcon />} {...a11yProps(3)} />
                        <Tab icon={<ContactIcon />} {...a11yProps(4)} />
                        <Tab icon={<PolicyIcon />} {...a11yProps(5)} />
                        <Tab style={{ display: 'none' }} icon={<VerifiedUserIcon />} {...a11yProps(6)} />
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <TabPanel value={value} index={0} style={{ height:100, padding: 0}}>
                <ProfileMainview 
                    name = {props.state.name}
                    career = {props.state.career}
                    campus = {props.state.campus}
                    picture = {props.state.picture}
                />
            </TabPanel>
            <TabPanel value={value} index={1} style={{height: 0}}>
                <ProfileChangePassword 
                    repeatPassword ={props.state.repeatPassword}
                    handleOnChange ={props.handleOnChange}
                    validate = {props.state.validate}
                    newPassword = {props.state.newPassword}
                    handleSubmit ={props.handleSubmit}
                    currentPassword = {props.state.currentPassword}
                />
            </TabPanel>
            <TabPanel value={value} index={2} style={{height: 0}}>
                <ProfileChangeEmail 
                    schoolEmail = {props.state.schoolEmail}
                    ownEmail = {props.state.ownEmail}
                />
            </TabPanel>
            <TabPanel value={value} index={3} style={{height: 0}}>
                <Info 
                    gender = {props.state.gender}
                    maritalStatus = {props.state.maritalStatus}
                    occupation = {props.state.occupation}
                    rfc = {props.state.rfc}
                    curp = {props.state.curp}
                    dateBirth = {props.state.dateBirth}
                    country= {props.state.country}
                    region = {props.state.region}
                    city= {props.state.city}
                    street= {props.state.street}
                    neighborhood = {props.state.neighborhood}
                    numberExt = {props.state.numberExt}
                    numberInt = {props.state.numberInt}
                    zip = {props.state.zip}
                    telephone= {props.state.telephone}
                    idPerson = {props.state.idPerson}
                    countries_select = {props.state.countries_select}
                    regions_select = {props.state.regions_select}
                    cities_select = {props.state.cities_select}
                />
            </TabPanel>
            <TabPanel value={value} index={4} style={{height:0}}>
                <EmergencyContact 
                    idPerson = {props.state.idPerson}
                />
            </TabPanel>
            <TabPanel value={value} index={5} style={{height:0}}>
                <PrivacyPolicy />
            </TabPanel>
            <TabPanel value={value} index={6} style={{height:0}}>
                <Mfa data={props.mfa} />
            </TabPanel>
        </div>
    )
}

/**
 * Build the component Profile using hooks
 * @param {object} props 
 */
export function Profile(props) {
    //const for show the screen depending of the state
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({
        flag: false,
        status : '' 
    });
    const [state, setState] = useState({
        idPerson: '',
        picture: '',
        name: '',
        career: '',
        campus: '',
        schoolEmail: '',
        ownEmail: '',
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
        gender: '',
        maritalStatus: '',
        occupation: '',
        rfc: '',
        curp: '',
        dateBirth: '',
        country: '',
        region: '',
        city: '',
        street: '',
        neighborhood: '',
        numberExt: '',
        numberInt: '',
        zip: '',
        telephone: '',
        validate: false,
    });

    /**
     * Fetch data using the API
     */
    const fetchData = () => {

        fetch(Constants.api + 'webApp/profile', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            let charge;
            switch(data.response.profile['userType']){
                case 2:
                    charge = t('candidate');
                break;

                case 3:
                    charge = t('teacher');
                break;
            
                case 1: case 36:
                    charge = data.response.profile['career'];
                break;
                case 34:
                    charge = t("attendee_profile_name");
                break;
                
                default:
                    charge = 'Admin';
            }
            setState({
                ...state,
                idPerson: data.response.profile['idPerson'],
                name: data.response.profile['name'],
                campus: data.response.profile['campus'],
                picture: Constants.base_site + 'public/img/profile/' + data.response.profile['picture'],
                career: charge,
                schoolEmail: data.response.profile['schoolEmail'],
                ownEmail: data.response.profile['ownEmail'],
                gender: (data.response.profile['gender'] === 'M')?'Masculino':'Femenino',
                maritalStatus: data.response.profile['maritalStatus'],
                occupation: (data.response.profile['occupation'] === ' ')?t('noData'):data.response.profile['occupation'],
                rfc: (data.response.profile['rfc'] === '')?t('noData'):data.response.profile['rfc'],
                curp: data.response.profile['curp'],
                dateBirth: data.response.profile['dateBirth'],
                country: {value: data.response.profile['country_id'], label: data.response.profile['country']},
                region: {value: data.response.profile['region_id'], label: data.response.profile['region']},
                city: {value: data.response.profile['city_id'], label: data.response.profile['city']},
                street: data.response.profile['street'],
                neighborhood: data.response.profile['neighborhood'],
                numberExt: data.response.profile['numberExt'],
                numberInt: data.response.profile['numberInt'],
                zip: data.response.profile['zip'],
                telephone: data.response.profile['telephone'],
                countries_select: data.response['countries'],
                regions_select: data.response['regions'],
                cities_select: data.response['cities']
            });
            setLoading(false);
        })
        .catch(error => {
            setError({
                ...error,
                flag: true,
                status : error
            });
        });
    }

    /**
     * Change password API
     */
    const changePassword = ()=>{
        fetch(Constants.api + 'webApp/changePassword', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                actualPassword: state.currentPassword,
                newPassword: state.newPassword
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data['affectedRows'] > 0){
                swal(t('modalSuccessTitle'), t('successPasswordChange'), "success");
            }else{
                swal('ERROR', t('errorCurrentPasswordWrong'), "error");
            }
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    /**
     * Init state, using eslint disable for ignore the warning
     */
    useEffect(() => {
        fetchData();
    // eslint-disable-next-line 
    },[])

    /**
     * Event for change state
     * @param {object} event 
     */
    const handleOnChange = event => {
        const { name, value } = event.target
        setState({
            ...state, [name]: value
        })
    }

    /**
     * Submit for the form
     * @param {object} evt 
     */
    const handleSubmit = (evt) => {
        evt.preventDefault();
        //if the text field is less than 6 show the message
        if (state.newPassword.length < 6) {
            setState({ ...state, validate: true })
        } else if(state.newPassword !== state.repeatPassword){
            swal("Error", t('errorBothPasswords'), "error");
        }else {
            changePassword();
            setState({
                ...state,
                currentPassword: '',
                newPassword: '',
                repeatPassword: '',
                validate: false,
            })
        }
    }

    return (
            (error.flag) ? <ErrorOnLoading type={error.status}/> :
            (loading) ? <Loading /> :
            <ProfileScreen
                handleOnChange={handleOnChange}
                handleSubmit={handleSubmit}
                state={state}
            />
            
    );
}
