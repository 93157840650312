import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import swal from '@sweetalert/with-react';
import {showError} from '../utilities/errorOnFetch';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import 'moment/min/locales';
import ReactPlayer from 'react-player/youtube';

moment.tz.setDefault(Constants.timeZone);

/**
 * Style css
 */
const useStyles = makeStyles(theme => ({
    text: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        marginTop: 190,
        fontSize: 20,
        color: '#37474f'
    },
    tabTitle: {
        color: 'black',
        fontWeight: "bold"
    },
    tabTitleText: {
        color: 'black',
        fontWeight: 600
    }
}));

/**
 * Styles for textfield
 */
const CssTextField = withStyles({
    root: {
        backgroundColor: 'transparent',
        '& label.Mui-focused': {
            color: 'rgb(var(--complementary))',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(var(--primary-text-color))',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(var(--primary-text-color))',
                backgroundColor: 'transparent',
            },
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid rgb(var(--primary-text-color))',
            backgroundColor: 'transparent',
        },
        '& .MuiInputBase-root': {
            color: 'rgb(var(--primary-text-color))',
            backgroundColor: 'transparent',
        }
    },
})(TextField);

/**
 * RestrictDiscord
 */
function RestrictDiscord({data}) {
    const { t } = useTranslation();
    moment.locale(t('discordRegisterDate'));

    const BanStatus = ({data}) =>{
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                <Alert severity="error" >
                    <AlertTitle>{t('ban_title')}</AlertTitle>
                    <Typography>
                        {t('discordRegisterAlertUser')}<strong>{data.user}</strong>
                        {t('discordRegisterAlertDate')}<strong>{moment(data.date).format("LLLL")}</strong>
                        <br />
                        {t('ban_description')} <br />
                    </Typography>
                </Alert>
            </div>
        )
    }

    /**
     * View success register
     * @param {Object} data
     */
    const SuccessStatus = ({data}) =>{
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                <Alert severity="success" >
                    <AlertTitle>{t('discordRegisterAlertTitle')}</AlertTitle>
                    <Typography>
                        {t('discordRegisterAlertUser')}<strong>{data.user}</strong>
                        {t('discordRegisterAlertDate')}<strong>{moment(data.date).format("LLLL")}</strong>
                        <br />
                        {t('discordRegisterAlertWelcome')} <br />
                    </Typography>
                </Alert>
            </div>
        )
    }

    return (
        (data.status === "banned")? <BanStatus data = {data} /> : <SuccessStatus data = {data} />
    );
}

/**
 * Discord view screen
 * @param {Object} props 
 */
function DiscordViewScreen() {
    const { t } = useTranslation();
    const [user, setUser] = useState('');

    /**
     * fetch user
     */
    const fetchUser= () =>{
        fetch(Constants.api + 'webApp/discord/register', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: user.trim()
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            // if found username
            // eslint-disable-next-line
            if (data.status == 'found'){
                swal("Error", t('validationDiscord') ,"error");
            }else{
                window.location.assign(data.link);
                //redirect
            }
            console.log(data)
        })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    }

    /**
     * Handle submit form
     */
    const handleSubmit = (evt) =>{
        evt.preventDefault();
        if (user.includes('#')){
            swal("Error",t('errorUser'),"error");
            setUser(''); // clear textfield
        }else{
            fetchUser()
        }
        //alert(user)
        //window.location.assign("http://exampleapp.com/somepage/");
    }

    /**
     * handle change input
     * @param {Object} evt 
     */
    const handleOnChange = (evt) =>{
        const {value} = evt.target
        setUser(value)
    }

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <div style={{ marginRight: '10%', marginLeft: '10%', marginTop: 70, background: 'rgb(var(--secondary-color))' }}>
                <Alert severity="warning">
                    <AlertTitle>{t('discordTabRegisterAlertRegister')}</AlertTitle>
                    <Typography>
                        {t('discordTabRegisterPendingAccountStart')}
                        <Link href={t('discordTabRegisterPendingAccountUrl')} target="_blank" rel="noreferrer">
                            {t('discordTabRegisterPendingAccountUrl')}
                        </Link>
                        {t('discordTabRegisterPendingAccountFinish')}
                        <br/>
                        {t('hoverDiscord')}
                    </Typography>
                </Alert>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit} style={{ background: 'rgb(var(--secondary-color))' }}>
                <div style={{ marginRight: '30%', marginLeft: '30%', background: 'rgb(var(--secondary-color))' }}>
                    <CssTextField
                        value = {user}
                        name="user"
                        onChange = {handleOnChange}
                        margin="normal"
                        type="text"
                        label={t('userDiscord')}
                        fullWidth={true}
                        required
                    />
                </div>
                <Button
                    variant="contained"
                    type='submit'
                    color="primary"
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '5%',
                        background: '#ff7600',
                        color: 'white'
                    }}
                    size='large'
                    //onClick={() => { window.location.assign('http://github.com') }}
                >
                    {t('registerDiscord')}
                </Button>
            </form>
            <div style = {{ marginRight: '10%', marginLeft: '10%', marginTop: 70, background: 'rgb(var(--secondary-color))' }} >
                <DiscordStatusVideo link = {'https://youtu.be/vDDw9CYxAQY'} />
            </div>
        </div>
    );
}

const DiscordStatusVideo = (prop) => {
    return (
        <ReactPlayer url={prop.link}
        controls
        width='100%'
        height='500px'
        />
    );
}

const DiscordStatus = ({data}) => {
    const { t } = useTranslation();
    let type = "";
    let txt = "";
    let severity = "";
    let showVideo = false;
    if(data !== null){
        /*data.status.description = "Partial System Outage";
        data.status.indicator = "major";*/
        switch (data.status.description) {
            case "All Systems Operational":
                type = "success";
                txt = t('discordStatusSuccess');
            break;
            case "Partial System Outage":
                type = "warning";
                txt = t('discordStatusWarning');
            break;
            case "Partially Degraded Service":
                type = "warning";
                txt = t('discordStatusWarning');
            break;
            case "Major Service Outage":
                type = "error";
                txt = t('discordStatusError');
            break;
            default:
                type = "info";
                txt = data.status.description;
            break;
        }
    
        switch (data.status.indicator) {
            case "minor":
                severity = t('discordStatusSeverityLow');    
            break;
            case "major":
                severity = t('discordStatusSeverityHigh');    
            break;
            case "critical":
                severity = t('discordStatusSeverityCritical');    
            break;
            default:
                severity = "";
            break;
        }
    }
    if(severity !== "" || (type !== "success" && type !== "info")){
        showVideo = true;
    }
    return (
        data == null? <Loading />:
        <div>
            <Alert severity={type} >
                <Typography>
                    <strong>{t('discordStatusAlertTitle')}: </strong>{txt} <br />
                    {
                    severity!==""?
                    <span><b> {t('discordStatusSeverity')}: </b>
                        {severity}
                    </span>
                    :""
                    }
                </Typography>
            </Alert>
            <Container style={{marginTop: 20}}>
                {!showVideo?null: <DiscordStatusVideo link = {'https://www.youtube.com/watch?v=5mQGT0dnIMw'} />}
            </Container>
        </div>
    );
}

function DiscordTabs({data}){
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [status, setStatus] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        if(newValue === 1){
            getDiscordStatus();
        }
        setValue(newValue);
    };
    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFFFFF'
            }
        }
    });

    const getDiscordStatus = () => {
        fetch(Constants.discordStatus, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            setStatus(data);
        })
        .catch(error => {
            console.log(error);
        });
    }
    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" style={{ background: '#ff7600' }}>
                    <Tabs
                    value={value}
                    onChange={handleTabChange}
                    variant="scrollable"
                    /* scrollButtons="on" */
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable auto tabs"
                    className={classes.tabTitle}
                    >
                        <Tab key={`tab1`} label = {t('discordTabRegister')} className={classes.tabTitleText} />
                        <Tab key={`tab2`} label = {t('discordTabStatus')} className={classes.tabTitleText} />
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <Container style={{marginTop: 20}}>
                <div key={`divClass_register`} style={value !== 0?{display:"none"}:{}} index={0} >
                    { (data.restrict) ? <RestrictDiscord data={data.discord_info} /> : <DiscordViewScreen /> }
                </div>
                <div key={`divClass_status`} style={value !== 1?{display:"none"}:{}} index={1} >
                    <DiscordStatus data={status} />
                </div>
            </Container>
        </div>
    );
}

export function DiscordView() {
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState()
    const [error, setError] = useState({
        flag: false,
        status: ''
    });

    // Check user
    const checkUser = () => {
        fetch(Constants.api + 'webApp/user/checkDiscord', {
            method: 'GET',
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
            .then(data => {
                setState(data)
                setLoading(false)
            })
            .catch(error => {
                setError({
                    ...error,
                    flag: true,
                    status: error
                });
                //swal("Error", t('informationError'), "error");
            })
    }

    useEffect(() => {
        checkUser()
        // eslint-disable-next-line
    }, [])

    return (
        (error.flag) ? <ErrorOnLoading type={error.status} /> :
            (loading) ? <Loading /> :
                <DiscordTabs data={state} />
    )
}
