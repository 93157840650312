import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

/**
 * Error on loading 
 */
export default function ErrorOnLoading(props){
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        text: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: 190,
            fontSize: 20,
            color: '#37474f'
        }
    }));
    const classes = useStyles();

    const errorMsg = (type)=>{
        console.log(type)
        switch(type){
            case 401:
                return t('sessionExpired')
            case 404:
                return t('dataFound')
            default: //500
                return 'Error : '+ t('requestError')
        }
    }

    return (
        <p className={classes.text} >
            {errorMsg(props.type)}
            <br/>
            <button style={{
                fontSize: 15,
                backgroundColor: '#ff7600',
                color: 'white',
                border: 'none',
                borderRadius: 5,
                padding: 5,
                cursor: 'pointer'
            }}
            onClick={()=>{window.location.reload()}}>{t('retry')}</button> 
        </p>
    );
}