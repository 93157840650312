import React from 'react';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import NoInfo from '../utilities/NoInfo';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import TeacherEvaluationView from './TeacherEvaluationView';
import EvaluationView from './EvaluationView';

const PendingEvaluationTabs = ({ data, refresh, evaluateTeacher, evaluate }) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const useStyles = makeStyles(theme => ({
        tabTitle: {
            color: 'black',
            fontWeight: "bold"
        },
        tabTitleText: {
            color: 'black',
            fontWeight: 600
        }
    }));
    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFFFFF'
            }
        }
    });

    let tabNumber = -1;

    const tabControl = () => {
        tabNumber++;
        return tabNumber;
    };

    return (
        <div style={{ background: 'rgb(var(--secondary-text))' }}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" style={{ background: '#ff7600' }}>
                    <Tabs
                    value={value}
                    onChange={handleTabChange}
                    variant="scrollable"
                    /* scrollButtons="on" */
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable auto tabs"
                    className={classes.tabTitle}
                    >
                        {(data.teachers_evaluation.length > 0)?<Tab key={`tab_teachers_evaluation`} label = {`${t('teacherEvaluationTitle')}`} className={classes.tabTitleText} />:null }
                        {(data.promoter_evaluation.length > 0)?<Tab key={`tab_promoter_evaluation`} label = {`${t('promoterEvaluationTitle')}`} className={classes.tabTitleText} />:null }
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <Container style={{marginTop: 20, background: 'rgb(var(--secondary-text))'}}>
                {
                    (data.teachers_evaluation.length > 0)?(
                        <div key={`divTeacherEvaluation`} style={{background: 'rgb(var(--secondary-text))', ...value !== tabControl() ? { display:"none", } : {} }} >
                            <Grid key={`gridTeachersTabContent`} container spacing={3} direction="row" justify="center" >
                        {
                            data.teachers_evaluation.map((evaluation, i) => {
                                return (
                                    <Grid item key={`gridTeacherCardContent${i}`} index={i} xs={12} sm={6} md={4} lg={4} style={{ background: 'rgb(var(--secondary-text))' }} >
                                        <PendingTeacherEvaluationCards key={`teachers${i}`} data={evaluation} evaluate={evaluateTeacher} />
                                    </Grid>
                                );
                            })
                        }
                            </Grid>
                        </div>
                    ):null
                }
                {
                    (data.promoter_evaluation.length > 0)?(
                        <div key={`divPromoterEvaluation`} style={{background: 'rgb(var(--secondary-text))', ...value !== tabControl()?{display:"none"}:{} }} >
                            <Grid key={`gridPromoterTabContent`} container spacing={3} direction="row" justify="center" >
                        {
                            data.promoter_evaluation.map((evaluation, i) => {
                                return (
                                    <PendingPromoterEvaluationCards key={`promoters${i}`} data={evaluation} evaluate={evaluate} />
                                );
                            })
                        }
                            </Grid>
                        </div>
                    ):null
                }
            </Container>
        </div>
    );
}

const PendingTeacherEvaluationCards = ({ data, evaluate }) => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        text: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: 190,
            fontSize: 20,
            color: '#37474f'
        },
        large: {
            background: 'rgba(var(--primary-text-color), 0.4)',
            width: 90,
            height: 90,
        },
    }));
    const classes = useStyles();

    return (
        <Card className={classes.root} style={{ background: 'rgb(var(--secondary-text))' }}>
            <CardContent className={classes.content} style={{ background: 'rgb(var(--secondary-text))' }}>
                <Grid key={`gridCard${data.idClass}`} container spacing={3} direction="row" justify="center" style={{ background: 'rgb(var(--secondary-text))' }}>
                    <Grid item xs={4} sm={5} md={4} lg={4} container alignContent="center" style={{ background: 'rgb(var(--secondary-text))' }}>
                        <Avatar className={classes.large}
                            src={Constants.base_site + 'public/img/profile/thumbnails/' + data.picture}
                        />
                    </Grid>
                    <Grid item xs={8} sm={7} md={8} lg={8}  style={{ background: 'rgb(var(--primary-text))' }}>
                        <Typography component="h5" variant="h5">
                            {`${data.teacherName} ${data.lastName}`}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {data.nameSubject}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {data.periodName}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
                <Button fullWidth={true} color="primary" variant="contained" onClick={() => evaluate(data)} >
                    {t('teacherEvaluationBtn')}
                </Button>
        </Card>
    );
}

const PendingPromoterEvaluationCards = ({ data, evaluate }) => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        text: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: 190,
            fontSize: 20,
            color: '#37474f'
        },
        large: {
            width: 90,
            height: 90,
        },
    }));
    const classes = useStyles();

    return (
        <Card className={classes.root} style={{ background: 'rgb(var(--primary-color))' }}>
            <CardContent className={classes.content} style={{ background: 'rgb(var(--primary-color))' }}>
                <Typography component="h5" variant="h5" align="center" >
                    {`${data.name}`}
                </Typography>
                <Typography variant="subtitle1" align="center" >
                    {data.periodName}
                </Typography>
            </CardContent>
            <CardActions>
                <Button fullWidth={true} color="primary" variant="contained" onClick={() => evaluate(data)} >
                    {t('teacherEvaluationBtn')}
                </Button>
            </CardActions>
        </Card>
    );
}

class PendingEvaluationView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            error: false,
            loading: true,
            status: '',
            pendingEvaluations: [],
            haveData: false,
            evaluatingTeacher: false,
            teacherQuestions: [],
            evaluationQuestions: [],
            evaluating: false
        }
    }

    getPendingEvaluations = () => {
        this.setState({...this.state, error: false, loading: true, pendingEvaluations: [], haveData: false, evaluatingTeacher:false, evaluating: false});
        fetch(Constants.api + 'webApp/student/getPendingEvaluations', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state, loading: false});
            if(json.teachers_evaluation.length > 0 || json.promoter_evaluation.length > 0){
                this.setState({
                    ...this.state,
                    pendingEvaluations: json,
                    haveData: true
                });
            }else{
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    setEvaluation = (type, visible) => {
        this.setState({...this.state, evaluatingTeacher: visible });
    }

    setLoading = (status) => {
        this.setState({...this.state, loading: status });
    }

    getTeacherEvaluation = (teacherData) => {
        this.setState({...this.state, error: false, loading: true});
        fetch(Constants.api + 'webApp/student/getTeacherEvaluationQuestions', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state, loading: false});
            if(json.questions.length > 0){
                this.setState({
                    ...this.state,
                    teacherQuestionsData: {teacher: teacherData, questions: json.questions},
                    evaluatingTeacher: true
                });
            }else{
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    getEvaluation = (evaluationData) => {
        this.setState({...this.state, error: false, loading: true});
        fetch(Constants.api + 'webApp/student/getEvaluationQuestions', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                idEvaluationRegister: evaluationData.idEvaluationRegister
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state, loading: false});
            if(json.questions.length > 0){
                this.setState({
                    ...this.state,
                    evaluationQuestions: {evaluation: evaluationData, questions: json.questions},
                    evaluating: true
                });
            }else{
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    componentDidMount() {
        this.getPendingEvaluations();
    }

    render(){
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                {
                    (this.state.loading) ? <Loading />:
                    (this.state.error) ? <ErrorOnLoading type={this.state.status} />:
                    (this.state.haveData) ? (this.state.evaluatingTeacher) ? 
                        <TeacherEvaluationView data={this.state.teacherQuestionsData} refresh={this.getPendingEvaluations} />:
                    (this.state.evaluating) ? 
                        <EvaluationView data={this.state.evaluationQuestions} refresh={this.getPendingEvaluations} />:(
                        <PendingEvaluationTabs data={this.state.pendingEvaluations} refresh={this.getPendingEvaluations} evaluateTeacher={this.getTeacherEvaluation} evaluate={this.getEvaluation} />
                    ):<NoInfo />
                }
            </div>
        );
    }
}

export {PendingEvaluationView}