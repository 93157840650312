import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import NoInfo from '../utilities/NoInfo';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/**
 * Tabs group build
 * @param {map} param0 
 */
const GroupTabs = ({data, refreshFunction, tab}) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(tab);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [state,setState] = React.useState({
        idClass: 0,
        title: '',
        url: '',
        idClassDrive: 0
    });

    const weekDaysTranslate = {
        "Mon": t('monday'),
        "Tue": t('tuesday'),
        "Wed": t('wednesday'),
        "Thu": t('thursday'),
        "Fri": t('friday'),
        "Sat": t('saturday')
    };

    /**
     * Changue tab
     * @param {object} event 
     * @param {int} newValue 
     */
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    /**
     * colors for tabs
     */
    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFF'
            }
        }
    });

    /**
     * Styles for view 
     */
    const useStyles = makeStyles(theme => ({
        addDriveCard: {
            backgroundColor: "#43a047",
            color: "white",
            "&:hover": {
                backgroundColor: "#43a047bd"
            },
        },
        modalDeleteBtn: {
            backgroundColor: "#e53935",
            color: "white",
            "&:hover": {
                backgroundColor: "#e53935bd"
            },
        },
        tabTitle: {
            color: 'black',
            fontWeight: "bold"
        },
        tabTitleText: {
            color: 'black',
            fontWeight: 500
        }
    }));

    const classes = useStyles();

    /**
     * Fetch for class drive
     */
    const addDriveCard = () => {
        if(state.title !== "" && state.url !== ""){
            handleCloseAddModal();
            fetch(Constants.api + 'webApp/teacher/addClassDrive', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    title: state.title,
                    driveUrl: state.url,
                    idClass: state.idClass
                })
            })
            .then(response => {
                if(response.status === 200 || response.ok === true){
                    swal(t('successText'), t('driveSuccess'), "success");
                    refreshFunction(value);
                }else{
                    swal('Error', t('messageError'), "error");
                }
                return response.json()
            })
            .then(json => {
            }).catch(error => {
                swal('Error', error, "error");
            });
        }else{
            swal(t('warningText'), t('driveValidation'), "warning");
        }
    }

    /**
     * Delete drive card
     */
    const deleteDriveCard = () => {
        fetch(Constants.api + 'webApp/teacher/deleteClassDrive', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                idClassDrive: state.idClassDrive,
            })
        })
        .then(response => {
            if(response.status === 200 || response.ok === true){
                swal(t('successText'), t('driveDelete'), "success");
                refreshFunction(value);
            }else{
                swal('Error', t('messageError'), "error");
            }
            return response.json()
        })
        .then(json => {
        }).catch(error => {
            swal('Error', error, "error");
        });
    }

    /**
     * Set state card model
     * @param {int} idClass 
     */
    const openAddCardModal = (idClass) => {
        setState({
            idClass: idClass,
            title: '',
            url: '',
        });
        setOpenAddModal(true);
    }

    /**
     * Delete card modal
     * @param {int} idClassDrive 
     */
    const openDeleteCardModal = (idClassDrive) => {
        setState({
            ...state,idClassDrive: idClassDrive
        });
        setOpenDeleteModal(true);
    }

    /**
     * Status open modal
     */
    const handleCloseAddModal = () => {
        setOpenAddModal(false);
    };

    /**
     * Delete modal
     */
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }; 

    /**
     * Set state 
     * @param {object} event 
     */
    const handleOnChange = (event) => {
        setState({
            ...state,[event.target.name]: event.target.value.trim()
        });
    }

    return (
        <div>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" style={{ background: '#ff7600' }}>
                    <Tabs
                    value={value}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    /* scrollButtons="on" */
                    aria-label="scrollable auto tabs"
                    className={classes.tabTitle}
                    >
                        {
                            data.map((classTitle, i) => {
                                return(
                                    <Tab key={`tab${i+1}`} label = {`${classTitle.title}`} className={classes.tabTitleText} />
                                );
                            })
                        }
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <Container style={{marginTop: 20}}>
                {
                    data.map((actualClass, i) => {
                        return(
                            <div key={`divClass${i}`} style={value !== i?{display:"none"}:{}}>
                                <Grid key={`gridClassTabContent${i}`} container spacing={3} direction="row" justify="center" >
                                    <Grid item key={`gridClassTabScheduleContent${i}`} index={i} xs={12} sm={12} md={6} lg={6} >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        actualClass.schedule.map((day,dayIndex) => {
                                                            return (
                                                                <TableCell key={`scheduleDayTitle${i}${dayIndex}`} align="center">{weekDaysTranslate[day.day]}</TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    <TableRow>
                                                        {
                                                            actualClass.schedule.map((day,dayIndex) => {
                                                                return (
                                                                    <TableCell key={`scheduleDayInfo${i}${dayIndex}`} align="center">{day.dayHours}</TableCell>
                                                                );
                                                            })
                                                        }
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                                <Grid key={`gridClassTabButton${i}`} container spacing={3} direction="row" justify="center" >
                                    <Grid item key={`gridClassTabButtonContent${i}`} index={i} xs={12} sm={7} md={4} lg={4}>
                                        <Button fullWidth={true} className={classes.addDriveCard} variant="contained" onClick={() => openAddCardModal(actualClass.idClass)}>
                                            {t('driveAdd')}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid key={`gridClassTab${i}`} container spacing={3} direction="row" justify="center">
                                    {
                                        actualClass.driveShared.map((drive, index) => {
                                            drive.subject = actualClass.title;
                                            return(
                                                <Grid item key={`gridClassTabContent${i}${index}`} index={i} xs={12} sm={7} md={4} lg={4}>
                                                    <ClassDriveCard key={`balanceCard${index+1}`} link={drive} deleteFunction={openDeleteCardModal} />
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </div>
                        );
                    })
                }
                <Dialog open={openAddModal} onClose={handleCloseAddModal} aria-labelledby="form-dialog-title" maxWidth="md" disableBackdropClick={true}>
                    <DialogTitle id="form-dialog-title">{t('driveAddModalTitle')}</DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            margin="dense"
                            id="driveTitle"
                            label={t('driveModalTitle')}
                            type="text"
                            name="title"
                            value={state.title}
                            onChange={handleOnChange}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="driveUrl"
                            label={t('driveModalLink')}
                            type="text"
                            name="url"
                            value={state.url}
                            onChange={handleOnChange}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={addDriveCard} color="primary">
                            {t('driveModalAddBtn')}
                        </Button>
                        <Button onClick={handleCloseAddModal} color="default">
                            {t('driveModalCloseBtn')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal} aria-labelledby="confirm-dialog-title" maxWidth="md" disableBackdropClick={true}>
                    <DialogTitle id="confirm-dialog-title">{t('driveDeleteModalTitle')}</DialogTitle>
                    <DialogContent dividers>
                        {t('driveDeleteMsg')}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={deleteDriveCard} className={classes.modalDeleteBtn}>
                            {t('driveDeleteBtn')}
                        </Button>
                        <Button onClick={handleCloseDeleteModal} color="default">
                            {t('driveModalCloseBtn')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    );
}

/**
 * class drive card
 * @param {map} param0 
 */
const ClassDriveCard = ({link, deleteFunction}) => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        card: {
            backgroundColor: "#f5f5f5"
        },
        cardContent: {
            flexGrow: 1,
        },
        cardBtn: {
            backgroundColor: "#1e88e5",
            color: "white",
            "&:hover": {
                backgroundColor: "#1e88e599"
            },
        },
        cardDeleteBtn: {
            backgroundColor: "#e53935",
            color: "white",
            "&:hover": {
                backgroundColor: "#e53935599"
            },
        },
        cardLinkTitle: {
            fontWeight: "700"
        }
    }));
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Tooltip title={link.title} arrow={true} >
                    <Typography gutterBottom className={classes.cardLinkTitle} noWrap={true}  >
                        {link.title}
                    </Typography>
                </Tooltip>
                <Typography>
                    <b>{t('driveLinkSubject')}</b>: {link.subject}
                </Typography>
                <Typography>
                    <b>{t('driveDate')}</b>: {link.sharedDate}
                </Typography>
            </CardContent>
            <CardActions>
                <Button fullWidth={true} className={classes.cardBtn} variant="contained" href={link.driveUrl} target="_blank">
                    {t('driveLinkUrl')}
                </Button>
                <Button fullWidth={true} className={classes.cardDeleteBtn} variant="contained" onClick={() => deleteFunction(link.idClassGoogleDrive)}>
                    {t('driveDeleteLinkUrl')}
                </Button>
            </CardActions>
        </Card>
    );
}

/**
 * Component React
 */
class TeacherDriveView extends React.Component{
    /**
     * Constructor
     * @param {objects} props 
     */
    constructor(props) {
        super(props);
        this.state = {groups: [], error: false, loading: true, tab: 0, status: ''};
    }

    /**
     * Fetch data from API
     */
    getClassInfo = () => {
        this.setState({...this.state, error: false, loading: true});
        fetch(Constants.api + 'webApp/teacher/getClassesDrive', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state, loading: false});
            if(json.length > 0){
                this.setState({
                    ...this.state,
                    groups: json
                });
            }else{
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    /**
     * Init state
     */
    componentDidMount(){
        this.getClassInfo();
    }

    /**
     * Refresh info change
     * @param {int} tab
     */
    refreshInfo = (tab) => {
        this.setState({...this.state, tab: tab});
        this.getClassInfo();
    };

    render(){
        return (
            <div>
                {
                    (this.state.loading) ? <Loading />:
                    (this.state.error) ? <ErrorOnLoading type={this.state.status}/>:
                    (this.state.groups.length > 0) ? (
                        <GroupTabs data={this.state.groups} refreshFunction={this.refreshInfo} tab={this.state.tab} />
                    ):<NoInfo />
                }
            </div>
        );
    }
}

export {TeacherDriveView};