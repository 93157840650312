import React,{useEffect, useState} from 'react';
import {Constants} from '../utilities/constants';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import { useTranslation } from 'react-i18next';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';

/**
 * Styles for the card 
 */
const useStyles = makeStyles({
    root: {
        backgroundColor: 'red',
      maxWidth: 300,
    },
    cover: {
        width: 300 ,
        height: 180
    },
});

/**
 * Show the screen of Documents and build the card
 * @param {Object} props 
 */
function DocumentsScreen(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid container spacing={3} style= {{paddingLeft: '10%', paddingTop: 30, width: '100%', background: 'red' }}>
            {props.data.show_autodesk_document?
            <Grid item xs={12} key={`autodesk_document`} sm={7} md={4} lg={4}>
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardMedia
                        className = {classes.cover}
                        component="img"
                        alt={"Autodesk"}
                        height="90"
                        image={Constants.base_site + Constants.autodesk_document_img}
                        title={"Autodesk"}
                        />
                        <CardContent>
                            <Typography variant="body2" color="rgb(var(--primary-text-color))" component="center" style={{margin:'auto'}} >
                                {"Autodesk"}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button
                        href={Constants.base_site + Constants.autodesk_document_download + props.data.student_info.idUser}
                        style={{
                            backgroundColor: '#ff7600',
                            color:'white',
                            margin: 'auto'
                            }}
                        download = {"Autodesk"}
                        >
                            {t('download')}&nbsp;<CloudDownloadOutlined />
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            :null
            }
            {props.data.files.map((item,index)=>(
                <Grid item xs={12} key={index} sm={7} md={4} lg={4}>
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardMedia
                        className = {classes.cover}
                        component="img"
                        alt={item.documentName}
                        height="90"
                        image={Constants.base_site + item.documentImage}
                        title={item.documentName}
                        />
                        <CardContent>
                            <Typography variant="body2" color="rgb(var(--primary-text-color))" component="center" style={{margin:'auto'}} >
                                {item.documentName.replace(".pdf", "")/* Remove .pdf */}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button 
                        href={Constants.base_site + Constants.downloadPDF + item.documentPath +'/'+ item.documentName} 
                        style={{
                            backgroundColor: '#ff7600', 
                            color:'white',
                            margin: 'auto' 
                            }}
                        download = {item.documentName}
                        >
                            {t('download')}&nbsp;<CloudDownloadOutlined />
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            ))}
        </Grid>
    );
}

/**
 * Documents main screen
 */
export function Documents(){
    //const for show the screen depending of the state
    const [loading, setLoading] = useState(true);   
    const [error, setError] = useState({
        flag: false,
        status: ''
    });
    const [state, setState] = useState();

    const fetchData= () =>{
        fetch(Constants.api + 'webApp/documents/getDocuments', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            setState(data);
            setLoading(false);
        })
        .catch(error => {
            setError({
                ...error,
                flag: true,
                status: error
            });
        });
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    return (
        (error.flag) ? <ErrorOnLoading type={error.status}/> :
        (loading) ? <Loading /> :
        <DocumentsScreen 
            data={state}
        />
    );

}
