/**
 * Constants
 */
export const Constants = {
    payBbva: 'PaymentController/online_payment/',
    downloadInvoice: 'AppController/downloadInvoice/',
    downloadLeadInvoice: 'AppController/downloadInvoiceLead/',
    downloadPDF: 'AppController/downloadFilesPDF/',
    api: 'https://api.eduscore.net/',
    base_site: 'https://admin.eduscore.net/',
    timeZone: 'America/Mexico_City',
    uploadPDF: 'AppController/uploadPDF/',
    deletePDF: 'AppController/deleteCV/',
    discordStatus: 'https://srhpyqt94yxb.statuspage.io/api/v2/status.json',
    introduction_video_eduscore: 'https://youtu.be/8nbRXQIum3k',
    introduction_video_google_workspace: 'https://youtu.be/aBboR-iYcUk',
    introduction_video_discord: 'https://youtu.be/m_u99aUJ0zs',
    introduction_video_invoicing: 'https://youtu.be/rYlNUdXl08I',
    introduction_video_connect_discord: 'https://youtu.be/vDDw9CYxAQY',
    online_payment_video: 'https://youtu.be/aIaYII9qYqk',
    invoicingURL: '_finances/Sat/create_with_cfdi_v4/',
    autodesk_document_img: 'public/img/utilities/autodesk_document.png',
    autodesk_document_download: 'AppController/create_student_software_certification_autodesk/',
    report_site: 'https://',
    site_version: '3.3.0',
    billing_tutorial_video: 'https://youtu.be/odPJDh4z-cU',
    download_receipt: 'AppController/download_receipt/'
};

