import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
// eslint-disable-next-line 
const options = {
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  order: ['navigator'],
  debug: true,
  caches: [],
  excludeCacheFor: ['cimode'],
  backend: {
    loadPath: 'locales/{{lng}}/translation36.json',
    allowMultiLoading: true
  },
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format, lng) => {
      if (format === 'uppercase') return value.toUpperCase()
      return value
    },
  },
  react: {
    useSuspense: false,
  },
  detection: {
    order: ['navigator', 'cookie'],
  },
  wait: process && !process.release,
}

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release) {
  i18n
    .use(XHR)
    .use(initReactI18next)
    .use(LanguageDetector)
}

if (!i18n.isInitialized) {
  i18n.init(options)
}

export default i18n
