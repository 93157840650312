import React from 'react';

/**
 * Context
 */
export const AppContext = React.createContext({
    isAuthenticated: false,
    setAuthentication: () => {},
    verifySession: () => {},
    destroySession: () => {},
});