import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';

export default function SurveyQuestionView ({question, handleRadioChange, radioControlFunction, answers}) {
    const useStyles = makeStyles(theme => ({
        questionTitle: {
            fontWeight: "bold"
        },
        centerQuestion: {
            textAlign: "center"
        },
        divider: {
            backgroundColor: 'rgb(var(--primary-text-color), 0.3) !important',
            marginBottom: '10px'
        }
    }));
    const classes = useStyles();
    

    return (
        <Grid container spacing={3} direction="row" justify="center" alignItems="center" style={{ background: 'rgb(var(--secondary-color))' }}>
            <Grid item key={`grid_survey_questions_content${question.survey_question_id}`} xs={12} sm={12} md={12} lg={12}  >
                <Typography align="center" className={classes.questionTitle} >
                    {`${question.question}`}
                </Typography>
            </Grid>
            <Grid item key={`grid_survey_answers${question.survey_question_id}`} xs={12} sm={12} md={12} lg={12} className={classes.centerQuestion} >
                    {
                        answers.map((answer, i) => {
                            return (
                                <FormControlLabel key={`question${question.survey_question_id}Answer${i}`} value={answer.value} checked={radioControlFunction(`question${question.survey_question_id}`,answer.value)} onChange={handleRadioChange} control={<Radio name={`question${question.survey_question_id}`}  />} label={answer.answer} labelPlacement="top" />
                            );
                        })
                    }
            </Grid>
            <Grid item key={`grid_survey_questions_content_divider${question.survey_question_id}`} xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
            </Grid>
        </Grid>
    );
}