import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import NoInfo from '../utilities/NoInfo';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import {showError} from '../utilities/errorOnFetch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
/* import { Error } from '@material-ui/icons'; */

const GradesTabs = ({data}) => {
    //const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const useStyles = makeStyles(theme => ({
        tabTitle: {
            color: 'black',
            fontWeight: "bold"
        },
        tabTitleText: {
            color: 'black',
            fontWeight: 500
        }
    }));
    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFF'
            }
        }
    });

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" style={{ background: '#ff7600' }}>
                    <Tabs
                    value={value}
                    onChange={handleTabChange}
                    variant="scrollable"
                    /* scrollButtons="on" */
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable auto tabs"
                    className={classes.tabTitle}
                    >
                        {
                            data.map((actualClass, i) => {
                                return(
                                    <Tab key={`tab${i+1}`} label = {`${actualClass.title + ' - ' + actualClass.id}`} wrapped className={classes.tabTitleText} />
                                );
                            })
                        }
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <Container style={{marginTop: 20, backgroundColor: 'rgb(var(--secondary-color))'}}>
                {
                    data.map((actualClass, i) => {
                        let activePartial = {partial:0};
                        for(var x = 0; x < actualClass.partials.length; x++){
                            if(actualClass.partials[x].enabled === 1){
                                activePartial = actualClass.partials[x];
                            }
                        }
                        return (
                            <div key={`divClass${actualClass.id}`} style={value !== i?{display:"none"}:{}} index={i} >
                                <StudentTable key={`table${actualClass.id}`} students={actualClass.rows} idClass={actualClass.id} activePartial={activePartial.partial} active_partial_id={activePartial.idPartial} period={actualClass.period_name} />
                            </div>
                        );
                    })
                }
            </Container>
        </div>
    );
}

const StudentTable = ({students, idClass, activePartial, active_partial_id, period}) => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        table: {
            overflowX: 'auto',
            maxHeight: '78vh'
        },
        bigAvatar: {
            width: 60,
            height: 60,
        },
        input: {
            backgroundColor: "rgb(var(--secondary-color))",
            textAlign: "center"
        },
        tr: {
            borderTopWidth: "1px",
            borderTopStyle: "double",
            borderBottomWidth: "1px",
            borderBottomStyle: "double"
        },
        saveBtn: {
            backgroundColor: "#43a047",
            color: "white",
            "&:hover": {
                backgroundColor: "#43a047bd"
            }
        },
        rubrics_btn: {
            backgroundColor: "#2898ee",
            "&:hover": {
                backgroundColor: "#2898eebd"
            }
        },
        rubrics_read_btn: {
            backgroundColor: "#196097",
            "&:hover": {
                backgroundColor: "#196097bd"
            }
        },
        thead: {
            fontWeight: 600
        },
        gradeRed: {
            backgroundColor: "rgb(var(--secondary-color))",
            color: "red"
        },
        hideBtn: {
            display: 'none !important'
        },
        center_rubrics_input: {
            textAlign: 'center'
        }
    }));
    const classes = useStyles();
    let grades = [];

    /* const handleSaveGrade = (idClass) => {
        let values = document.getElementsByClassName(`class_${idClass}`);
        grades = [];
        for(var index = 0; index < values.length; index++){
            let value = values[index].value;
            if(isNaN(value) || value.trim() === ""){
                value = 0;
            }
            let info_pieces = values[index].id.split("_sid");
            grades.push({
                idStudentSubject: info_pieces[1],
                type: `${info_pieces[0]}`,
                value: parseFloat(value).toFixed(1)
            });
        }
        fetch(Constants.api + 'webApp/teacher/setGrades', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                idClass: idClass,
                update: JSON.stringify(grades)
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            if(json.success === true){
                swal(t('successText'), t('gradeSaveSuccess'), "success");
            }else{
                swal('Error', t('gradeSaveError'), "error");
            }
        }).catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    } */

    const handleGradeChange = (event) => {
        let elementValue = event.target.value;
        if(elementValue < 0){
            event.target.value = 0;
        }else if(elementValue < 5 && elementValue > 0){
            //event.target.value = 5
        }
        else{
            if(elementValue > 10){
                event.target.value = 10;
            }
        }

        let decimal = new RegExp(/^(\d\.?\d?)$/);
        let decimalMore = new RegExp(/^(\d\.?\d\d+)$/);
        if (!decimal.test(event.target.value)){
            if (decimalMore.test(event.target.value)) {
                event.target.value = parseFloat(event.target.value).toFixed(1);
            }else if (event.target.value === ""){
                // Empty
            }else{
                event.target.value = 0;
            }
        }
    }

    const handleGradeBlur = (event) => {
        event.target.value = parseFloat(event.target.value);
    }

    const handle_grades_rubric_dialog_close = (event) => {
        set_grades_rubric_dialog({...grades_rubric_dialog, isOpen: false});
    }

    const [grades_rubric_dialog, set_grades_rubric_dialog] = React.useState({
        isOpen: false,
        loader: false,
        show_save_btn: false,
        rubrics_rows: [],
        student_subject_id: 0,
        student: 0,
        partial: 0
    });

    const save_student_rubric_grade = (class_id, activePartial) => {
        let grades = document.getElementsByClassName(`rubrics_grades_class_${class_id}`);
        let upload_data = {
            student_subject_id: grades_rubric_dialog.student_subject_id,
            student: grades_rubric_dialog.student,
            partial: grades_rubric_dialog.partial.id,
            grades: []
        };
        let final_grade = 0.0;
        for(let index = 0; index < grades.length; index++){
            const grade = grades[index];
            let input_id_array = grade.id.split("-");
            let input_id = input_id_array[1];
            let input_percentage = input_id_array[2];
            final_grade = final_grade + (grade.value * (input_percentage / 100));
            upload_data.grades.push({
                rubric: input_id,
                percentage: input_percentage,
                grade: grade.value === "" ? 0 : grade.value
            });
        }
        /*if(final_grade < 5){
            let final_grade_array = final_grade.toString().split(".");
            let final_grade_text = final_grade.toString();
            console.log(final_grade_array);
            if(final_grade_array.length > 1 && final_grade_array[1].length > 1){
                final_grade_text = final_grade_text.slice(0, final_grade_text.indexOf(".") + 2);
            }
            swal(t('modalErrorTitle'), `${t('rubrics_minimum_grade_error')} ${t('rubrics_minimum_grade_error_current_value')} ${final_grade_text}`, "error");
            return false;
        }*/
        upload_data.final_grade_preview = final_grade;
        set_grades_rubric_dialog({...grades_rubric_dialog, loader: true});
        fetch(Constants.api + 'webApp/teacher/save_student_grades_rubrics', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(upload_data)
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            if(json.success === true){
                set_grades_rubric_dialog({...grades_rubric_dialog, loader: false});
                if(json.upload_error === true){
                    swal(t('modalErrorTitle'), `${t('rubrics_minimum_grade_error')} ${t('rubrics_minimum_grade_error_current_value')} ${json.final_grade}`, "error");
                }else{
                    swal(t('successText'), t('gradeSaveSuccess'), "success");
                    let button_element_id = `upload_rubrics_${upload_data.student_subject_id}`;
                    let final_grade_element_id = `upload_rubrics_final_grade_${upload_data.student_subject_id}`;
                    let button_element = document.getElementById(button_element_id);
                    let final_grade_element = document.getElementById(final_grade_element_id);
                    button_element.textContent = json.partial_final_grade;
                    final_grade_element.value = json.student_subject_final_grade;
                    set_grades_rubric_dialog({...grades_rubric_dialog, isOpen: false});
                }
            }else{
                swal('Error', t('gradeSaveError'), "error");
            }
        }).catch(error => {
            swal("Error", showError(t('errorData'), error, t), "error");
            console.log("Error", error);
        });
    };

    const open_grade_rubric_dialog = (student_data, partial, partial_id, period_name, show_save_btn) => {
        set_grades_rubric_dialog({...grades_rubric_dialog, loader: true});
        fetch(Constants.api + 'webApp/teacher/get_student_grades_rubric_information', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                student: student_data.idStudent,
                partial: partial,
                student_subject_id: student_data.idStudentSubject,
                period_name: period_name
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            if(json.rubrics.length > 0){
                let rubrics_rows = json.rubrics;
                console.log(rubrics_rows);
                set_grades_rubric_dialog({...grades_rubric_dialog, rubrics_rows: rubrics_rows, student_subject_id: student_data.idStudentSubject, student: student_data.idStudent, partial: {partial: partial, id: partial_id}, isOpen: true, loader: false, show_save_btn: show_save_btn});
            }else{
                console.log("Sin información");
                set_grades_rubric_dialog({...grades_rubric_dialog, rubrics_rows: [], student_subject_id: student_data.idStudentSubject, student: student_data.idStudent, partial: {partial: partial, id: partial_id}, isOpen: true, loader: false, show_save_btn: show_save_btn})
            }
        }).catch(error => {
            set_grades_rubric_dialog({...grades_rubric_dialog, loader: false, rubrics_rows: [], student_subject_id: 0, student: 0, partial: 0, show_save_btn: false});
            swal("Error", showError(t('errorData'), error, t), "error");
            console.log("Error", error);
        });
    }

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <Grid key={`gridClassTabButton${idClass}`} container spacing={3} direction="row" justify="center"  >
                <Grid item key={`gridClassExcelTabButtonContent${idClass}`} index={idClass} xs={12} sm={6} md={4} lg={4}>
                    <Button fullWidth={true} className={`${classes.saveBtn}`} variant="contained" href={`${Constants.api}webApp/teacher/studentsGradesList/${idClass}`} >
                        {t('studentGradesList')}
                    </Button>
                </Grid>
                {/* <Grid item key={`gridClassTabButtonContent${idClass}`} index={idClass} xs={12} sm={6} md={4} lg={4}>
                    <Button fullWidth={true} className={`${classes.saveBtn} ${activePartial === 0?classes.hideBtn:''}`} variant="contained" onClick={() => {handleSaveGrade(idClass)} } >
                        {t('saveBtn')}
                    </Button>
                </Grid> */}
            </Grid>
            <Grid key={`gridClassTabContent${idClass}`} container spacing={3} direction="row" justify="center" >
                <Grid item key={`gridClassTabScheduleContent${idClass}`} index={idClass} xs={12} sm={12} md={10} lg={9} >
                    <div className={classes.table} style={{ background: 'rgb(var(--secondary-color))' }}>
                    <Table size="small" stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.thead}>{t('gradeTablePhoto')}</TableCell>
                                <TableCell align="center" className={classes.thead}>{t('gradeTableName')}</TableCell>
                                <TableCell align="center" className={classes.thead}>1° {t('partialGrade')}</TableCell>
                                <TableCell align="center" className={classes.thead}>2° {t('partialGrade')}</TableCell>
                                <TableCell align="center" className={classes.thead}>3° {t('partialGrade')}</TableCell>
                                <TableCell align="center" className={classes.thead}>4° {t('partialGrade')}</TableCell>
                                <TableCell align="center" className={classes.thead}>{t('finalGrade')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                students.map((student, i) => {
                                    return (
                                        <TableRow key={`class${idClass}Student${student.idStudentSubject}`} className={classes.tr} hover >
                                            <TableCell align="center"><Avatar className={classes.bigAvatar} variant="square" src={`${Constants.base_site}public/img/profile/thumbnails/${student.picture}`} alt={t('gradeTableAlt')} /></TableCell>
                                            <TableCell align="center" className={classes.thead} >{student.name}</TableCell>
                                            {
                                                student.partials.map((partial,partialId) => {
                                                    let redColor = partial.grade < student.gradeMin?classes.gradeRed:'noRed';
                                                    let gradeActiveClass = partial.partial === activePartial?`class_${idClass}`:'';
                                                    let inputProps = {
                                                        className: `${redColor} ${gradeActiveClass} ${classes.center_rubrics_input}`
                                                    }
                                                    let absences_input_color = partial.absence > student.absenceMax?classes.gradeRed:'noRed';
                                                    let absences_input_props = {
                                                        style: {
                                                            background: 'rgb(var(--secondary-color))'
                                                        },
                                                        className: `${absences_input_color} ${classes.center_rubrics_input}`
                                                    };
                                                    let show_button = partial.partial !==  activePartial?false:true;
                                                    return (
                                                        <TableCell key={`partial${student.id}${partial.partial}`} align="center">
                                                            {
                                                            partial.partial !== "grade_final" ?
                                                                <Button variant="contained" color="primary" id={show_button ? `upload_rubrics_${student.idStudentSubject}` : `btn_rubrics`} onClick={() => {open_grade_rubric_dialog(student, partial.partial, active_partial_id, period, show_button)}} className={`${show_button ? classes.rubrics_btn : classes.rubrics_read_btn}`} fullWidth>
                                                                    {partial.grade}
                                                                </Button> :
                                                                <TextField
                                                                    id={`upload_rubrics_final_grade_${student.idStudentSubject}`}
                                                                    defaultValue={partial.grade}
                                                                    label={t('gradeTableGrade')}
                                                                    type="number"
                                                                    disabled={true}
                                                                    inputProps={inputProps}
                                                                    placeholder="0"
                                                                />
                                                            }
                                                            <TextField
                                                            defaultValue={partial.absence}
                                                            label={t('gradeTableAbsence')} margin="normal"
                                                            disabled
                                                            inputProps={absences_input_props} />
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                            {
                                                /*student.delays.map((delay,delayId) => {
                                                    return (
                                                        <TableCell key={`delay${student.id}${delayId}`} align="center">
                                                            <TextField id={`d${delayId + 1}_${student.idStudentSubject}`} defaultValue={delay.delay} label={t('delayTableAbsence')}  type="number" disabled={true} placeholder="0" />
                                                        </TableCell>
                                                    );
                                                })*/
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    </div>
                </Grid>
            </Grid>

            <Dialog open={grades_rubric_dialog.isOpen} onClose={handle_grades_rubric_dialog_close} maxWidth="md" fullWidth={true} disableBackdropClick={true} >
                <DialogTitle>{t("grades_text")}</DialogTitle>
                <Loading display={grades_rubric_dialog.loader ? "grid" : "none"} height='25vh' />

                <DialogContent style={grades_rubric_dialog.loader ? {display: "none"} : {}}>
                    <DialogContentText component="div">
                    </DialogContentText>
                    <Grid item key={`grid_student_grades_rubrics_${idClass}`} index={idClass} xs={12} sm={12} md={12} lg={12} >
                        <div className={classes.table} style={{ background: 'rgb(var(--secondary-color))' }}>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className={classes.thead}>{t('rubric_text')}</TableCell>
                                    <TableCell align="center" className={classes.thead}>{t('percentage_text')}</TableCell>
                                    <TableCell align="center" className={classes.thead}>{t('gradeTableGrade')} (5-10)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{
                                grades_rubric_dialog.rubrics_rows.map((rubric, i) => {
                                    let redColor = rubric.grade < rubric.gradeMin?classes.gradeRed:'noRed';
                                    let rubric_grade_class = `rubrics_grades_class_${idClass}`;
                                    let inputProps = {
                                        className: `${redColor} ${rubric_grade_class} ${classes.center_rubrics_input}`
                                    }
                                    return (
                                        <TableRow key={`class_${idClass}_rubric_${rubric.id}`} className={classes.tr} hover>
                                            <TableCell align="center">{rubric.rubric_name}</TableCell>
                                            <TableCell align="center">{`${rubric.rubric_percentage}%`}</TableCell>
                                            <TableCell align="center">
                                            {
                                                <TextField 
                                                    id={`grade_rubric-${rubric.id}-${rubric.rubric_percentage}`}
                                                    defaultValue={rubric.grade}
                                                    label={t('gradeTableGrade')}
                                                    onBlur={(event) => handleGradeBlur(event)}
                                                    onChange={(event) => handleGradeChange(event, idClass)}
                                                    inputProps={inputProps}
                                                    type="number"
                                                    placeholder="0"
                                                    disabled={!grades_rubric_dialog.show_save_btn}
                                                />
                                            }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }</TableBody>
                        </Table>
                        </div>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {!grades_rubric_dialog.loader && grades_rubric_dialog.show_save_btn ? <Button className='buttons' color="secondary" variant="outlined" disabled={grades_rubric_dialog.loader} onClick={() => {save_student_rubric_grade(idClass, activePartial)}}>
                        {t("saveBtn")}
                    </Button> : null}
                    <Button className='buttons' onClick={handle_grades_rubric_dialog_close} color="primary" variant="outlined" disabled={grades_rubric_dialog.loader}>
                        {t("closeText")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

class TeacherGradesView extends React.Component{
    constructor(props){
        super(props);
        this.state = {groups: [], error: false, loading: true, tab: 0, status: ''};
        /* this.state = {groups: [{id: 0, partials: [{enabled: 1, partial: 2}], rows: [{idStudentSubject: 0, partials: [{partial: 1,grade: 6, absence: 2 }], absenceMax: 10, gradeMin: 8,  name: 'Antonio'}] }], error: false, loading: false, tab: 0, status: ''}; */
    }

    getGrades = () => {
        this.setState({...this.state, error: false, loading: true});
        fetch(Constants.api + 'webApp/teacher/getTeacherGrades', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state, loading: false});
            if(json.length > 0){
                this.setState({
                    ...this.state,
                    groups: json
                });
            }else{
                console.log("Sin información");
                console.log(json);
                this.setState({...this.state, status: 404})
            }
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    componentDidMount() {
        this.getGrades();
    }

    render(){
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                {
                    (this.state.loading) ? <Loading />:
                    (this.state.error) ? <ErrorOnLoading type={this.state.status}/>:
                    (this.state.groups.length > 0) ? (
                        <GradesTabs data={this.state.groups} />
                    ):<NoInfo />
                }
            </div>
        );
    }
}

export {TeacherGradesView};
