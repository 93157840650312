import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import swal from '@sweetalert/with-react';
import Loading from '../utilities/Loading';
import SurveyQuestionView from './SurveyQuestionView';

const SurveyInfo = ({data}) => {
    const useStyles = makeStyles(theme => ({
        text: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: 190,
            fontSize: 20,
            color: '#37474f'
        },
        large: {
            width: 90,
            height: 90,
        },
    }));
    const classes = useStyles();
    return (
        <Table size="medium" stickyHeader >
            <TableBody>
                <TableRow className={classes.tr} hover >
                    <TableCell align="center" className={classes.thead}>
                        <Typography>
                            {`${data.survey_name}`}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.thead}>
                        <Typography>
                            {`${data.description}`}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default function SurveyView ({data, refresh}) {
    const { t } = useTranslation();
    const [enableSaving, setUpdateSaving] = React.useState(false);
    const [formSurvey, setFormSurvey] = React.useState({
        loader: false
    });

    const [radios, setRadio] = React.useState({
        radios: {},
        radiosLength: 0
    });

    const handleRadioChange = (event) => {
        let radioControl = radios.radios;
        let radioControlLength = radios.radiosLength;
        radioControlLength = (radioControl[event.target.name] === undefined)?radioControlLength + 1:radioControlLength;
        radioControl[event.target.name] = event.target.value;
        setRadio({...radios,radios: radioControl, radiosLength: radioControlLength});
        if(radioControlLength == (data.questions.length) ){
            setUpdateSaving(true);
        }else{
            setUpdateSaving(false);
        }
    };

    const radioControlFunction = (name, value) => {
        let radio = radios.radios[name];
        if(radio == value){
            return true;
        }
        return false;
    };

    const save_survey = () => {
        if(radios.radiosLength === (data.questions.length)){
            setFormSurvey({
                ...formSurvey, loader: true
            });
            fetch(Constants.api + data.save_results_url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    id_registration: data.survey_information.registration_id,
                    id_survey: data.survey_information.survey_id,
                    questions: JSON.stringify(radios.radios)
                })
            })
            .then(response => {
                return response.json()
            })
            .then(json => {
                setFormSurvey({
                    ...formSurvey, loader: false
                });
                if(json.success == true){
                    refresh();
                    swal(t('successText'), t('survey_success_save'), "success");
                }else{
                    swal('Error', t('survey_error_save'), "error");
                }
            }).catch(error => {
                console.log(error);
                setFormSurvey({
                    ...formSurvey, loader: false
                });
                swal('Error', t('messageError'), "error");
            });
        }else{
            swal(t('important_text'), t('survey_pending_questions'), "warning");
        }
    };

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
        {
            formSurvey.loader ? <Loading /> :
            <Container style={{marginTop: 20, background: 'rgb(var(--secondary-color))'}}>
                <Grid key={`grid_survey`} container spacing={3} direction="row" justify="center" >
                    <Grid item key={`grid_survey_title`} xs={12} sm={12} md={12} lg={12}  >
                        <SurveyInfo data={data.survey_information} />
                    </Grid>
                    <Grid item key={`grid_survey_content`} xs={12} sm={12} md={12} lg={12}  >
                        <div style={{ background: 'rgb(var(--secondary-color))' }}>
                            {
                                data.questions.map((question, q) => {
                                    return (
                                        <SurveyQuestionView question={question} key={`grid_survey_question${data.survey_information.survey_id}Q${question.survey_question_id}`} radioControlFunction={radioControlFunction} handleRadioChange={handleRadioChange} answers={question.answers} />
                                    );
                                })
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}  >
                        <Button variant="contained" style={{ margin: '15px auto 0px', background: '#ff7600', color: 'rgb(var(--primary-text-color))' }} fullWidth disabled={!enableSaving} onClick={save_survey} >
                            {t("evaluationSend")}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        }
        </div>
    );
} 