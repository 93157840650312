import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from './Drawer';
import ComponentLoader from './../components/Frame/ComponentLoader';
import { Routes, TitleLocalization } from '../utilities/routes';
import CssBaseline from "@material-ui/core/CssBaseline";
import { useTranslation } from 'react-i18next';
import { ReactComponent as DarkIcon } from '../SVGs/DarkIcon.svg'
import { ReactComponent as LightIcon } from '../SVGs/LightIcon.svg'
import { applyTheme } from '../utilities/themes'

/**
 * Styles for the class components
 */
const useStyles = makeStyles(theme => ({
  root: {
    background: 'rgb(var(--secondary-color))',
    flexGrow: 1,
    minHeight: '100vh'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    backgroundColor: 'red',
    color: 'white'
  },
  title: {
    flexGrow: 1,
  },
  contentContainer: {
    backgroundColor: 'rgb(var(--secondary-color))',
    display: 'flex',
    margin: '0 auto'
  },
  containerCentered: {
    backgroundColor: 'rgb(var(--secondary-color))',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

}));

/**
 * View for the drawer
 * @param {object} props 
 */
const Frame = (props) => {
    const { t } = useTranslation();
    const [state, setState] = React.useState({
      module: {name: t('titleProfile'), route:'/profile'}
    })

    const [darkMode, enableDarkMode] = useState(JSON.parse(localStorage.getItem('darkMode')))

    useEffect(() => {
      enableDarkMode(darkMode)
      applyTheme(darkMode)
    }, [darkMode])

    /**
     * Select module 
     * @param {object} item 
     */
    const onSelectModule = (item) => {
        setState({...state, module: item})
    };

    const toggleDarkMode = () => {
      localStorage.setItem('darkMode', JSON.stringify(!darkMode))
      applyTheme(!darkMode)
      enableDarkMode(!darkMode)
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
        <CssBaseline/>
            <AppBar  position="static" className= {classes.appBar} >
                <Toolbar>
                    <Drawer
                        opener={
                        <IconButton edge="start"
                            className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                        }
                        moduleSelected={1}
                        onSelectModule={onSelectModule}
                    />
                    <div style = {{margin: 'auto'}}>
                        <Typography variant="h6" className={classes.title} >
                            <b>{TitleLocalization(state.module.name)}</b>
                        </Typography>
                    </div>
                    
                    <div style={{
                      position: 'relative',
                      zIndex: '0',
                      overflow: 'hidden',
                      alignSelf: 'center',
                      width: '40px',
                      height: '40px',
                    }}>
                      <div style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        display: 'flex',
                        alignSelf: 'center',
                        zIndex: '0',
                        transform: !darkMode ? 'rotate(-180deg)' : '',
                        transitionProperty: 'transform',
                        transitionTiminFfunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                        transitionDuration: '200ms',
                        right: '-13rem',
                        bottom: '10px',
                        background: 'rgb(var(--complementary-color))'
                      }}>

                        <div style={{ background: 'rgb(var(--complementary-color))', height: '20px', width: '20px', marginRight: '12rem' }} onClick={() => toggleDarkMode()}>
                          <DarkIcon/>
                        </div>
                        <div style={{ background: 'rgb(var(--complementary-color))', height: '20px', width: '20px'  }} onClick={() => toggleDarkMode()}>
                          <LightIcon />
                        </div>
                      </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                <ComponentLoader tag={Routes[state.module.route]}/>
            </div>
        </div>
    );
}

export default Frame;