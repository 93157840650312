import React from 'react';
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import NoInfo from '../utilities/NoInfo';
import BalanceTabsView from './BalanceTabsView';

/**
 * Component for balance react component
*/
class LeadBalanceView extends React.Component {
    /**
     * constructor
     * @param {object} props 
     */
    constructor(props) {
        super(props);
        this.state = { balance_data: [], error: false, loading: true, status: '' };
    }

    /**
     * Init state
     */
    componentDidMount() {
        fetch(Constants.api + 'webApp/student/getLeadBalance', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(response => {
                if(!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(json => {
                this.setState({ ...this.state, loading: false });
                if (json.balance_data.length > 0) {
                    this.setState({
                        ...this.state,
                        balance_data: json.balance_data,
                        campus: json.lead_campus,
                        prices: json.prices_by_period
                    });
                } else {
                    console.log("Sin información");
                    console.log(json);
                }
            }).catch(error => {
                this.setState({ ...this.state, error: true, status: error });
                console.log("Error balance");
                console.log(error);
            });
    }

    render() {
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                {
                    (this.state.error) ? <ErrorOnLoading type={this.state.status}/> :
                        (this.state.loading) ? <Loading /> :
                            (this.state.balance_data.length > 0) ? (
                                <BalanceTabsView data={this.state.balance_data} isStudent={false} idCampus = {this.state.campus} prices = {this.state.prices} />
                            ) : <NoInfo />
                }
            </div>
        );
    }
};

export { LeadBalanceView };