export const applyTheme = (theme) => {
  const root = document.documentElement
  if(theme) {
    root.style.setProperty('--primary-color', '13, 19, 25')
    root.style.setProperty('--secondary-color', '44, 48, 52')
    root.style.setProperty('--background-color', '25, 29, 33')
    root.style.setProperty('--primary-text-color', '234, 234, 234')
    root.style.setProperty('--secondary-text-color', '220, 220, 220')
    root.style.setProperty('--complementary', '255, 118, 0')
    root.style.setProperty('--opacity', '0.2')
  } else {
    root.style.setProperty('--primary-color', '255, 255, 255')
    root.style.setProperty('--secondary-color', '230, 230, 230')
    root.style.setProperty('--background-color', '25, 29, 33')
    root.style.setProperty('--primary-text-color', '35, 35, 35')
    root.style.setProperty('--secondary-text-color', '220, 220, 220')
    root.style.setProperty('--complementary', '255, 118, 0')
    root.style.setProperty('--opacity', '1')
  }
}
