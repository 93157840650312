import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import Container from '@material-ui/core/Container';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import NoInfo from '../utilities/NoInfo';
import Radio from '@material-ui/core/Radio';
import swal from 'sweetalert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {showError} from '../utilities/errorOnFetch';
import moment from 'moment-timezone';

moment.tz.setDefault(Constants.timeZone);

const ActiveClass = ({data, refreshFunction}) => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        table: {
            padding: '1rem',
            borderRadius: '4px',
            overflowX: 'auto',
            maxHeight: '73vh'
        },
        bigAvatar: {
            width: 60,
            height: 60,
        },
        input: {
            textAlign: "center"
        },
        tr: {
            borderTopWidth: "1px",
            borderTopStyle: "double",
            borderBottomWidth: "1px",
            borderBottomStyle: "double"
        },
        saveBtn: {
            backgroundColor: "#43a047",
            color: "white",
            "&:hover": {
                backgroundColor: "#43a047bd"
            },
        },
        thead: {
            fontWeight: 600
        },
        gradeRed: {
            color: "red"
        },
        paper: {
            margin: "10px 0",
            padding: "4px 24px",
            borderLeft: "5px solid rgb(var(--complementary))",
            backgroundColor: "rgba(3, 169, 244, 0.06)"
        }
    }));
    const classes = useStyles();
    const [radios, setRadio] = useState({
        radioControl: []
    })
    let studentList = [];

    const handleRadioClick = (event) => {
        let radioControl = radios.radioControl;
        radioControl[event.target.name] = (radioControl[event.target.name] === event.target.value)?"":event.target.value;
        setRadio({ ...radios, radioControl: radioControl });
    };

    const radioControlFunction = (name, value) => {
        let radio = radios.radioControl[name];
        if(radio === value){
            return true;
        }
        return false;
    };

    const handle_save_absence = (idAbsenceActivity) => {
        swal({
            title: t('absenceConfirmTitle'),
            text: t('absenceConfirmTxt'),
            icon: "warning",
            closeOnClickOutside: false,
            buttons: {
                cancel: {
                    text: t('absenceConfirmCancel'),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: t('absenceConfirmProcced'),
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        }).then((isConfirm) => {
            if(isConfirm){
                let values = document.querySelectorAll("input[type='radio']:checked");
                studentList = {
                    absence: {},
                    delay: {}
                };

                for(var index = 0; index < values.length; index++){
                    let value = values[index].value;
                    let id = values[index].name.replace("student","");
                    studentList[`${value}`][`${id}`] = 'true';
                }
                
                fetch(Constants.api + 'webApp/absence/setAbsence', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        idAbsenceActivity: idAbsenceActivity,
                        update: JSON.stringify(studentList)
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        throw response.status;
                    }
                    else {
                        return response.json();
                    }
                })
                .then(json => {
                    if(json.success === true){
                        refreshFunction();
                        swal(t('successText'), t('absenceSaveSuccess'), "success");
                    }else{
                        swal('Error', t('absenceSaveFail'), "error");
                    }
                }).catch(error => {
                    swal("Error",
                    showError(t('errorData'), error, t) ,
                    "error");
                });
            }
        });
    }

    return (
        <Container style={{marginTop: 20}}>
            <Grid key={`gridClassButton`} container spacing={3} direction="row" justify="center" >
                <Grid item key={`gridClassButtonContent`} xs={12} sm={7} md={4} lg={4}>
                    <Button fullWidth={true} className={classes.saveBtn} variant="contained" onClick={() => {handle_save_absence(data.idAbsenceActivity)} }>
                        {t('saveBtn')}
                    </Button>
                </Grid>
            </Grid>
            <Grid key={`gridClassTitle`} container spacing={3} direction="row" justify="center" >
                <Grid item key={`gridClassTitleContent`} xs={12} sm={7} md={4} lg={4}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" component="h3">
                            <center>
                                {data.subjectName} ( {moment(data.startTime, 'HH:mm:ss').tz(moment.tz.guess(true)).format("HH:mm")} - {moment(data.endTime, 'HH:mm:ss').tz(moment.tz.guess(true)).format("HH:mm")})
                                <br/>ID:&nbsp;{data.idClass}
                            </center>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid key={`gridClassTabContent`} container spacing={3} direction="row" justify="center" >
                <Grid item key={`gridClassTabScheduleContent`} xs={12} sm={12} md={8} lg={8} >
                    <div className={classes.table}>
                        <Table size="small" stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className={classes.thead}>{t('gradeTablePhoto')}</TableCell>
                                    <TableCell align="center" className={classes.thead}>{t('gradeTableName')}</TableCell>
                                    <TableCell align="center" className={classes.thead}>{t('absenceTableAbsence')}</TableCell>
                                    <TableCell align="center" className={classes.thead}>{t('delayTableAbsence')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.rows.map((student,i) => {
                                        return (
                                            <TableRow key={`classStudent${student.id}`} className={classes.tr} hover >
                                                <TableCell align="center"><Avatar className={classes.bigAvatar} variant="square" src={`${Constants.base_site}public/img/profile/thumbnails/${student.picture}`} alt={t('gradeTableAlt')} /></TableCell>
                                                <TableCell align="center" className={classes.thead} >{student.name}</TableCell>
                                                <TableCell align="center" className={classes.thead} >
                                                    {/* <Checkbox value={`student${student.id}`} /> */}
                                                    <Radio name={`student${student.id}`} value={'absence'} checked={radioControlFunction(`student${student.id}`,'absence')} onClick={handleRadioClick} />
                                                </TableCell>
                                                <TableCell align="center" className={classes.thead} >
                                                    <Radio name={`student${student.id}`} value={'delay'} checked={radioControlFunction(`student${student.id}`,'delay')} onClick={handleRadioClick} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

class TeacherAbsenceView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            students: [],
            error: false,
            loading: true,
            status: ''
        };
    }

    getActiveClassInfo = () => {
        this.setState({...this.state, error: false, loading: true});
        fetch(Constants.api + 'webApp/absence/getActiveGroup', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state, loading: false});
            if(json.length > 0){
                this.setState({
                    ...this.state,
                    students: json
                });
            }else{
                this.setState({
                    ...this.state,
                    students: []
                });
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    componentDidMount(){
        this.getActiveClassInfo();
    }

    render(){
        return (
            <div style={{ backgroundColor: 'transparent' }}>
                {
                    (this.state.loading) ? <Loading />:
                    (this.state.error) ? <ErrorOnLoading type={this.state.status} />:
                    (this.state.students.length > 0) ? (
                        <ActiveClass data={this.state.students[0]} refreshFunction={this.getActiveClassInfo} />
                    ):<NoInfo />
                }
            </div>
        );
    }
}

export {TeacherAbsenceView};