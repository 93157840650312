import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function EvaluationQuestionView ({question, handleRadioChange, radioControlFunction, answers}) {
    const useStyles = makeStyles(theme => ({
        questionTitle: {
            fontWeight: "bold"
        },
        centerQuestion: {
            textAlign: "center"
        }
    }));
    const classes = useStyles();
    
    return (
        <Grid container spacing={3} direction="row" justify="center" alignItems="center" style={{ background: 'rgb(var(--primary-color))' }}>
            <Grid item key={`gridTeachersEvaluationsQuestionsContent${question.idQuestion}`} xs={12} sm={12} md={12} lg={12} style={{ background: 'rgb(var(--primary-color))' }}>
                <Typography align="center" className={classes.questionTitle} >
                    {`${question.question}`}
                </Typography>
            </Grid>
            <Grid item key={`gridTeachersEvaluationsAnswers${question.idQuestion}`} xs={12} sm={12} md={12} lg={12} className={classes.centerQuestion} >
                    {
                        answers.map((answer, i) => {
                            return (
                                <FormControlLabel key={`question${question.idQuestion}Answer${i}`} value={answer.value} checked={radioControlFunction(`question${question.idQuestion}`,answer.value)} onChange={handleRadioChange} control={<Radio name={`question${question.idQuestion}`}  />} label={answer.answer} labelPlacement="top" />
                            );
                        })
                     }
            </Grid>
        </Grid>
    );
}