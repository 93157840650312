import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import NoInfo from '../utilities/NoInfo';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import RestrictMessage from '../utilities/RestrictMessage';

const GradesTabs = ({data}) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const useStyles = makeStyles(theme => ({
        tabTitle: {
            color: 'black',
            fontWeight: "bold"
        },
        tabTitleText: {
            color: 'black',
            fontWeight: 600
        }
    }));
    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFFFFF'
            }
        }
    });

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" style={{ background: '#ff7600' }}>
                    <Tabs
                    value={value}
                    onChange={handleTabChange}
                    variant="scrollable"
                    /* scrollButtons="on" */
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable auto tabs"
                    className={classes.tabTitle}
                    >
                        {
                            data.map((actualClass, i) => {
                                return(
                                    <Tab key={`tab${i+1}`} label = {`${t('period')} ${actualClass.periodName}`} className={classes.tabTitleText} />
                                );
                            })
                        }
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <Container style={{marginTop: 20, background: 'rgb(var(--secondary-color))'}}>
                {
                    data.map((actualClass, i) => {
                        return (
                            <div key={`divClass${actualClass.periodName}`} style={value !== i?{display:"none"}:{}} index={i} >
                                {
                                    actualClass.teacherEvaluation.length > 0 || actualClass.otherEvaluations.length > 0? <PendingEvaluationView key={`alertPendingEvaluation${actualClass.id}`} />:<GradesTable key={`table${actualClass.id}`} grades={actualClass.rows} id={actualClass.id} />}
                            </div>
                        );
                    })
                }
            </Container>
        </div>
    );
}

const PendingEvaluationView = () => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        alertTile: {
            fontWeight: 'bold'
        }
    }));
    const classes = useStyles();
    return (
        <Alert severity="warning">
            <AlertTitle className={classes.alertTile}>
                {t('gradePendingEvaluationAlertTitle')}
            </AlertTitle>
            <Typography>
                {t('gradePendingEvaluationAlertContent')}
            </Typography>
        </Alert>
    );
}

const PendingTelephones = (props) => {
    const { t } = useTranslation();
    let text = t(props.text).split("_");
    let list = text.slice(1);
    return (
        <Container style={{marginTop: 20, backgroundColor: 'rgb(var(--secondary-color))'}}>
            <Alert severity="warning">
                <Typography>
                    {text[0]}
                </Typography>
                <ul>
                    {list.map((item, index) => {
                        return (
                            <li key={`alert_telephones_${index}`}>{item}</li>
                        );
                    })}
                </ul>
            </Alert>
        </Container>
    );
}

const GradesTable = ({grades, id}) => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        table: {
            overflowX: 'auto',
            maxHeight: '78vh'
        },
        bigAvatar: {
            width: 60,
            height: 60,
        },
        input: {
            textAlign: "center"
        },
        tr: {
            borderTopWidth: "1px",
            borderTopStyle: "double",
            borderBottomWidth: "1px",
            borderBottomStyle: "double"
        },
        saveBtn: {
            backgroundColor: "#43a047",
            color: "white",
            "&:hover": {
                backgroundColor: "#43a047bd"
            },
        },
        thead: {
            fontWeight: 600
        },
        gradeRed: {
            color: "red"
        }
    }));
    const classes = useStyles();

    return (
        <div style={{ backgroundColor: 'rgb(var(--secondary-color))' }}>
            <Grid style={{ backgroundColor: 'rgb(var(--secondary-color))' }} key={`gridClassTabContent${id}`} container spacing={3} direction="row" justify="center" >
                <Grid  item key={`gridClassTabScheduleContent${id}`} index={id} xs={12} sm={12} md={10} lg={10} >
                    <div className={classes.table}>
                    <Table size="medium" stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.thead}>{t('gradeTableSubject')}</TableCell>
                                <TableCell align="center" className={classes.thead}>1° {t('partialGrade')}</TableCell>
                                <TableCell align="center" className={classes.thead}>2° {t('partialGrade')}</TableCell>
                                <TableCell align="center" className={classes.thead}>3° {t('partialGrade')}</TableCell>
                                <TableCell align="center" className={classes.thead}>4° {t('partialGrade')}</TableCell>
                                <TableCell align="center" className={classes.thead}>{t('finalGrade')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                grades.map((grade,i) => {
                                    return (
                                        <TableRow key={`class${id}Student${grade.id}`} className={classes.tr} hover >
                                            <TableCell align="center" className={classes.thead}>{grade.name}</TableCell>
                                            {
                                                grade.partials.map((partial,partialId) => {
                                                    return (
                                                        <TableCell key={`partial${grade.id}${partialId}`} align="center">
                                                            <TextField value={partial.grade} label={t('gradeTableGrade')} disabled inputProps={partial.grade < grade.gradeMin?{className: classes.gradeRed}:{}} />
                                                            <TextField value={partial.absence} label={t('gradeTableAbsence')} margin="normal" disabled inputProps={partial.absence > grade.absenceMax?{className: classes.gradeRed}:{}} />
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                            {
                                                grade.extras.map((partial,partialId) => {
                                                    return (
                                                        <TableCell key={`partialExtra${grade.id}${partialId}`} align="center">
                                                            <TextField value={partial.grade} label={t('extraordinaryGrade')} disabled inputProps={partial.grade < grade.gradeMin?{className: classes.gradeRed}:{}} />
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                            {
                                                grade.retake.map((partial,partialId) => {
                                                    return (
                                                        <TableCell key={`partialRetake${grade.id}${partialId}`} align="center">
                                                            <TextField value={partial.grade} label={t('retakeGrade')} disabled inputProps={partial.grade < grade.gradeMin?{className: classes.gradeRed}:{}} />
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

class StudentGradesView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            grades: [],
            error: false,
            loading: true,
            status: '',
            restrict: false,
            contentRestrict: null,
            show_alert: false,
            alert_text: null
        }
    }

    getGrades = () => {
        this.setState({...this.state, error: false, loading: true});
        fetch(Constants.api + 'webApp/student/getStudentGrades', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(json => {
            this.setState({...this.state, loading: false});
            if(json.length > 0){
                this.setState({
                    ...this.state,
                    grades: json
                });
            }else{
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
            console.log("Error");
            console.log(error);
        });
    }

    checkBlackList = () =>{
        fetch(Constants.api + 'webApp/user/checkBlackList', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            if(data.restrict){
                this.setState({...this.state, loading: false, restrict: true, contentRestrict: data.result[0]});
            }else{
                this.setState({...this.state, restrict: false});
                this.get_user_contacts_telephones();
            }
        })
        .catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
        });
    }

    get_user_contacts_telephones = () =>{
        fetch(Constants.api + 'webApp/student/get_contacts_telephones', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            let show_telephones_error = false;
            if(data.user.length === 0 || data.contacts.length === 0){
                show_telephones_error = true;
            }
            if(!show_telephones_error){
                show_telephones_error = true;
                for (let index = 0; index < data.contacts.length; index++) {
                    const contact = data.contacts[index];
                    for (let i = 0; i < data.user.length; i++) {
                        const user = data.user[i];
                        if(contact.telephone === user.number && user.leadRelationship !== "Propio"){
                            show_telephones_error = false;
                        }
                        if(contact.telephone !== user.number){
                            show_telephones_error = false;
                        }
                    }
                }
            }
            if(show_telephones_error){
                this.setState({...this.state, loading: false, show_alert: true, alert_text: "grades_missing_telephones"});
            }else{
                this.setState({...this.state, loading: false, show_alert: false});
                this.getGrades();
            }
            /*this.setState({...this.state, loading: false});
            if(data.restrict){
                this.setState({...this.state, restrict: true, contentRestrict: data.result[0]});
            }else{
                this.setState({...this.state, restrict: false});
                this.getGrades();
            }*/
        })
        .catch(error => {
            this.setState({...this.state, error: true, loading: false, status: error});
        });
    }

    componentDidMount() {
        this.checkBlackList();
    }

    render(){
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                {
                    (this.state.loading) ? <Loading />:
                    (this.state.error) ? <ErrorOnLoading type={this.state.status} />:
                    (this.state.restrict) ? <RestrictMessage restrict={this.state.contentRestrict}/>:
                    (this.state.show_alert) ? <PendingTelephones text={this.state.alert_text}/>:
                    (this.state.grades.length > 0) ? (
                        <GradesTabs data={this.state.grades} />
                    ):<NoInfo />
                }
            </div>
        );
    }
}

export {StudentGradesView}