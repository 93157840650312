
/**
 * Show error in a swal
 * @param {String} msg 
 * @param {Int} type 
 */
export const showError = (msg,type,t)=>{
    switch(type){
        case 401:
            return t('sessionExpired')
        default: //500
            return msg
    }
}