import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Constants } from '../utilities/constants.js';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import {showError} from '../utilities/errorOnFetch';
import swal from 'sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Alert, AlertTitle } from '@material-ui/lab';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Link from '@material-ui/core/Link';
import Loading from '../utilities/Loading';
import moment from 'moment';
import 'moment/min/locales';

/**
    * Format for number
    * @param {int} value 
*/
const numberFormat = (value) => {
    let currencyNumber = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(value);
    currencyNumber = currencyNumber.toString().replace("US", "");
    let number_parts = currencyNumber.split(".");
    if (number_parts.length === 1) {
        return currencyNumber + ".00";
    } else {
        return currencyNumber;
    }
}

const dates_format = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

const change_date_format = (date) => {
    moment.locale('es');
    let formatted_date = moment(date).format("LL");
    return formatted_date;
};

/**
    * Balance tabs
    * @param {map} param0 
*/
const BalanceTabsView = ({ data, isStudent, idCampus, prices, show_period, active_period }) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(show_period);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFFFFF'
            }
        }
    });

    const useStyles = makeStyles(theme => ({
        tabTitle: {
            color: 'black',
            fontWeight: "bold"
        },
        tabPeriod: {
            color: 'black',
            fontWeight: 600
        },
        table: {
            overflowX: 'auto',
            maxHeight: '78vh',
            marginTop: '5vh',
            marginBottom: '5vh'
        }
    }));
    const classes = useStyles();

    const [billingDialog, setBillingDialog] = React.useState({
        isOpen: false,
        data: [],
        billingProfile: "",
        invoice: 0,
        loader: false
    });

    const handleDialogClose = () => {
        setBillingDialog({billingProfile: "", isOpen: false, data: [], invoice: 0});
    };

    const handleBillingSelectChange = (event) => {
        setBillingDialog({...billingDialog, billingProfile: event.target.value});
    };

    const confirmCreation = () => {
        if(billingDialog.billingProfile !== ""){
            swal({
                title: t('balanceBillingConfirmationTitle'),
                text: t('balanceBillingConfirmationText'),
                icon: "warning",
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: t('balanceBillingConfirmationBtnCancel'),
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true
                    },
                    confirm: {
                        text: t('balanceBillingConfirmationBtnContinue'),
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            }).then((isConfirm) => {
                if(isConfirm){
                    createSatInvoice(billingDialog.invoice, billingDialog.billingProfile);
                }
            });
        }else{
            swal("Error", t("balanceBillingNoProfileSelected"), "error");
        }
    };

    const createSatInvoice = (invoice, billingProfile) => {
        setBillingDialog({...billingDialog, loader: true});
        fetch(Constants.base_site + Constants.invoicingURL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idInvoice: invoice,
                idBilling: billingProfile
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }else {
                return response.json();
            }
        })
        .then(json => {
            setBillingDialog({...billingDialog, loader: false});
            if(json.status === 1){
                handleDialogClose();
                swal({
                    title: t('successText'),
                    text: t('balanceBillingSuccessTxt'),
                    icon: "success",
                    closeOnEsc: false,
                    closeOnClickOutside: false
                }).then((isConfirm) => {
                    window.location.reload();
                });
            }else{
                console.log(json.msg);
                swal("Error", json.msg, "error");
            }
        }).catch(error => {
            setBillingDialog({...billingDialog, loader: false});
            console.log(error);
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    };

    const getBillingProfiles = (invoice) => {
        fetch(Constants.api + 'webApp/billing/getBilling', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }else {
                return response.json();
            }
        })
        .then(json => {
            if(json.billing.length > 0){
                setBillingDialog({...billingDialog, isOpen: true, data: json.billing, invoice: invoice});
            }else{
                swal('Error', t('balanceNoBillingInfoAlert'), "error");
            }
        }).catch(error => {
            swal("Error",
            showError(t('errorData'), error, t) ,
            "error");
        });
    };

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" style={{ background: '#ff7600' }}>
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable auto tabs"
                    className={classes.tabTitle}
                    >
                        {
                            data.map((periodTitle, i) => {
                                return(
                                    <Tab key={`tab_${periodTitle.period}`} label={`${t('period')} ${periodTitle.period}`} className={classes.tabPeriod} />
                                );
                            })
                        }
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <Container style={{ marginTop: 20, background: 'rgb(var(--secondary-color))' }}>
                <Alert severity="warning" style={{ marginBottom: 20 }}>
                    <AlertTitle><b>{t("titleBilling")}</b></AlertTitle>
                    <Typography>
                        {t("balanceBillingWarningAlert")}
                    </Typography>
                    <Typography>
                        {t("balanceBillingRegimeWarningAlert")}
                    </Typography>
                    <br/>
                    <Typography>
                        {t("billing_tutorial_video_text")} <Link href={Constants.billing_tutorial_video} color="error" target="_blank" rel="noopener noreferrer">{t("billing_tutorial_video_link_text")}</Link>
                    </Typography>
                    <Typography>
                        <br/>
                        <b>{t("warning")}</b>
                    </Typography>
                    <Typography>
                        {t("warningIncrementPrice1")}
                    </Typography>
                    <Typography component={'span'}>
                        <br/>
                        <table style={{width: "100%"}}>
                            <tbody>
                                <tr>
                                {
                                    /* prices.map((value,index)=>{
                                        return(
                                            <th key={index}><b>{value.periodName}</b></th>
                                        )
                                    }) */
                                }
                                </tr>
                                <tr>
                                {
                                    /* prices.map((value,index)=>{
                                        return(
                                            <td key={index}><center>{`$ ${value.total}.00`}</center></td>    
                                        )
                                    }) */
                                }
                                </tr>
                            </tbody>
                        </table>
                    </Typography>
                </Alert>
                {
                    data.map((period, i) => {
                        return(
                            <div key={`period_balance_container_${i}`}>
                            <Grid key={`gridBalanceTab${i}`} container spacing={3} direction="row" justify="center" index={i} style={value !== i?{display:"none"}:{}}>
                                {
                                    period.invoices.map((invoice, index) => {
                                        return (
                                            <Grid item key={`gridBalanceTabContent${i}${index}`} index={i} xs={12} sm={7} md={4} lg={4}>
                                                <BalanceInvoiceCard key={`balanceCard${index + 1}`} invoice={invoice} isStudent={isStudent} openBillingDialog={getBillingProfiles} />
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                            <Grid key={`grid_balance_payments_tab${period.period}`} container direction="row" justify="center" index={i} style={value !== i?{display:"none"}:{}}>
                                <Grid item key={`grid_balance_payments_tab${period.period}_content`} index={i} xs={12} sm={12} md={10} lg={10} >
                                    <div className={classes.table}>
                                        <BalancePaymentTable key={`balance_payments_${period.period}_${i + 1}`} payments={period.payments} period={period.period} />
                                    </div>
                                </Grid>
                            </Grid>
                            </div>
                        );
                    })
                }
                <Dialog open={billingDialog.isOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth={true}
                disableBackdropClick={true} >
                    <DialogTitle>{t("titleBilling")}</DialogTitle>
                    {(billingDialog.loader) ? <Loading /> :
                    <DialogContent>
                        <DialogContentText component="div">
                            <Alert severity="info">
                                <AlertTitle>{t("titleBilling")}</AlertTitle>
                                <Typography>
                                    {t("balanceBillingInfoModal")}
                                </Typography>
                            </Alert>
                        </DialogContentText>
                        <FormControl fullWidth>
                            <InputLabel id="balanceBillingProfiles">
                                {t("balanceBillingModalSelectLabel")}
                            </InputLabel>
                            <Select
                                labelId="balanceBillingProfiles"
                                id="balanceBillingProfiles"
                                value={billingDialog.billingProfile}
                                label={t("balanceBillingModalSelectLabel")}
                                onChange={handleBillingSelectChange}
                                autoWidth={true}
                            >
                                {
                                    billingDialog.data.map((profile, i) => {
                                        return (
                                            <MenuItem key={`billingProfile${i}`} value={profile.idBilling}>{profile.profileName === null || profile.profileName === "" ? profile.rfc : profile.profileName}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </DialogContent>
                    }
                    <DialogActions>
                        <Button className='buttons' onClick={confirmCreation} color="secondary" variant="outlined" disabled={billingDialog.loader}>
                            {t("balanceBillingModalCreateBtn")}
                        </Button>
                        <Button className='buttons' onClick={handleDialogClose} color="primary" variant="outlined" disabled={billingDialog.loader}>
                            {t("closeText")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    );
}

/**
 * Card invoice card
 * @param {map} param0 
 */
const BalanceInvoiceCard = ({ invoice, isStudent, openBillingDialog }) => {
    const { t } = useTranslation();
    const [payment_button, set_payment_button] = React.useState({
        isDisabled: false
    });
    const useStyles = makeStyles(theme => ({
        card: {
            backgroundColor: "#f5f5f5"
        },
        cardContent: {
            flexGrow: 1,
        },
        cardBtnDownload: {
            backgroundColor: "#1e88e5",
            color: "white",
            "&:hover": {
                backgroundColor: "#1e88e5bd"
            },
        },
        cardBtnPay: {
            backgroundColor: "#43a047",
            color: "white",
            "&:hover": {
                backgroundColor: "#43a047bd"
            },
        },
        cardBtnSat: {
            backgroundColor: "#f44336",
            color: "white",
            "&:hover": {
                backgroundColor: "#f44336bd"
            },
        },
        cardBtnPending:{
            backgroundColor: "#43a047",
            color: "white",
            "&:hover": {
                backgroundColor: "#43a047bd"
            },
        },
        cardInvoiceTitle: {
            fontWeight: "700"
        },
        card_receipt_btn: {
            backgroundColor: "#1e88e5",
            color: "white",
            "&:hover": {
                backgroundColor: "#1e88e5bd"
            }
        }
    }));
    let buttonsEnable = true;
    let activateInvoiceBtn = invoice.activateInvoice;
    const classes = useStyles();
    if (invoice.sta === "Abierto") {
        invoice.sta = "Pendiente";
    } else if (invoice.sta === "Parcial") {
        invoice.sta = "Pago Parcial";
    } else if (invoice.sta === "Pagado" || invoice.sta === "Pago Tardio") {
        buttonsEnable = false;
    }

    const payment_button_click = () => {
        set_payment_button({isDisabled: true});
    }
    return (
        <Card className={classes.card} key={invoice.idInv} >
            <CardContent className={classes.cardContent} >
                <Tooltip title={invoice.concepto?invoice.concepto:"No encontrado"} arrow={true} >
                    <Typography gutterBottom className={classes.cardInvoiceTitle} noWrap={true} >
                        {invoice.concepto}
                    </Typography>
                </Tooltip>
                <Typography>
                    <b>{t('total')}</b>: {numberFormat(invoice.tot)} MXN
                </Typography>
                <Typography>
                    <b>{t('outstandingBalance')}</b>: {numberFormat(invoice.rest)}
                </Typography>
                <Typography>
                    <b>{t('state')}</b>: {invoice.sta}
                </Typography>
                {isStudent ?
                <Typography>
                    <b>{t('dueDate')}</b>: {change_date_format(invoice.invoiceDate)}
                </Typography>
                : null}
            </CardContent>
            {
                buttonsEnable ? (
                    <CardActions>
                        {isStudent ? 
                            <StudentDownloadInvoice invoice={invoice} classes={classes} /> : 
                            <LeadDownloadInvoice invoice={invoice} classes={classes} />
                        }
                        <Button fullWidth={true} 
                                className={classes.cardBtnPay} 
                                variant="contained"
                                disabled={payment_button.isDisabled}
                                onClick={payment_button_click}
                                onAuxClick={payment_button_click}
                                href={`${Constants.base_site}${Constants.payBbva}${invoice.idInv}/${invoice.idUser}`}> 
                            {t('payNow')}
                        </Button>
                    </CardActions>
                ) : null
            }
            {
                activateInvoiceBtn ? (
                    <CardActions>
                        <SatInvoiceCreation invoice={invoice} classes={classes} openBillingDialog={openBillingDialog} />
                    </CardActions>
                ) : null
            }
        </Card>
    );
}

const StudentDownloadInvoice = ({invoice, classes}) =>{
    const { t } = useTranslation();
    return (
        <Button fullWidth={true} className={classes.cardBtnDownload} variant="contained" href={`${Constants.base_site}${Constants.downloadInvoice}${invoice.idInv}`} >
            <center>{t('downloadInvoice')}</center>
        </Button>
    );
}

const LeadDownloadInvoice = ({invoice, classes}) =>{
    const { t } = useTranslation();
    return (
        <Button fullWidth={true} className={classes.cardBtnDownload} variant="contained" href={`${Constants.base_site}${Constants.downloadLeadInvoice}${invoice.idInv}`} >
            <center>{t('downloadInvoice')}</center>
        </Button>
    );
}

const SatInvoiceCreation = ({invoice, classes, openBillingDialog}) => {
    const {t} = useTranslation();

    return (
        <Button fullWidth={true} className={classes.cardBtnSat} variant="contained" 
        onClick={() => {openBillingDialog(invoice.idInv)}} >
            <center>{t("balanceSatInvoicingBtn")}</center>
        </Button>
    );
}

const ReceiptButton = ({payment, classes}) => {
    const {t} = useTranslation();

    return (
        <Button fullWidth={true} className={classes.card_receipt_btn} variant="contained" 
        href={`${Constants.base_site}${Constants.download_receipt}${payment.idPay}`} >
            <center>{t("receipt_button")}</center>
        </Button>
    );
}

const BalancePaymentTable = ({payments, period}) => {
    const {t} = useTranslation();
    const useStyles = makeStyles(theme => ({
        card_receipt_btn: {
            backgroundColor: "#1e88e5",
            color: "white",
            "&:hover": {
                backgroundColor: "#1e88e5bd"
            }
        },
        table: {
            overflowX: 'auto',
            maxHeight: '78vh',
            minWidth: 650
        },
        tr: {
            borderTopWidth: "1px",
            borderTopStyle: "double",
            borderBottomWidth: "1px",
            borderBottomStyle: "double"
        },
        thead: {
            fontWeight: 600
        }
    }));
    const classes = useStyles();
    return (
        <TableContainer>
        <Table size="medium" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell align="center" colSpan={3}>
                        <Typography>Pagos</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    payments.map((payment, payment_index) => {
                        return (
                            <TableRow key={`payments_${period}_${payment_index}`} className={classes.tr} hover >
                                <TableCell align="center" className={classes.thead}>
                                    <Typography>
                                        {`${payment.payment_date}`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" className={classes.thead}>
                                    <Typography>
                                        {`${payment.payment_concepts}`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" className={classes.thead}>
                                    <ReceiptButton payment={payment} classes={classes} />
                                </TableCell>
                            </TableRow>
                        );
                    })
                }
            </TableBody>
        </Table>
        </TableContainer>
    );
}

export default BalanceTabsView;
