import React,{useEffect, useState} from 'react';
import {Schedule as ScheduleScreen} from '../components/Schedule/Schedule'
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import RestrictMessage from '../utilities/RestrictMessage';
/**
 * Schedule component
 */
export function Schedule() {
    const [loading,setLoading] = useState(true);
    const [restriction, setRestriction] = useState(null);
    const [schedule,setSchedule] = useState([]);
    const [error, setError] = useState({
        flag: false,
        status: ''
    });

    /**
     * Fetch data using the API
    */
    // eslint-disable-next-line
    const fetchSchedule = () => {
        fetch(Constants.api + 'webApp/schedule', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            setSchedule(data.response);
            if(data.message !== undefined){
                setError({
                    ...error,
                    flag: true,
                });
                setLoading(false);
            }else{
                setLoading(false);
            }
        })
        .catch(error => {
            setError({
                ...error,
                flag: true,
                status: error
            });
            //swal("Error", t('informationError'), "error");
        });
    }

    const verifyEmergencyContacts = () =>{
        fetch(Constants.api + 'webApp/user/checkBlackList', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            //if not access
            if(data.restrict){
                setRestriction(data)
            }else{
                setRestriction(null)
            }
        })
        .catch(error => {
            setError({
                ...error,
                flag: true,
                status: error
            });
            //swal("Error", t('informationError'), "error");
        });
    }

    /**
     * Init state
     */
    useEffect(() => {
        verifyEmergencyContacts();
    // eslint-disable-next-line 
    }, [ ])

    return (
        (error.flag) ? <ErrorOnLoading type={error.status}/> :
        (loading)? <Loading /> :
        (restriction !== null)? <RestrictMessage restriction={restriction} /> :
        <ScheduleScreen 
            hours={schedule}
            //loading = {loading}
        />
    );
}