import React, { useState, useEffect } from 'react';
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { EventNote as EventNoteIcon, FindInPage as FindInPageIcon } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import { Constants } from '../utilities/constants';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';

/**
 * Styles for components
 */
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'rgb(var(--secondary-color))',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 50
    },
    appBar: {
        backgroundColor: '#ff7600',
        zIndex: 2,
        color: 'white'
    },
    section1: {
        margin: theme.spacing(3, 2),
    },
}));

/**
 * Component for the tab panel
 * @param {object} props 
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

/**
 * Tab panel types
 */
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

/**
 * Select the index to change
 * @param {int} index 
 */
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/**
 * Filtered components
 */
const FilterComponent = ({ filterText, onFilter, onClear }) => {
    const { t } = useTranslation();
    return(
        <>
            <TextField id="search" type="text" placeholder={t('search')} value={filterText} onChange={onFilter} />
        </>
    );
}

/**
 * When expand the row
 */
const ExpandedComponent = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div style={{ marginLeft: '6%', marginRight: '6%', marginTop: 30 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <div style={{ textAlign: 'left' }}>
                        <img height="auto" width="150px" style={{ maxHeight: '180px' }} alt={data.name}
                            src={Constants.base_site + 'public/img/convenios_logos/' + data.logo_url} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{ textAlign: 'left', fontSize: 14 }}>
                        <b>{t('name')}:&nbsp;</b>{data.name}<br /><br />
                        <b>{t('description')}:&nbsp;</b>{data.objective}<br />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{ textAlign: 'left', fontSize: 14 }}>
                        <b>{t('campus')}:&nbsp;</b> {data.campus} <br />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{ textAlign: 'left', fontSize: 14 }}>
                        <b>{t('email')}:&nbsp;</b>{data.emails}<br />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ fontSize: 14 }}>
                        <b>{t('careers')}: </b><br />
                        {
                            data.careers
                        }
                        <br /><br /><br />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}


/**
 * Agreements show the companies
 */
function Agreements(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const data = props.data;

    /**
 * Pagination options 
 */
    const paginationOptions = {
        rowsPerPageText: t('rowsPerPage'),
        rangeSeparatorText: t('of'),
        selectAllRowsItem: true,
        selectAllRowsItemText: t('all')
    };

    const columns = [
        {
            name: 'Logo',
            selector: 'logo_url',
            maxWidth: '150px',
            sortable: false,
            grow: 1,
            cell: data => <img height="auto" width="70px" style={{ maxHeight: '50px' }} alt={data.name} src={Constants.base_site + 'public/img/convenios_logos/' + data.logo_url} />
        },
        {
            name: t('name'),
            wrap: true,
            selector: 'name',
            maxWidth: '200px',
            sortable: true
        },
        {
            name: t('description'),
            selector: 'objective',
            maxWidth: '400px',
            sortable: true
        },
        {
            name: t('campus'),
            selector: 'campus',
            maxWidth: '150px'
        },
        {
            name: t('startDate'),
            selector: 'startDate',
            maxWidth: '110px',
            sortable: true
        },
        {
            name: t('endDate'),
            selector: 'endDate',
            maxWidth: '110px',
            sortable: true
        },
    ];

    const filteredItems = data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    /**
     * Filter component
     */
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return (
        <div className={classes.root} id='agreements-table' style={{ background: 'rgb(var(--secondary-color))' }} >
            <DataTable
                theme='dark'
                title={t('agreements')}
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                paginationComponentOptions={paginationOptions}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                expandableRows
                expandableRowsComponent={<ExpandedComponent />}
            />
        </div>
    );
}

function GetReports(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const data = (props.information === 'Not found')? [] : props.information;
    const reports = (props.information === 'Not found')? 'Not found' : props.reports;

    const ExpandedReports = ({ data }) => {
        const { t } = useTranslation();

        // Get hour by json information
        const getHours = () => {
            for (let i=0; i< reports.length; i++){
                // this is [i][0] because is a array with arrays json [{[0][1]}] like this
                if (reports[i][0].idSocialService === data.idSocialService){
                    return reports[i]
                }
            }
        }

        //Show the hours in the report by idSocialService
        const hours =  getHours()

        // Show headers
        const headers = [{
            name : t('numberHours'),
            selector: 'amountHours',
        },{
            name : t('startDate'),
            selector: 'startDate',
        },{
            name : t('endDate'),
            selector: 'endDate',
        }];

        return (
            <div id='agreements-table' style={{ marginLeft: '6%', marginRight: '6%'}}>
                <DataTable
                    theme='dark'
                    columns={headers}
                    data={hours}
                />
            </div>
        )
    }

    const columns = [
        {
            name: t('name'),
            selector: 'name',
            wrap: true,
            sortable: true,
            maxWidth: '600px'
        },
        {
            name: t('career'),
            selector: 'career',
            wrap: true,
            sortable: true,
            maxWidth: '600px'
        },
        {
            name: t('startDate'),
            selector: 'startDate',
            wrap: true,
            sortable: true,
            maxWidth: '600px'
        },
        {
            name: t('endDate'),
            selector: 'endDate',
            wrap: true,
            sortable: true,
            maxWidth: '600px'
        },
        {
            name: t('hrsRequired'),
            selector: 'serviceHours',
            wrap: true,
            sortable: true,
            maxWidth: '600px'
        },
        {
            name: t('hrsMade'),
            selector: 'advanceHours',
            sortable: true,
            maxWidth: '600px'
        },
        {
            name: t('dependency'),
            selector: 'dependency',
            wrap: true,
            sortable: true,
            maxWidth: '600px'
        },
        {
            name: t('statusProgress'),
            selector: 'serviceStatus',
            wrap: true,
            sortable: true,
            maxWidth: '600px'
        }
    ];


    return (
        <div>
            <br /><br/>
            <Alert severity="info">{t('alertSocial')}</Alert>
            <div id='agreements-table' className={classes.root} style={{ paddingTop: 40, height: 500 }}>
                <DataTable
                    theme='dark'
                    title={t('reports')}
                    columns={columns}
                    data={data}
                    expandableRows={(reports === 'Not found')? false: true}
                // expandableRowDisabled = {row => true}
                    expandableRowsComponent={<ExpandedReports />}
                />
            </div>
        </div>
    );
}

/**
 * The main view
 */
function SocialServiceScreen(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    /**
    * Create theme
    */
    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFF'
            }
        }
    });

    /**
     * New value for navbar
     * @param {object} event 
     * @param {int} newValue 
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" className={classes.appBar} >
                    <Tabs indicatorColor="secondary" value={value} onChange={handleChange} style={{ margin: 'auto' }} >
                        <Tab icon={<FindInPageIcon />}  {...a11yProps(0)} />
                        {(props.information !== "Not found") ?
                            <Tab icon={<EventNoteIcon />} {...a11yProps(1)} /> : null}
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <TabPanel value={value} index={0} >
                <Agreements
                    data={props.data}
                />
            </TabPanel>
            <TabPanel value={value} index={1} >
                <GetReports
                    information = {props.information}
                    reports = {props.reports}
                 />
            </TabPanel>
        </div>
    );
}

export function SocialServiceView() {
    //const for show the screen depending of the state
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState({
        flag: false,
        status: ''
    });

    /**
 * Fetch data using the API
 */
    const fetchData = () => {
        fetch(Constants.api + 'webApp/socialService/getCompanies', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                // get data
                setData(data);
                //console.log(data)
                setLoading(false);
            })
            .catch(error => {
                setError({
                    ...error,
                    flag: true,
                    status: error
                });
            });
    }

    /**
     * Init state, using eslint disable for ignore the warning
    */
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line 
    }, [])

    return (
        (error.flag) ? <ErrorOnLoading type={error.status} /> :
            (loading) ? <Loading /> :
                <SocialServiceScreen
                    data={data.companies}
                    information={data.information}
                    reports = {data.reports}
                />
    );
}