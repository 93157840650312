import React,{useState,useEffect} from 'react';
import {Constants} from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import DataTable from 'react-data-table-component';

/**
 * Styles for components
 */
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'rgb(var(--secondary-color))',
    },
}));

/**
 * Filtered components
 */
const FilterComponent = ({ filterText, onFilter, onClear }) => {
    const { t } = useTranslation();
    return(<>
        <TextField id="search" type="text" placeholder={t('search')} value={filterText} onChange={onFilter} />
    </>)
}

/**
 * Intern service screen
 * @param {Object} props
 */
function InternServiceScreen(props){
    const classes = useStyles();
    const { t } = useTranslation();
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const data = props.data;

    const columns = [
        {
            name: t('semester'),
            selector: 'period',
            sortable: true,
        },
        {
            name: t('area'),
            selector: 'area',
            sortable: true,
        },
        {
            name: t('madeHours'),
            selector: 'madeHours',
            sortable: true,
        },
        {
            name: t('totalHours'),
            selector: 'totalHours',
            sortable: true,
        },
        {
            name: t('status'),
            selector: 'statusSemester',
            sortable: true,
        },
    ];

    /**
    * Pagination options 
    */
    const paginationOptions = {
        rowsPerPageText: t('rowsPerPage'),
        rangeSeparatorText: t('of'),
        selectAllRowsItem: true,
        selectAllRowsItemText: t('all')
    };

    /**
     * Filtered items
     */
    const filteredItems = data.filter(item => 
        item.area && item.area.toLowerCase().includes(filterText.toLowerCase()));

    /**
     * Filter component
     */
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return(
        <div id='agreements-table' className={classes.root} style={{ paddingTop: 40, height: 700, paddingLeft:40, paddingRight:40 }} >
            <DataTable
                theme='var(--dark)'
                title={t('tableIntern')}
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                paginationComponentOptions={paginationOptions}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
            />
        </div>
    );
}

/**
 * Main view
 */
export function InternServiceView() {
    //const for show the screen depending of the state
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState([]);
    const [error, setError] = useState({
        flag: false,
        status: ''
    });

    const fetchData= () =>{
        fetch(Constants.api + 'webApp/internService/getReports', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            console.log(data)
            setState(data);
            setLoading(false);
        })
        .catch(error => {
            setError({
                ...error,
                flag: true,
                status: error
            });
        });
    }

    //On init
    useEffect(() => {
        fetchData();
    // eslint-disable-next-line
    }, [])

    return (
        (error.flag) ? <ErrorOnLoading type={error.status}/> :
        (loading) ? <Loading /> :
        <InternServiceScreen 
            data = {state}
        />
    );
}