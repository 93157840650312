import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import swal from '@sweetalert/with-react'
import classNames from 'classnames';


/**
 *  Const for show each subject by color. 
*/
const colorClass = {
    1: "#F95959",
    2: "#FACF5A",
    3: "#2471A3",
    4: "#2C3E50",
    5: "#05A19C",
    6: "#8559A5",
    7: "#424949",
    8: "#E67E22",
    9: "#0B5345",
    10: "#AA255B",
};

/**
 * Style for show components
 */
const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650
    },
    cell: {
        borderRight: '0.05em solid #e0e0e0',
        padding: '0.8em',
        paddingRight: 4,
        paddingLeft: 5,
        color: '#707372'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        //width: '50%',
    },
    btnColor: {
        backgroundColor: 'orange',
        "&:hover": {
          background: "orange"
        }
    },
    toggleRow: {
        backgroundColor: '#f0f0f0'
    },
});

/**
 * Component dot material
 * @param {object} props
 */
function DotMaterial(props) {
    const { t } = useTranslation();
   // const classes = useStyles();
    return (
        <div onClick={() => {
            swal({
                buttons: (props.url == null)?
                {
                    primary:"Ok"
                }:
                {
                    //primary: "Ok",
                    catch: {
                        text: t('classOnline'),
                        value: "go",
                        //className: classes.btnColor
                    },
                    cancel: "Ok",
                },
                content: (
                    <div>
                        <br/>
                        {/*<div style={{backgroundColor:'orange', width:'100%', height:50}}>&nbsp;</div>*/}
                        <p style={{ fontSize: 20}}><b>{t('teacher')}: </b>{props.teacher}</p>
                        <p style={{ fontSize: 20}}><b>{t('class')}: </b>{props.subject}</p>
                        <p style={{ fontSize: 20}}><b>{t('classroom')}: </b>{props.classRoom}</p>
                    </div>
                )
            }).then((value)=>{
                switch(value){
                    case "go":
                        window.open(
                             props.url,
                            '_blank' // <- This is what makes it open in a new window.
                        );
                    break;
                    default: break;
                }
            })
        }}>
            <div
                style={{
                    backgroundColor: props.color,
                    margin: "auto",
                    width: '100%',
                    color: 'white',
                    height: 80,
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                    cursor: "pointer",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10,
                }}
            >
                {props.subject}
            </div>
        </div>
    )
}

/**
 * Create data in the table
 * @param {string} hour 
 * @param {object} mon 
 * @param {object} tue 
 * @param {object} wed 
 * @param {object} thu 
 * @param {object} fri 
 * @param {object} sat 
 */
function createData(hour, mon, tue, wed, thu, fri, sat) {
    return { hour, mon, tue, wed, thu, fri, sat };
}

/**
 * Return a array depending the hours example if is 07:00:00 - 09:00:00 = [7,8,9]
 * @param {int} startHour 
 * @param {int} endHour 
 */
function getHours(startHour, endHour) {
    let total = endHour - startHour
    let hours = [];
    let firstHour = parseInt(startHour)
    hours.push(firstHour)
    for (let i = 0; i < total; i++) {
        hours.push(firstHour += 1)
    }
    return hours;
}

/**
 * Build the schedulewith the json data 
 * @param {object} data 
 */
function buildSchedule(data, hours) {
    let subjectColor = new Map()
    let auxColor = 1

    for (let i = 0; i < data.hours.length; i++) {
        //s take the two first value from a string 0 + 7 = 07
        let startHour = data.hours[i]['startTime'][0] + data.hours[i]['startTime'][1]
        // take the two first value from a string 0 + 9 = 09
        let endHour = data.hours[i]['endTime'][0] + data.hours[i]['endTime'][1];
        // Substract 9 - 7 = 2
        let result = getHours(startHour, endHour)
        let day = data.hours[i]['day']
        let classSchool = data.hours[i]['subject']
        let classRoom = data.hours[i]['classroom']
        let teacher = data.hours[i]['teacher']
        let url= data.hours[i]['url']
        let color
        if (subjectColor.has(classSchool)) {
            color = subjectColor.get(classSchool)
        } else {
            color = colorClass[auxColor]
            auxColor++
            subjectColor.set(classSchool, color)
        }

        for (let j = 0; j < result.length; j++) {
            if ((result.length - 1) !== j) {
                let start = result[j]
                let end = (result[j] + 1)
                let key = day + start + end
                hours[key] =
                    <DotMaterial
                        color={color}
                        teacher={teacher}
                        subject={classSchool}
                        classRoom={classRoom}
                        url = {url}
                    />
            }
        }
    }
}

/**
 * Component for show the schedule
 * @param {object} props 
 */
export function Schedule(props) {
    const hours = {};
    const { t } = useTranslation();
    const classes = useStyles();
    let toggleRow = false;
    //console.log(props)
    buildSchedule(props, hours);
    console.log(hours);
    //Build the schedule with the new datas
    const rows = [
        createData('07:00 - 08:00', hours['Mon78'], hours['Tue78'], hours['Wed78'], hours['Thu78'], hours['Fri78'], hours['Sat78']),
        createData('08:00 - 09:00', hours['Mon89'], hours['Tue89'], hours['Wed89'], hours['Thu89'], hours['Fri89'], hours['Sat89']),
        createData('09:00 - 10:00', hours['Mon910'], hours['Tue910'], hours['Wed910'], hours['Thu910'], hours['Fri910'], hours['Sat910']),
        createData('10:00 - 11:00', hours['Mon1011'], hours['Tue1011'], hours['Wed1011'], hours['Thu1011'], hours['Fri1011'], hours['Sat1011']),
        createData('11:00 - 12:00', hours['Mon1112'], hours['Tue1112'], hours['Wed1112'], hours['Thu1112'], hours['Fri1112'], hours['Sat1112']),
        createData('12:00 - 13:00', hours['Mon1213'], hours['Tue1213'], hours['Wed1213'], hours['Thu1213'], hours['Fri1213'], hours['Sat1213']),
        createData('13:00 - 14:00', hours['Mon1314'], hours['Tue1314'], hours['Wed1314'], hours['Thu1314'], hours['Fri1314'], hours['Sat1314']),
        createData('14:00 - 15:00', hours['Mon1415'], hours['Tue1415'], hours['Wed1415'], hours['Thu1415'], hours['Fri1415'], hours['Sat1415']),
        createData('15:00 - 16:00', hours['Mon1516'], hours['Tue1516'], hours['Wed1516'], hours['Thu1516'], hours['Fri1516'], hours['Sat1516']),
        createData('16:00 - 17:00', hours['Mon1617'], hours['Tue1617'], hours['Wed1617'], hours['Thu1617'], hours['Fri1617'], hours['Sat1617']),
        createData('17:00 - 18:00', hours['Mon1718'], hours['Tue1718'], hours['Wed1718'], hours['Thu1718'], hours['Fri1718'], hours['Sat1718']),
        createData('18:00 - 19:00', hours['Mon1819'], hours['Tue1819'], hours['Wed1819'], hours['Thu1819'], hours['Fri1819'], hours['Sat1819']),
        createData('19:00 - 20:00', hours['Mon1920'], hours['Tue1920'], hours['Wed1920'], hours['Thu1920'], hours['Fri1920'], hours['Sat1920']),
        createData('20:00 - 21:00', hours['Mon2021'], hours['Tue2021'], hours['Wed2021'], hours['Thu2021'], hours['Fri2021'], hours['Sat2021']),
        createData('21:00 - 22:00', hours['Mon2122'], hours['Tue2122'], hours['Wed2122'], hours['Thu2122'], hours['Fri2122'], hours['Sat2122']),
    ];

    return (
        //(props.loading) ? <img className={classes.image} src={logo_eduscore} alt="Logo Eduscore"></img> :
            <Paper className={classes.root} style={{ marginRight: 10 }} >
                <Table className={classes.table} >
                    <TableHead>
                        <TableRow >
                            <TableCell align="center" style={{ width: "14%", fontSize: 15 }} className={classes.cell}><b>{t('hour')}</b></TableCell>
                            <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('monday')}</b></TableCell>
                            <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('tuesday')}</b></TableCell>
                            <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('wednesday')}</b></TableCell>
                            <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('thursday')}</b></TableCell>
                            <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('friday')}</b></TableCell>
                            <TableCell align="center" style={{ fontSize: 15, width: "14%" }} className={classes.cell}><b>{t('saturday')}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map(row => {
                                toggleRow = !toggleRow;
                                return (
                                    <TableRow key={row.hour} className={classNames(toggleRow?classes.toggleRow:'')}>
                                        <TableCell align="center" style={{ fontSize: 15, height: 100 }} className={classes.cell}><b>{row.hour}</b></TableCell>
                                        <TableCell align="center" className={classes.cell}>{row.mon}</TableCell>
                                        <TableCell align="center" className={classes.cell}>{row.tue}</TableCell>
                                        <TableCell align="center" className={classes.cell}>{row.wed}</TableCell>
                                        <TableCell align="center" className={classes.cell}>{row.thu}</TableCell>
                                        <TableCell align="center" className={classes.cell}>{row.fri}</TableCell>
                                        <TableCell align="center" className={classes.cell}>{row.sat}</TableCell>
                                    </TableRow>
                                )}
                            )
                        }
                    </TableBody>
                </Table>
            </Paper>
    );
}