import firebase from 'firebase/app';
import 'firebase/firestore'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
//import 'firebase/firebase-messaging';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAz4CfqfbzXTNkrEBdKo-WxsO4IuAP-2Iw",
    authDomain: "eduscore-app.firebaseapp.com",
    databaseURL: "https://eduscore-app.firebaseio.com",
    projectId: "eduscore-app",
    storageBucket: "eduscore-app.appspot.com",
    messagingSenderId: "250736247218",
    appId: "1:250736247218:web:1389295becf2fadb6d24ab",
    measurementId: "G-HDCRK0YVP3"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

export const myFirebase = firebase
export const myFirestore = firebase.firestore()
export const myStorage = firebase.storage()
//export const messaging = firebase.messaging()
