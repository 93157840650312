import React from 'react';
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';       
import NoInfo from '../utilities/NoInfo';
import Container from '@material-ui/core/Container';

/**
 * Component for balance react component
*/

class ProfessionalPracticesView extends React.Component {
    /**
     * constructor
     * @param {object} props 
     */
    constructor(props) {
        super(props);
        this.state = { data: { projects: [], required_hours:0}, error: false, loading: true, status: '' };
    }

    /**
     * Init state
     */
    componentDidMount() {
        fetch(Constants.api + 'webApp/professionalPractice/getReports', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(response => {
                if(!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(json => {
                this.setState({ ...this.state, loading: false });
                if (json.projects.length > 0) {
                    this.setState({
                        ...this.state,
                        data: {
                          projects: json.projects,
                          required_hours: json.required_hours.reduce((acc, hour) => acc + hour.maximum_hours, 0)
                        },
                    });
                } else {
                    console.log("Sin información");
                    console.log(json);
                }
            }).catch(error => {
                this.setState({ ...this.state, error: true, status: error });
                console.log("Error balance");
                console.log(error);
            });
    }

    render() {
        return (
            <div style={{ background: 'rgb(var(--secondary-color))' }}>
                {
                    (this.state.error) ? <ErrorOnLoading type={this.state.status}/> :
                        (this.state.loading) ? <Loading /> :
                            (this.state.data.projects.length > 0) ? (
                              <Container style={{ marginTop: 20 }}>
                                <div style={{ padding: '1px 30px 1px 30px' }}>
                                  <p style={{ fontSize: '18px' }}>
                                    Horas totales realizadas: {this.state.data?.projects?.reduce((accumulator, value) => JSON.parse(value?.reports ?? '[]').reduce((sum, value2) => sum + value2.hours, 0) + accumulator, 0)} / {this.state.data?.required_hours}
                                  </p>
                                </div>
                                <div style={{ padding: '1px 10px 1px 30px' }}>
                                  <p style={{ fontSize: '16px', fontWeight: 500 }}>Participaciones</p>
                                  {
                                    this.state.data?.projects.map((project, index) => <div style={{ display: 'flex', marginBottom: '1rem' }} key={index}>
                                    <div style={{ alignSelf: 'center', width: '30%' }}>
                                      <p style={{ fontWeight: '600' }} className='text-sm font-bold text-primary-text/70'>Nombre</p>
                                      <p className='text-lg font-medium text-primary-text/80'>{project.name}</p>
                                    </div>
                                    <div  style={{ alignSelf: 'center', width: '20%' }}>
                                      <p style={{ fontWeight: '600' }} className='text-sm font-bold text-primary-text/70'>Estado</p>
                                      <p className='text-lg font-medium text-primary-text/80'>{project.status}</p>
                                    </div>
                                    <div  style={{ alignSelf: 'center', width: '20%' }}>
                                      <p style={{ fontWeight: '600' }} className='text-sm font-bold text-primary-text/70'>Horas realizadas</p>
                                      <p className='text-lg font-medium text-primary-text/80'>{JSON.parse(project?.reports ?? '[]').reduce((acc, report) => acc + report.hours, 0)}</p>
                                    </div>
                                    <div  style={{ alignSelf: 'center', width: '20%' }}>
                                      <p style={{ fontWeight: '600' }} className='text-sm font-bold text-primary-text/70'>Horas requeridas</p>
                                      <p className='text-lg font-medium text-primary-text/80'>{project.hours_required}</p>
                                    </div>
                                  </div>)
                                  }
                                </div>
                              </Container>
                            ) : <NoInfo />
                }
            </div>
        );
    }
};

export { ProfessionalPracticesView };