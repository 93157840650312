import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MaterialDrawer } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {FolderOpenSharp} from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';


/**
 * Styles for the components
 */
const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: 'auto',
    },
    listItemText: {
        color: '#888888',
        fontWeight: 'bold'
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            padding: '2px 0'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.3)'
            //outline: '1px solid slategrey'
        }
    }
});

/**
 * build the avatar header for drawer
 * @param {object} props 
 */
function AvatarDrawer(props) {
    return (
        <div style={{
            backgroundColor: 'rgb(var(--complementary))',
            height: 250,
            paddingTop: 50
        }}>
            <Avatar
                style={{
                    margin: 'auto',
                    width: 100,
                    height: 100, backgroundColor: 'rgba(var(--primary-color), 0.4)'
                }}
                src={props.data.picture}
            >
            </Avatar>
            <Typography style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 15, padding: 5, color: 'rgb(var(--primary-color))' }}>
                {props.data.name}
            </Typography>
            <Typography style={{ textAlign: 'center', color: 'rgb(var(--primary-color))' }}>
               {props.data.email}
            </Typography>
        </div>
    )
}

/**
 * Drawer react component
 * @param {object} props 
 */
export function Drawer(props) {
    //const context = React.useContext(AppContext);
    const classes = useStyles();
    /**
    * List of components or items in the drawer
    * @param {object} side 
    */
    const sideList = (side, state) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={props.toggleDrawer(side, false)}
            onKeyDown={props.toggleDrawer(side, false)}
        >
            <AvatarDrawer 
                data = {state}
            />
            <List>
                {props.state.modules.map((item, index) => (
                    <ListItem
                        button key={item.name + index}
                        onClick={() => {
                                props.onSelectModule(item)
                            }
                        }
                    >
                        <ListItemIcon><FolderOpenSharp /></ListItemIcon>
                        <ListItemText classes={{ primary: classes.listItemText }} primary={item.translation} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
    return (
        <Fragment>
            <div onClick={props.toggleDrawer('left', true)}>
                {props.opener}
            </div>
            <MaterialDrawer open={props.state.left} onClose={props.toggleDrawer('left', false)}>
                {sideList('left', props.state)}
            </MaterialDrawer>
        </Fragment>
    )
}