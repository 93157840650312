import React, { useState, useEffect } from 'react';
import Login from '../components/Login/Login';
import bg_piram from '../utilities/images/bg_piram.png';
import logo_eduscore from '../utilities/images/logo_eduscore.png';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {AppContext} from '../utilities/app-context';
import {Constants} from '../utilities/constants';
import swal from '@sweetalert/with-react';
import { useTranslation } from 'react-i18next';
import Loading from '../utilities/Loading';
import { applyTheme } from '../utilities/themes'
import { ReactComponent as DarkIcon } from '../SVGs/DarkIcon.svg'
import { ReactComponent as LightIcon } from '../SVGs/LightIcon.svg'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
/**
 * Login view
 */
export default function LoginView() {
    const { t } = useTranslation();

    const [darkMode, enableDarkMode] = useState(JSON.parse(localStorage.getItem('darkMode')))

    useEffect(() => {
      enableDarkMode(darkMode)
      applyTheme(darkMode)
    }, [darkMode])

    const toggleDarkMode = () => {
        localStorage.setItem('darkMode', JSON.stringify(!darkMode))
        applyTheme(!darkMode)
        enableDarkMode(!darkMode)
    }

    let loginBackground = {
        /* backgroundImage: `url(${bg_piram})`, */
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        overflow: 'hidden',
        backgroundColor: 'rgba(var(--secondary-color), 0.9)',
        height: '100vh',
        position: 'relative'
    };
    const useStyles = makeStyles(theme => ({
        '@global': {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
        backgroundimage: {
            opacity: 'var(--opacity)',
            position: 'absolute',
            left: 0,
            top: '-15%',
            width: '170%',
            height: 'auto',
        },
        paper: {
            backgroundColor: 'transparent',
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        form: {
            backgroundColor: 'transparent',
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            backgroundColor: 'transparent',
            margin: theme.spacing(3, 0, 2),
        },
        image: {
            width: '100%',
            paddingBottom: '40px'
        }
    }));
    const context = React.useContext(AppContext);

    const [state, setState] = useState({
        loading: false
    });

    const [show_mfa, set_show_mfa] = useState(false);

    /**
     * handle on click button
     * @param {object} event 
     * @param {object} state 
     */
    const handleClickButton = (event, state) => {
        event.preventDefault();
        if(state.username.trim() !== "" && state.password.trim() !== ""){
            fetch(Constants.api + 'webApp/login/login', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    user: state.username.trim(),
                    password: state.password.trim(),
                    mfa_code: state.mfa_code.trim()
                })
            })
            .then(response => {
                return response.json()
            })
            .then(json => {
                if(json.status === 1){
                    context.setAuthentication(true);
                }else if(json.status === 0 && json.reason !== ""){
                    swal('Error', t(json.reason), "error");
                    if(json.reason === "secure_code"){
                        set_show_mfa(true);
                    }
                }else{
                    swal('Error', t('messageErrorPassword'), "error");
                }
            }).catch(error => {
                swal('Error', error.toString(), "error");
            });
        }else{
            swal(t('warningText'), t('loginValidation'), "warning");
        }
    }

    const handleForgotPassword = (e) => {
        e.preventDefault();
        swal({
            title: t('resetConfirmTitle'),
            text: t('resetConfirmText'),
            icon: "info",
            closeOnClickOutside: false,
            target: 'body',
            content: {
                element: "input",
                attributes: {
                    placeholder: t('resetUser'),
                    type: "text",
                    name: "forgottenUser",
                    id: "forgottenUser",
                    value: '',
                    autofocus: false
                },
            },
            buttons: {
                cancel: {
                    text: t('resetCancel'),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: t('resetSendUser'),
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        }).then((user) => {
            if(user && user.trim() !== ""){
                setState({ ...state, loading: true });
                fetch(Constants.api + 'webApp/login/resetForgottenUser', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        forgottenUser: user.trim()
                    })
                })
                .then(response => {
                    return response.json()
                })
                .then(json => {
                    setState({ ...state, loading: false });
                    if(json.length > 0 && json[0].status === 1){
                        swal(t('successText'), t('resetPassword') + json[0].email,'success');
                    }else{
                        swal(t('showError'), t('resetPasswordNoUser'), "error");
                    }
                }).catch(error => {
                    setState({ ...state, loading: false });
                    console.log(error);
                    swal(t('showError'), t('messageErrorLogin'), "error");
                });
            }else{
            }
        });
    };

    const classes = useStyles();
    return (
    <div className="Login" style={loginBackground}>
        <img className={classes.backgroundimage} src={bg_piram} alt="bg"></img>
        {
            (state.loading) ? <Loading /> :
            <Container component="main" maxWidth="xs" style={{ backgroundColor: 'transparent' }}>
                <div className={classes.paper}>
                    <div style={{
                        display: 'flex',
                        position: 'absolute',
                        right: 0,
                        marginRight: '1.5rem',
                        marginTop: '1rem',
                        top: 0,
                        zIndex: '0',
                        overflow: '',
                        alignSelf: 'center',
                        width: '40px',
                        height: '40px',
                        background: 'transparent'
                    }}>
                        <div style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        display: 'flex',
                        alignSelf: 'center',
                        zIndex: '0',
                        transform: !darkMode ? 'rotate(-180deg)' : '',
                        transitionProperty: 'transform',
                        transitionTiminFfunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                        transitionDuration: '200ms',
                        right: '-13rem',
                        bottom: '10px',
                        background: 'transparent'
                        }}>

                        <div id='darkmodeIcon' style={{ background: 'transparent', height: '20px', width: '20px', marginRight: '12rem' }} onClick={() => toggleDarkMode()}>
                            <DarkIcon/>
                        </div>
                        <div id='darkmodeIcon' style={{ background: 'transparent', height: '20px', width: '20px'  }} onClick={() => toggleDarkMode()}>
                            <LightIcon />
                        </div>
                        </div>
                    </div>
                    <img style={{ zIndex: '2', position: 'relative', width: '100%' }} src={logo_eduscore} alt="Logo Eduscore"></img>
                    <Login style={useStyles} onClick={handleClickButton} btnText={t('loginBtn')} forgotPassword={handleForgotPassword} forgotPasswordText={t('forgotPasswordText')} userText={t("loginUser")} passText={t("loginPassword")} mfaText={t("mfaText")} mfa_alert_title={t("mfa_alert_title")} mfa_alert_text={t("mfa_alert_text")} show_mfa={show_mfa} />
                </div>
                <Grid container style={{position: 'relative', bottom: 0, marginTop: "20px"}}>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Typography>© Eduscore v. {Constants.site_version}</Typography>
                        <Typography>©Todos los derechos reservados.</Typography>
                    </Grid>
                </Grid>
            </Container>
        }
    </div>
    );
}
