import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import {showError} from '../utilities/errorOnFetch';
import swal from '@sweetalert/with-react';


const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 16,
    margin: `0 0 ${8}px 0`,
    borderRadius: 10,
    borderStyle: 'outset red',
    borderColor: 'red',
    color: 'rgb(var(--primary-color))',
    // change background colour if dragging
    background: isDragging ? 'rgba(var(--complementary), 0.8)' : 'rgb(var(--complementary))',
    // styles we need to apply on draggables
    ...draggableStyle
});

const getItemStyleAsigned = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    //padding: 2,
    margin: 'auto',
    borderRadius: 10,
    //width: '50%',
    //color: '#DFE5E2',
    borderStyle: 'outset',
    color: 'rgb(var(--primary-color))',
    // change background colour if dragging
    background: isDragging ? 'rgba(var(--complementary), 0.8)' : 'rgb(var(--complementary))',
    // styles we need to apply on draggables
    ...draggableStyle
});


/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination, id) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const getIndex = sourceClone.findIndex(obj => obj.id === parseInt(id));
    //console.log('Con indice', getIndex)
    /// sacar el index de source by id
    //console.log('Removed', removed)
    const [removed] = sourceClone.splice(getIndex, 1);
    //console.log('Quito', removed)
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
};

/**
 * Styles for view screen in te chat screen
 */
const useStyles = makeStyles(theme => ({
    search: {
        height: '300',
        padding: 5,
        marginLeft: '5%'
    },
    margin: {
        margin: theme.spacing(1),
    },
    text: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        marginTop: 190,
        fontSize: 20,
        color: '#37474f'
    }
}));

/**
 * Styles for textfield
 */
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'orange',
            },
        },
    },
})(TextField);

export function TeacherAssignationScreen(props) {
    const [filter, setFilter] = useState('')
    const classes = useStyles()
    const { t } = useTranslation()
    const [state, setState] = useState({
        items: props.data.items,
        selected: props.data.selected
    })
    //console.log(state)
    /**
     * Filter by name and description
     */
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = state.items.filter(item => {
        return item.name.toLowerCase().includes(lowercasedFilter) ||
            item.description.toLowerCase().includes(lowercasedFilter)
    });

    const id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    /**
     * Get items by index
     * @param {String} id 
     */
    const getList = id => {
        switch (id2List[id]) {
            case 'items': return state.items;
            case 'selected': return state.selected;
            default: break;
        }
    }

    const onDragEnd = result => {
        const { draggableId, source, destination } = result;
   
        if (!destination) {
            return;
        }
        if (source.droppableId !== destination.droppableId) {
            fetchSubjects(draggableId); //Communication with API 
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination,
                draggableId
            );

            setState({
                ...state,
                items: result.droppable,
                selected: result.droppable2
            });
        }
    };

    /**
     * Fetch data using the API
     */
    const fetchSubjects = (idSubject) => {
        fetch(Constants.api + 'webApp/assignation/setSubjects', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idSubject: idSubject
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {  })
        .catch(error => {
            swal("Error",
            showError(t('errorData'), error, t),
            "error");
        });
    }

    /**
     * handleChange for the search
     * @param {object} event 
     */
    const handleChange = event => {
        setFilter(event.target.value);
    };

    return (
        <div style={{ display: 'flex', background: 'red' }} >
            <DragDropContext onDragEnd={onDragEnd} >
                <div style={{ flex: 1, background: 'rgb(var(--secondary-color))' }}>
                    <Tooltip title={t('drag')} placement="top">
                        <center><p style={{ fontSize: 20, marginLeft: "5%", marginBottom: 104}}><b>{t('subjectsAssigned')}</b>
                            <Button className={classes.button}>
                                <InfoIcon fontSize="small" style={{ color: 'orange' }} />
                            </Button>
                        </p></center>
                    </Tooltip>
                    <Droppable droppableId="droppable2" >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef} //style
                                style={{
                                    padding: 15,
                                    borderRadius: 20,
                                    boxShadow: '5px 5px 8px rgb(var(--primary-color))',
                                    marginTop: 10,
                                    marginBottom: 20,
                                    backgroundColor: 'rgb(var(--primary-color))',
                                    overflow: 'scroll',
                                    height: 600,
                                    marginRight: '5%',
                                    marginLeft: '5%'
                                }}
                            >
                                {state.selected.map((item, index) => (
                                    <Draggable
                                        key={item.id.toString()}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyleAsigned(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                <center><p><b style={{ color: 'rgb(var(--primary-color))' }}>{item.name}</b></p></center>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
                <div style={{ flex: 1, background: 'rgb(var(--secondary-color))' }}>
                    <center><p style={{ fontSize: 20 }}><b>{t('subjects')}</b></p></center>
                    <div className={classes.search} autoComplete="off" style={{ flex: 1, background: 'rgb(var(--secondary-color))' }}>
                        <CssTextField
                            className={classes.margin}
                            label={t('search')}
                            variant="outlined"
                            id="custom-css-outlined-input"
                            style={{ boxShadow: '5px 5px 10px rgba(0,0,0,0.05)' }}
                            value={filter}
                            onChange={handleChange}
                        />
                    </div>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={{
                                    padding: 15,
                                    borderRadius: 20,
                                    boxShadow: '5px 5px 8px gray',
                                    backgroundColor: 'rgb(var(--primary-color))',
                                    overflow: 'scroll',
                                    height: 600,
                                    marginRight: '5%',
                                    marginLeft: '5%'
                                }}
                            >
                                {filteredData.map((item, index) => (
                                    <Draggable
                                        key={item.id.toString()}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                <p style={{ color: 'rgb(var(--primary-color))' }}><b style={{ color: 'rgb(var(--primary-color))' }}>{t('name')}:</b> {item.name}</p>
                                                <p style={{ color: 'rgb(var(--primary-color))' }}><b style={{ color: 'rgb(var(--primary-color))' }}>{t('description')}:</b> {item.description}</p>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <br />
                </div>
            </DragDropContext>
        </div>
    );
}

/**
 * Teacher asingnation screen
 */
export function TeacherAssignation() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState([]);
    const [error, setError] = useState({
        flag: false,
        status: ''
    });

    /**
     * Fetch data using the API
     */
    const fetchData = () => {
        fetch(Constants.api + 'webApp/assignation/getSubjects', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => {
                if(!response.ok) {
                    throw response.status;
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                setState(data); //save the json content
                if (data.message !== undefined) {
                    setError({
                        ...error,
                        flag: true,
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(error => {
                setError({
                    ...error,
                    flag: true,
                    status: error
                });
                //swal("Error", t('informationError'), "error");
            });
    }

    /**
     * Init state, using eslint disable for ignore the warning
     */
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    return (
        (error.flag) ? <ErrorOnLoading type={error.status}/> :
            (loading) ? <Loading /> :
                (state.items !== undefined )?
                <TeacherAssignationScreen
                    data={state}
                />:
                <p className={classes.text} >{t('noAccess')}</p>
    );
}