import { useTranslation } from 'react-i18next';

/**
 * Routes
 */
export const Routes = {
    '/blank': 'Test',
    '/schedule': 'Schedule',
    '/teacher_grades': 'TeacherGradesView',
    '/profile': 'Profile',
    '/grades': 'StudentGradesView',
    '/balance': 'StudentBalanceView',
    '/leadBalance': 'LeadBalanceView',
    '/googleDrive_student': 'StudentDriveView',
    '/class_student': 'ClassOnline',
    '/chat_users': 'Chat',
    '/googleDrive_teacher': 'TeacherDriveView',
    '/logout': 'Logout',
    '/teacher_absence': 'TeacherAbsenceView',
    '/support': 'Support',
    '/teacher_assignation': 'TeacherAssignation',
    '/schedule_editable': 'Availability',
    '/documents' : 'Documents',
    '/billing': 'Billing',
    '/teacher_evaluation': 'PendingEvaluationView',
    '/job_offer': 'JobOfferView',
    '/social_service': 'SocialServiceView',
    '/intern_service': 'InternServiceView',
    '/dynamic_schedule': 'DynamicScheduleView',
    '/change_account': 'ChangeAccountView',
    '/discord': "DiscordView",
    '/absence_admin': "TeacherAbsenceAdminView",
    '/introduction': "IntroductionView",
    '/attendee_surveys': "AttendeeSurveysView",
    'professional-Practices': "ProfessionalPracticesView"
}

/**
 * For translate the title in the drawer
 * @param {string} title 
 */
export const TitleLocalization = (title) => {
    const { t } = useTranslation();
    return TranslationTitle(title, t);
}

export const TranslationTitle = (title, t) => {
        return t(title);
}