import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import Tooltip from '@material-ui/core/Tooltip';

/**
 * Drive card
 * @param {map} param0 
 */
const DriveCard = ({data}) => {
    const { t } = useTranslation();
    const useStyles = makeStyles(theme => ({
        card: {
            backgroundColor: "#f5f5f5"
        },
        cardContent: {
            flexGrow: 1,
        },
        cardBtn: {
            backgroundColor: "#1e88e5",
            color: "white",
            "&:hover": {
                backgroundColor: "#1e88e599"
            },
        },
        cardLinkTitle: {
            fontWeight: "700"
        }
      }));
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Tooltip title={data.teacher?data.teacher:""} arrow="true">
                    <Typography gutterBottom className={classes.cardLinkTitle} noWrap={true}>
                        {data.teacher}
                    </Typography>
                </Tooltip>
                <Tooltip title={data.title?data.title:""} arrow="true">
                    <Typography gutterBottom className={classes.cardLinkTitle} noWrap={true}  >
                        {data.title}
                    </Typography>
                </Tooltip>
                <Typography>
                    <b>{t('driveLinkSubject')}</b>: {data.subjectName}
                </Typography>
                <Typography>
                    <b>{t('driveDate')}</b>: {data.sharedDate}
                </Typography>
            </CardContent>
            <CardActions>
                <Button fullWidth={true} className={classes.cardBtn} variant="contained" color="primary" href={data.driveUrl} target="_blank">
                    {t('driveLinkUrl')}
                </Button>
            </CardActions>
        </Card>
    );
}

/**
 * Component react for student drive
 */
class StudentDriveView extends React.Component{
    /**
     * constructor
     * @param {object} props 
     */
    constructor(props) {
        super(props);
        this.state = {links: []};
    }

    /**
     * Init state
     */
    componentDidMount(){
        fetch(Constants.api + 'webApp/student/getGoogleDriveUrls', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => {
            return response.json()
        })
        .then(json => {
            if(json.length > 0){
                this.setState({
                    ...this.state,
                    links: json
                });
            }else{
                console.log("Sin información");
                console.log(json);
            }
        }).catch(error => {
            console.log("Error");
            console.log(error);
        });
    }

    /**
     * render
     */
    render(){
        return (
            <Grid container spacing={3} direction="row" justify="center" alignItems="center" style={{ padding: 20 }}>
                <Grid container item xs={12} sm={12} md={12} lg={6} spacing={3} direction="row" justify="center" alignItems="center" >
                    {
                    this.state.links.length > 0?
                        this.state.links.map((link, i) => {
                            return (
                                <Grid item key={i} xs={12} sm={7} md={5} lg={6} >
                                    <DriveCard data={link} />
                                </Grid>
                            );
                        }):null
                    }
                </Grid>
            </Grid>
        );
    }
};

export {StudentDriveView};