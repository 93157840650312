import React, { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import logo_eduscore from '../../utilities/images/logo_eduscore.png';
import welcome_image from '../../utilities/images/welcome_image.png';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import InfoIcon from '@material-ui/icons/Info';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import './login.css'

/**
 * Login component
 * @param {object} props 
 */
const Login = (props) => {
    const { t } = useTranslation();
    const [transition, setTransition] = useState(false)
    const [text, setText] = useState(false)
    const [opacity, setOpacity] = useState(false)
    const [state,setState] = React.useState({
        showPassword: 'password',
        username: '',
        password: '',
        mfa_code: ''
    });
    const nodeRef = useRef(null);
    const mediaMatch1024 = window.matchMedia('(min-width: 1024px)');
    const mediaMatch768 = window.matchMedia('(min-width: 768)');
    const [matches1024, setMatches1024] = useState(mediaMatch1024.matches);
    const [matches768, setMatches768] = useState(mediaMatch768.matches);

    /**
     * handle change
     * @param {object} event 
     */
    const handleOnChange = (event) => {
        setState({
            ...state,[event.target.name]: event.target.value
        });
    }

    /**
     * Event click button
     * @param {object} event 
     */

    const CssTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'rgb(var(--primary-color))',
            },
            '& label.Mui': {
                color: 'rgb(var(--primary-color))',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'rgb(var(--primary-color))',
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'rgb(var(--primary-color))',
                },
                borderColor: 'rgb(var(--primary-color))',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: '2px solid rgb(var(--primary-color))'
            },
            '& .MuiInputBase-root': {
                backgroundColor: 'rgb(var(--secondary-color))',
                color: 'rgb(var(--primary-color))'
            },
            '& .MuiInputBase-root::placeholder': {
                color: 'rgb(var(--primary-color))'
            }
        },
    })(TextField);

    useEffect(() => {
        document.addEventListener('keydown', (ev) => {
            if (ev.key === 'Escape' | ev.key === 'Enter') {
                setTransition(false)
                setText(false)
                setOpacity(false)
            }
        })
        setTimeout(() => {
            setTransition(true)
        }, 100)
        setTimeout(() => {
            setOpacity(true)
        }, 1000)
    }, [])

    const timeOut = () => {
        setTimeout(() => {
            setOpacity(true)
        }, 600)
    }

    const transitionStyle = {
        transitionProperty: 'transform, opacity',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1);',
        transitionDuration: '1000ms',
    }

    const hideText = () => {
        setTimeout(() => {
            setText(true)
        }, 1000)
    }

    const handleClickButton = (event) => props.onClick(event, state);
    const handleForgotPassword = (event) => props.forgotPassword(event);
    const handleRedirect = (event) => window.location.href = "https://www.uniat.edu.mx/"
    const classes = props.style();
    const btnText = props.btnText;
    return (
        <div style={{ backgroundColor: 'transparent' }}>
            <InfoIcon className='help-icon' onClick={() => { setTransition(!transition); timeOut(); setText(false) }} />
            <div className={`welcome-mobile ${transition ? 'welcome-mobile-active' : 'welcome-mobile-not-active'}`}>
                <img src={logo_eduscore} alt="Logo Eduscore"></img>
                <p className='title'>{t('initialMessageTitle')}</p>
                <p className='text'>
                    {t('initialMessage')}
                </p>
                <Button
                variant="contained"
                type="submit"
                color="primary"
                style={{backgroundColor: '#ff7600',  width: '100%', maxWidth: '200px'}}
                className={classes.submit}
                onClick={() => { setTransition(false); setOpacity(false) }}>{t('initialMessageButton')}</Button>
            </div>
            <div className={`welcome ${ transition ? 'welcome-active' : 'welcome-not-active'}`}>
                <img className='welcome-image'
                    src={welcome_image} alt="Logo Eduscore">
                </img>
            </div>
            <div className={`welcome-background ${transition ? 'welcome-background-active' : 'welcome-background-not-active'}`}/>
            <div className={`welcome-card ${transition ? 'welcome-card-active' : 'welcome-card-not-active'}`} />
            <div className='welcome-card-content' style={{ visibility: !transition ? 'hidden' : 'visible' }}>
                <img
                className={`welcome-card-logo ${transition ? 'welcome-card-logo-active' : 'welcome-card-logo-not-active'}`}
                src={logo_eduscore} alt="Logo Eduscore"></img>
                <div className={`welcome-card-text ${opacity ? 'welcome-card-text-active' : 'welcome-card-text-not-active'}`} style={{ visibility: text ? 'hidden' : 'visible' }}>
                    <p className='font-size-title'>{t('initialMessageTitle')}</p>
                    <p className='font-size-text'>
                        {t('initialMessage')}
                    </p>

                    <div className='welcome-card-button'>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            style={{backgroundColor: '#ff7600',  width: '100%', maxWidth: '200px'}}
                            className={classes.submit}
                            onClick={() => { setTransition(false); setOpacity(false); hideText(false) }}>{t('initialMessageButton')}</Button>
                    </div>
                </div>

            </div>
        <form className={classes.form} noValidate onSubmit={handleClickButton}>
            <TextField
                style={{ color:'rgb(var(--primary-color)' }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label={props.userText}
                name="username"
                autoComplete="username_eduscore"
                onChange={handleOnChange}
                placeholder={props.userText}
                autoFocus
                value={state.username}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={props.passText}
                type="password"
                id="password"
                placeholder={props.passText}
                autoComplete="current-password"
                value={state.password}
                onChange={handleOnChange}
            />
            {props.show_mfa?
            <div>
            <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="mfa_code"
                label={props.mfaText}
                id="mfa_code"
                placeholder={props.mfaText}
                value={state.mfa_code}
                onChange={handleOnChange}
            />
            <Alert severity="warning" style={{ marginBottom: 20 }}>
                <AlertTitle><b>{props.mfa_alert_title}</b></AlertTitle>
                <Typography>
                    {props.mfa_alert_text}
                </Typography>
            </Alert>
            </div>: null}
            <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
                style={{backgroundColor: '#ff7600'}}
                className={classes.submit}
                
            >
                {btnText}
            </Button>
        </form>
            <Grid container>
                <Grid item xs style={{textAlign: 'left'}} >
                    <Link id='loginLinks' href="#" component="button" variant="body2" onClick={handleForgotPassword}>
                        {props.forgotPasswordText}
                    </Link>
                </Grid>
                <Grid item xs style={{textAlign: 'right'}} >
                    <Link id='loginLinks' href="#" component="button" variant="body2" onClick={handleRedirect}>
                        Ir a uniat.edu.mx
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}

export default Login;
