import React,{useEffect, useState} from 'react';
import {  AppContext } from "../utilities/app-context";
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';


export function Logout() {
    const context = React.useContext(AppContext);
    const [loading, setLoading] = useState(true);
    /**
     * Set online or offline user
     * @param {int} status 
     */
    const setOnline = (status) => {
        fetch(Constants.api + 'webApp/setStatus', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                status: status
            })
        })
            .then(response => {setLoading(false) })
            .catch(error => {setLoading(false) })
    }

    useEffect(() => {
        setOnline(0);
    }, [])

    return (
        (loading)?<Loading />:
        <p>{context.destroySession()}</p>
    );
}