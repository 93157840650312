import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import swal from '@sweetalert/with-react';
import { Constants } from '../../utilities/constants';
import { showError } from '../../utilities/errorOnFetch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';

//moment.tz.setDefault(moment.tz.guess(true));

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    period_text:{
        marginTop: "0.35em"
    } 
}));

function CardsSchedule(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    //hours
    const hours = [
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00'
    ]

    //days
    const days = [
        { name: t('monday'), value: 'Mon' },
        { name: t('tuesday'), value: 'Tue' },
        { name: t('wednesday'), value: 'Wed' },
        { name: t('thursday'), value: 'Thu' },
        { name: t('friday'), value: 'Fri' },
        { name: t('saturday'), value: 'Sat' },
    ]

    //onSubmit={props.handleSubmit}
    return (
        <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="day">{t('day')}</InputLabel>
                            <Select
                                labelId="day"
                                id="day"
                                required
                                name="day"
                                value={props.day || " "}
                                onChange={(e) => props.handleChange(props.index, e)}
                            >
                                {
                                    days.map((data, index) => (
                                        <MenuItem key={index} value={data.value}>
                                            {data.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select> <br />
                        </FormControl>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">{t('startHour')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                required
                                name="startHour"
                                value={props.startHour || " "}
                                onChange={(e) => props.handleChange(props.index, e)}
                            >
                                {
                                    hours.map((data, index) => (
                                        <MenuItem key={index} value={data + ':00'}>
                                            {data}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">{t('endHour')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                required
                                name="endHour"
                                value={props.endHour || " "}
                                onChange={(e) => props.handleChange(props.index, e)}
                            >
                                {
                                    hours.map((data, index) => (
                                        <MenuItem key={index} value={data + ':00'}>
                                            {data}
                                        </MenuItem>
                                    ))
                                }
                            </Select> <br />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{t("availability_schedule_type_title")}</FormLabel>
                            <RadioGroup name="schedule_type" value={props.schedule_type} onChange={(e) => props.handleChange(props.index, e)}>
                                <FormControlLabel value="Presencial" control={<Radio />} label={t("availability_schedule_type_presential")} />
                                <FormControlLabel value="Online" control={<Radio />} label={t("availability_schedule_type_online")} />
                                {
                                props.schedule_type === "Mixto" ?
                                    <FormControlLabel value="Mixto" control={<Radio />} label={t("availability_schedule_type_mix")} />
                                : null
                                }
                            </RadioGroup>
                        </FormControl>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <IconButton style={{ color: '#04A348', display: (props.accepted) ? 'none' : null }} onClick={() => props.acceptSchedule(props.index)} >
                            <CheckIcon />
                        </IconButton>
                        <IconButton style={{ color: '#FE0B28' }} onClick={() => props.cancelCard(props.index)} >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

/**
 * Availability component
 * @param {Object} props 
 */
export function Availability(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [count, setCount] = useState(0);
    const [state, setState] = useState([]);
    const [availability_period, setAvailability_period] = useState("");

    /**
     * On init component
     */
    useEffect(() => {
        let aux = []
        let auxCount = 0
        for (let data of props.data){
            let start_time = moment(data.startTime, "HH:mm:ss").tz(moment.tz.guess(true)).format("HH:mm:ss");
            let end_time = moment(data.endTime, "HH:mm:ss").tz(moment.tz.guess(true)).format("HH:mm:ss");
            aux.push({
                id: auxCount,
                accepted: true,
                day: data.day,
                startHour: start_time,
                endHour: end_time,
                schedule_type: data.schedule_type,
                idAvailability: data.idAvailability
            })
            auxCount ++
        }

        setState(aux);
        setCount(auxCount);
        setAvailability_period(props.period);
    // eslint-disable-next-line
    }, [])

    /**
     * On handle change from components html
     * @param {Object} event 
     */
    const handleChange = (id, event) => {
        const { name, value } = event.target
        // update data
        let newArray = state.map((item) => {
            if (item.id === id) {
                // add data
                return { ...item, [name]: value, accepted: false }
            } else {
                return item
            }
        })
        setState(newArray)
    };

    /**
     * The hours is between Hours example
     * Mon 09:00:00 - 13:00:00 coming 09:30:00 - 12:00 the first hour 9 is between 9 and 13
     */
    const isBetweenHours = (startHour, endHour, day, id) => {
        let insertStartHour = parseInt(startHour.split(':')[0])
        let insertMinutesStartHour = parseInt(startHour.split(':')[1])

        let insertEndHour = parseInt(endHour.split(':')[0])
        let insertMinutesEndHour = parseInt(endHour.split(':')[1])

        for (let data of state) {
            if (data.day === day) {
                let getStartHour = parseInt(data.startHour.split(':')[0])
                let getEndHour = parseInt(data.endHour.split(':')[0])
                //9 - 20
                if ((insertStartHour >= getStartHour && insertStartHour <= getEndHour) && id !== data.id && data.accepted) {
                    if (insertMinutesStartHour !== 0 && insertStartHour === getEndHour) { // 30 minutes or different to 0
                        return false
                    } else {
                        return true
                    }
                //compare end hour
                }else if((insertEndHour >= getStartHour && insertEndHour <= getEndHour) && id !== data.id && data.accepted){
                    if (insertMinutesEndHour !== 0 && insertEndHour === getEndHour) { // 30 minutes or different to 0
                        return false
                    } else {
                        return true
                    }
                }
            }
        }
        return false
    }

    // Validate start hour with endHour
    const validationHour = (data) => {
        let getStartHour = parseInt(data.startHour.split(':')[0]) // get the first hour only number 07:00:00 -> 07
        let getEndHour = parseInt(data.endHour.split(':')[0])
        let day = data.day

        // if the first hour is 11 and the last hour is 9 or it's 11 and 11
        if (getStartHour >= getEndHour) {
            // if is the same hour check the minutes
            if (getStartHour === getEndHour) {
                //last hour has minutes for example last hour 07:30
                let minutesLastHour = parseInt(data.endHour.split(':')[1]) // 30 or 0
                if (minutesLastHour === 0) {
                    swal("Horario", t('conflictMayorHour'), "error")
                    return false
                } else {
                    //here
                    if (isBetweenHours(data.startHour, data.endHour, day, data.id) && state.length > 1) {
                        swal("Error", t('conflictSameHour'), "error")
                        return false
                    } else {
                        return true
                    }
                }

            } else {
                swal("Horario", t('conflictMayorHour'), "error")
                return false
            }
        }
        else {
            //return true
            let startHour = data.startHour // start hour 09:00:00
            let continueHour = false
            //validate if it's continue
            for (let data of state) {
                if (data.day === day && data.endHour === startHour && data.accepted) {
                    continueHour = true
                    break
                }
            }

            if (continueHour && state.length > 1) {
                swal("Error", t('errorContinueHour'), "error")
                return false
            } else {
                //here
                if (isBetweenHours(data.startHour, data.endHour, day, data.id) && state.length > 1) {
                    swal("Error", t('conflictSameHour'), "error")
                    //console.log('Estas horas ya fueron registradas estan entre')
                    return false
                } else {
                    return true
                }
            }
        }
    }

    /**
     * Fetch Availability
     * @param {Object} data
    */
    const fetchAvailability = async (data) => {
        let request_data = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            request_data[index] = JSON.parse(JSON.stringify(element));
            let start_time = moment.tz(element.startHour, 'HH:mm', moment.tz.guess(true));
            let end_time = moment.tz(element.endHour, 'HH:mm', moment.tz.guess(true));
            request_data[index].startHour = moment(start_time).tz(Constants.timeZone).format("HH:mm:ss");
            request_data[index].endHour = moment(end_time).tz(Constants.timeZone).format("HH:mm:ss");
        }
        const response = await fetch(Constants.api + 'webApp/availability/setAvailability', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                availability: request_data
            })
        })
        const info = await response.json()
        return info
    }

    /**
     * FetchDeleted availability
     * @param {Integer} idAvailability 
     */
    const fetchDeletedAvailability = async (idAvailability) =>{
        const response = await fetch(Constants.api + 'webApp/availability/deleteAvailability', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idAvailability: idAvailability
            })
        })
        const info = await response.json()
        return info
    }

    /**
     * Accept schedule
     */
    const acceptSchedule = async (id) => {
        // get data updated
        let data = state.filter(item => {
            if(item.id === id){
                console.log(item.startHour);
                console.log(item.endHour);
                return true;
            }
            return false;
        });

        // validate if 09:00 and 07:00 is false and continue hour
        if (validationHour(data[0])) { // if there isn't any problem
            const response = await fetchAvailability(data) // save in database
            if (response.status){  // success
                //update the data in the view
                let newArray = state.map((item, index) => {
                    if (item.id === id) {
                        // add data
                        return {
                            ...item,
                            accepted: true,
                            day: data[0].day,
                            startHour: data[0].startHour,
                            endHour: data[0].endHour,
                            idAvailability: response.data
                        }
                    } else {
                        return item
                    }
                })
                setState(newArray)
            }else{
                swal('Error', t('requestError'), "error")
            }
        }
    }


    /**
     * Cancel schedule
     */
    const cancelSchedule = async (id) => {
        let data = state.filter(item => item.id === id) // get the data
        let idAvailability = data[0].idAvailability

        if(idAvailability == 0){
            //not saved in database
            setState(state.filter(item => item.id !== id))
        }else{
            const response = await fetchDeletedAvailability(idAvailability)
            // status success
            if (response.status){
                //show the change in the view
                setState(state.filter(item => item.id !== id))  
            }else{
                swal('Error', t('requestError'), "error")
            }
        }
    }

    /**
     * Set a new card
     */
    const newSchedule = () => {
        setState([
            ...state, {
                id: count,
                accepted: false,
                day: 'Mon',
                startHour: '07:00:00',
                endHour: '22:00:00',
                schedule_type: "Presencial",
                idAvailability: 0
            }])

        setCount(count + 1)
    }

    /**
     * Save 
     */
    return (
        <div className={classes.root} style={{ marginRight: '10%', marginLeft: '10%' }} >
            {
                availability_period !== "" && availability_period?
                <Typography variant="h3" align="center" className={classes.period_text} gutterBottom>
                    {availability_period}
                </Typography>:null
            }
            <Alert severity="info" className={classes.info_message}>
                <AlertTitle>{t('important_text')}</AlertTitle>
                <Typography>
                    {t('availability_alert_text')}
                </Typography>
            </Alert>
            <Grid item xs={12} sm={3}>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        fullWidth={true}
                        variant='contained'
                        style={{
                            backgroundColor: '#ff7600',
                            marginTop: 20,
                            marginBottom: 20,
                            color: 'white'
                        }}
                        onClick={() => newSchedule()}>
                        {t('new')}
                    </Button>
                </Grid>
            </Grid>
            
            <Grid container spacing={3}>
                {
                    state.map((item, index) => (
                        <CardsSchedule
                            key={index}
                            cancelCard={cancelSchedule}
                            acceptSchedule={acceptSchedule}
                            index={item.id}
                            accepted={item.accepted}
                            startHour={item.startHour}
                            endHour={item.endHour}
                            day={item.day}
                            handleChange={handleChange}
                            schedule_type={item.schedule_type}
                        />
                    ))
                }
            </Grid>
        </div>
    )
}