import Frame from './views/Frame';
import React, { Component } from 'react';
import LoginView from './views/LoginView';
import { AppContext } from './utilities/app-context';
import { Constants } from './utilities/constants';
import './index.css';

class App extends Component {
    constructor(props) {
        super(props);

        this.setAuthentication = (isAuthenticated) => {
            this.setState((state) => ({
                ...this.state,
                isAuthenticated: isAuthenticated
            }));
        }

        this.verifySession = () => {
            this.setState((state) => ({
                ...this.state,
                isAuthenticated: false
            }));
            fetch(Constants.api + 'webApp/verifySession', {
                    method: 'GET',
                    credentials: 'include'
                })
                .then(response => {
                    if (response.status === 200) {
                        this.setState((state) => ({
                            ...this.state,
                            isAuthenticated: true
                        }));
                    } else {
                        this.setState((state) => ({
                            ...this.state,
                            isAuthenticated: false
                        }));
                    }
                }).catch(error => {
                    console.log("Error");
                    console.log(error);
                });
        }

        this.destroySession = () => {
            fetch(Constants.api + 'webApp/destroySession', {
                    method: 'GET',
                    credentials: 'include'
                })
                .then(response => {
                    if (response.status === 200) {
                        this.setState((state) => ({
                            ...this.state,
                            isAuthenticated: false
                        }));
                    } else {
                        console.log(response);
                    }
                }).catch(error => {
                    console.log("Error");
                    console.log(error);
                });
        }

        this.state = {
            isAuthenticated: null,
            setAuthentication: this.setAuthentication,
            verifySession: this.verifySession,
            destroySession: this.destroySession
        };
    }

    componentDidMount() {
        document.title = 'Eduscore | UNIAT Platform';
        this.verifySession();
    }

    render() {
        return ( <AppContext.Provider value = { this.state } > {
            this.state.isAuthenticated === null ? null : this.state.isAuthenticated === true ?
                <Frame / > :
                <LoginView / >
        }
        </AppContext.Provider>
        );
    }
}
App.contextType = AppContext;
export default App;
