import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Constants} from '../utilities/constants';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import swal from '@sweetalert/with-react';
import Loading from '../utilities/Loading';
import EvaluationQuestionView from './EvaluationQuestionView';

const EvaluationInfo = ({data}) => {
    const useStyles = makeStyles(theme => ({
        text: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            marginTop: 190,
            fontSize: 20,
            color: '#37474f'
        },
        large: {
            width: 90,
            height: 90,
        },
    }));
    const classes = useStyles();
    return (
        <Table size="medium" stickyHeader >
            <TableBody>
                <TableRow className={classes.tr} hover >
                    <TableCell align="center" className={classes.thead}>
                        <Typography>
                            {`${data.name}`}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.thead}>
                        <Typography>
                            {`${data.periodName}`}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default function EvaluationView ({data, refresh}) {
    const { t } = useTranslation();
    const [enableSaving, setUpdateSaving] = React.useState(false);
    const [formEvaluation, setFormEvaluation] = React.useState({
        loader: false
    });

    /*const useStyles = makeStyles(theme => ({
        tabTitle: {
            color: 'black',
            fontWeight: "bold"
        },
        tabTitleText: {
            color: 'black',
            fontWeight: 600
        },
        panelTitle: {
            backgroundColor: "#e9ecf3"
        }
    }));

    const classes = useStyles();*/

    const [radios, setRadio] = React.useState({
        radios: {},
        radiosLength: 0
    });

    const handleRadioChange = (event) => {
        let radioControl = radios.radios;
        let radioControlLength = radios.radiosLength;
        radioControlLength = (radioControl[event.target.name] === undefined)?radioControlLength + 1:radioControlLength;
        radioControl[event.target.name] = event.target.value;
        setRadio({...radios,radios: radioControl, radiosLength: radioControlLength});
        if(radioControlLength == (data.questions.length) ){
            setUpdateSaving(true);
        }else{
            setUpdateSaving(false);
        }
    };

    const radioControlFunction = (name, value) => {
        console.log(name);
        let radio = radios.radios[name];
        if(radio == value){
            return true;
        }
        return false;
    };

    const saveEvaluation = () => {
        if(radios.radiosLength === (data.questions.length)){
            setFormEvaluation({
                ...formEvaluation, loader: true
            });
            fetch(Constants.api + 'webApp/student/saveEvaluation', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    idEvaluationRegister: data.evaluation.idEvaluationRegister,
                    questions: JSON.stringify(radios.radios)
                })
            })
            .then(response => {
                return response.json()
            })
            .then(json => {
                setFormEvaluation({
                    ...formEvaluation, loader: false
                });
                if(json.success == true){
                    refresh();
                    swal(t('successText'), t('evaluationSuccess'), "success");
                }else{
                    swal('Error', t('evaluationFail'), "error");
                }
            }).catch(error => {
                console.log(error);
                setFormEvaluation({
                    ...formEvaluation, loader: false
                });
                swal('Error', t('messageError'), "error");
            });
        }else{
            swal(t('evaluationSavingErrorTitle'), t('evaluationSavingErrorTxt'), "warning");
        }
    };

    const evaluationAnswers = [
        { answer: t("evaluationsAnswer1"), value: 1},
        { answer: t("evaluationsAnswer2"), value: 2},
        { answer: t("evaluationsAnswer3"), value: 3},
        { answer: t("evaluationsAnswer4"), value: 4},
        { answer: t("evaluationsAnswer5"), value: 5},
    ];

    return (
        <div style={{ background: 'rgb(var(--secondary-color))' }}>
        {
            formEvaluation.loader ? <Loading /> :
            <Container style={{marginTop: 20, background: 'rgb(var(--secondary-color))'}}>
                <Grid key={`gridEvaluations`} container spacing={3} direction="row" justify="center" >
                    <Grid item key={`gridEvaluationsTitle`} xs={12} sm={12} md={12} lg={12}  >
                        <EvaluationInfo data={data.evaluation} />
                    </Grid>
                    <Grid item key={`gridEvaluationsContent`} xs={12} sm={12} md={12} lg={12}  >
                        <div style={{ background: 'rgb(var(--secondary-color))' }}>
                            {
                                data.questions.map((question, q) => {
                                    return (
                                        <EvaluationQuestionView question={question} key={`gridEvaluationsQuestionsE${question.idEvaluation}Q${question.idQuestion}`} radioControlFunction={radioControlFunction} handleRadioChange={handleRadioChange} answers={evaluationAnswers} />
                                    );
                                })
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Button variant="contained" color="primary" fullWidth disabled={!enableSaving} onClick={saveEvaluation} >
                            {t("evaluationSend")}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        }
        </div>
    );
} 