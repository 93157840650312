import React, {useState, useEffect} from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';
import { Constants } from '../utilities/constants';
import swal from '@sweetalert/with-react';
import {showError} from '../utilities/errorOnFetch';
import { createMuiTheme, MuiThemeProvider, AppBar, Tabs, Tab, Container, Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

/**
 * Styles for support
 */
const useStyles = makeStyles(theme => ({
    root:{
        padding: 15,
        '& > *': {
            margin: theme.spacing(1),
        },
        ['@media (min-width:600px)']:{// eslint-disable-line no-useless-computed-key
            padding: 15,
            marginRight: '20%' , 
            marginLeft: '20%',
            '& > *': {
            margin: theme.spacing(1),
            },
        },
    },
    textFieldLarge:{
        width: '100%',
        ['@media (min-width:600px)']: {// eslint-disable-line no-useless-computed-key
            width: '94%',
        }
    },
    textFieldMid:{
        width: '100%',
        marginTop: 30,
        ['@media (min-width:600px)']: {// eslint-disable-line no-useless-computed-key
            width: '46%',
            marginTop: 30,
        }
    },
    textFieldSmall:{
        width: '100%',
        ['@media (min-width:600px)']: {// eslint-disable-line no-useless-computed-key
            width: '30%'
        }
    },
    selectMid:{
        width: '100%',
        ['@media (min-width:600px)']: {// eslint-disable-line no-useless-computed-key
            width: '94%'
        }
    },
    radio: {
        '&$checked': {
          color: '#FF7600'
        }
    },
    checked: {}
}));

/**
 * Styles for textfield
 */
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'orange',
            },
        },
    },
})(TextField);

/**
 * Message label
 * @param {Object} props 
 */
function MessageLabel(props) {
    return(
        <div style={{ backgroundColor: 'rgb(var(--secondary-text))' }}>
            <br/>
            <div style={{marginTop:5, backgroundColor: 'rgb(var(--secondary-text))'}}>
                <FormLabel><b>{props.text}</b><br/></FormLabel>
            </div>
        </div>
    );
}

/**
 * Billing items
 * @param {Object} data
 */
const BillingScreen = ({data, refreshFunction, regime}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [state, setState] = useState({
        mainEmail : data.email === undefined ? '' : data.email,
        optionEmail: data.email2 === undefined ? '' : data.email2,
        socialReason: data.razonSocial === undefined ? '' : data.razonSocial,
        rfc: data.rfc === undefined ? '' : data.rfc,
        street: data.calle === undefined ? '' : data.calle,
        numOut: data.numExt === undefined ? '' : data.numExt,
        numInt: data.numInt === undefined ? '' : data.numInt,
        suburb: data.colonia === undefined ? '' : data.colonia,
        cp: data.cp === undefined ? '' : data.cp,
        state: data.estado === undefined ? '' : data.estado,
        city: data.ciudad === undefined ? '' : data.ciudad,
        useCFDI: data.use === undefined ? 'P01' : data.use,
        payMethod: data.payMethod === undefined ? '99' : data.payMethod,
        bank: data.bankName === undefined ? '' : data.bankName,
        numAccount: data.bankAccount === undefined ? '' : data.bankAccount,
        clabe: data.clabe === undefined ? '' : data.clabe,
        profileName: data.profileName === undefined  || data.profileName === null ? '' : data.profileName,
        idBilling: data.idBilling === undefined ? 0 : data.idBilling,
        regime: data.regime === null ? 0 : data.regime
    });

    const default_state_values = {
        mainEmail : '',
        optionEmail: '',
        socialReason: '',
        rfc: '',
        street: '',
        numOut: '',
        numInt: '',
        suburb: '',
        cp: '',
        state: '',
        city: '',
        useCFDI: 'P01',
        payMethod: '99',
        bank: '',
        numAccount: '',
        clabe: '',
        profileName: '',
        idBilling: 0,
        regime: 0
    };

    const form_validation = {
        numOut: {
            length: 64,
            type: "text"
        },
        numInt: {
            length: 64,
            type: "text"
        },
        mainEmail: {
            length: 64,
            type: "text"
        },
        optionEmail: {
            length: 64,
            type: "text"
        },
        socialReason: {
            length: 150,
            type: "text"
        },
        rfc: {
            length: 30,
            type: "text"
        },
        street: {
            length: 50,
            type: "text"
        },
        suburb: {
            length: 50,
            type: "text"
        },
        cp: {
            length: 8,
            type: "text"
        },
        state: {
            length: 50,
            type: "text"
        },
        city: {
            length: 50,
            type: "text"
        },
        bank: {
            length: 50,
            type: "text"
        },
        numAccount: {
            length: 50,
            type: "text"
        },
        clabe: {
            length: 50,
            type: "text"
        },
        profileName: {
            length: 16,
            type: "text"
        }
    }

    /**
     * Event for change state
     * @param {object} event 
     */
    const handleOnChange = event => {
        let { name, value } = event.target;
        if(name === "rfc"){
            value = value.toUpperCase();
        }
        setState({
            ...state, [name]: value
        })
    }

    //Validate numbers
    const validation = () => {
        let is_error = false;
        for (const key in state) {
            if (Object.hasOwnProperty.call(state, key) && form_validation.hasOwnProperty(key)) {
                const element = state[key];
                const validation = form_validation[key];
                if(element.length > validation.length){
                    if(validation.type === "number"){
                        swal('Error', t('numLength'), "error");
                    }else if(validation.type === "text"){
                        swal('Error', t('validationLength'), "error");
                    }
                    is_error = true;
                    console.log(key);
                    console.log(element);
                    console.log(element.length);
                }
            }
        }
        if (state.regime === 0 || state.regime === undefined){
            is_error = true;
            swal('Error', t('validationRegime'), "error");
        }
        if(!is_error){
            setData();
        }
    }

    /**
     * Submit for the form
     * @param {object} evt 
     */
    const handleSubmit = (evt) => {
        evt.preventDefault();
        //if the text field is less than 6 show the message
        validation();
    }

    const setData = () => {
        fetch(Constants.api + 'webApp/billing/insertBilling', {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: state
            })
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            swal(t('modalSuccessTitle'), t('successBilling'), "success");
            if(state.idBilling === 0){
                setState(default_state_values);
            }
            refreshFunction();
        })
        .catch(error => {
            swal("Error",
            showError(t('errorBilling'), error, t) ,
            "error");
        });
    }

    return (
        <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
            <Alert severity="info">
                <AlertTitle>{t("titleBilling")}</AlertTitle>
                <Typography>
                    {t("billingModuleAlert")}
                </Typography>
                <Typography>
                    {t("billingModuleFiscalInformationAlert")}
                </Typography>
                <br/>
                <Typography>
                    {t("billing_tutorial_video_text")} <Link href={Constants.billing_tutorial_video} color="error" target="_blank" rel="noopener noreferrer">{t("billing_tutorial_video_link_text")}</Link>
                </Typography>
            </Alert>
            <MessageLabel text = {t('contact')} />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                type="email"
                label= {t('mainEmail')}
                className={classes.textFieldLarge}
                value={state.mainEmail}
                onChange={handleOnChange}
                name = "mainEmail"
                required
                inputProps={{maxLength: form_validation.mainEmail.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                type="email"
                label= {t('optionalEmail')}
                className={classes.textFieldLarge}
                value={state.optionEmail}
                onChange={handleOnChange}
                name = "optionEmail"
                inputProps={{maxLength: form_validation.optionEmail.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('businessSocial')}
                className={classes.textFieldMid}
                value={state.socialReason}
                onChange={handleOnChange}
                name = "socialReason"
                required
                inputProps={{maxLength: form_validation.socialReason.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('RFC')}
                className={classes.textFieldMid}
                value={state.rfc}
                onChange={handleOnChange}
                name = "rfc"
                required
                inputProps={{maxLength: form_validation.rfc.length}}
            />
            <MessageLabel style={{ background: 'rgb(var(--secondary-color))' }} text = {`${t('fiscalAddress')} (${t('billing_module_check_fiscal_record')})`} />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('street')}
                className={classes.textFieldSmall}
                value={state.street}
                onChange={handleOnChange}
                name = "street"
                required
                inputProps={{maxLength: form_validation.street.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('numOutdoor')}
                className={classes.textFieldSmall}
                value={state.numOut}
                onChange={handleOnChange}
                name = "numOut"
                required
                inputProps={{maxLength: form_validation.numOut.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('numInterior')}
                className={classes.textFieldSmall}
                value={state.numInt}
                onChange={handleOnChange}
                name = "numInt"
                inputProps={{maxLength: form_validation.numInt.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('suburb')}
                className={classes.textFieldSmall}
                value={state.suburb}
                onChange={handleOnChange}
                name = "suburb"
                required
                inputProps={{maxLength: form_validation.suburb.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('cp')}
                className={classes.textFieldSmall}
                value={state.cp}
                onChange={handleOnChange}
                name = "cp"
                inputProps={{maxLength: form_validation.cp.length}}
                required
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('stateCity')}
                className={classes.textFieldSmall}
                value={state.state}
                onChange={handleOnChange}
                name = "state"
                required
                inputProps={{maxLength: form_validation.state.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('city')}
                className={classes.textFieldSmall}
                value={state.city}
                onChange={handleOnChange}
                name = "city"
                required
                inputProps={{maxLength: form_validation.city.length}}
            />
            <MessageLabel text = {t('moreInfo')} />
            <InputLabel htmlFor="filled-age-native-simple">{t('useCFDI')}</InputLabel>
            <Select
                variant="outlined"
                id="custom-css-outlined-input"
                //value= {t('useCFDI')}
                className={classes.selectMid}
                value={state.useCFDI}
                onChange={handleOnChange}
                name = "useCFDI"
                required
            >
                <MenuItem value={'P01'}>P01 - Por definir</MenuItem>
                <MenuItem value={'G03'}>G03 - Gastos en general</MenuItem>
                <MenuItem value={'D10'}>D10 - Pagos por servicios educativos</MenuItem>
            </Select>
            <InputLabel htmlFor="filled-age-native-simple">{t('payMethod')}</InputLabel>
            <Select
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('payMethod')}
                className={classes.selectMid}
                value={state.payMethod}
                onChange={handleOnChange}
                name = "payMethod"
                required
            >
                <MenuItem value={'99'}>99 - Por definir</MenuItem>
                <MenuItem value={'02'}>02 - Cheque nomitativo</MenuItem>
                <MenuItem value={'03'}>03 - Transferencia electrónica</MenuItem>
                <MenuItem value={'04'}>04 - Tarjeta de crédito</MenuItem>
                <MenuItem value={'28'}>28 - Tarjeta de débito</MenuItem>
            </Select>
            <MessageLabel text = {t('messagePay')} />
            <MessageLabel text = {t('bankAccount')} />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('bank')}
                className={classes.textFieldSmall}
                value={state.bank}
                onChange={handleOnChange}
                name = "bank"
                inputProps={{maxLength: form_validation.bank.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('accountNumber')}
                className={classes.textFieldSmall}
                value={state.numAccount}
                onChange={handleOnChange}
                name = "numAccount"
                inputProps={{maxLength: form_validation.numAccount.length}}
            />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('clabe')}
                className={classes.textFieldSmall}
                value={state.clabe}
                onChange={handleOnChange}
                name = "clabe"
                inputProps={{maxLength: form_validation.clabe.length}}
            />
            <MessageLabel text={`${t('regime')} (${t('billing_module_check_fiscal_record')})`} />
            <Select
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('regime')}
                className={classes.selectMid}
                value={state.regime}
                onChange={handleOnChange}
                name = "regime"
                required
            >
                {
                    regime.map((info, index)=>{
                        return(
                            <MenuItem key={index} value={info.idRegime}>{info.idRegime} - {info.description}</MenuItem>
                        )
                    })
                }
            </Select>
            <MessageLabel text={t('profileNameTitle')} />
            <CssTextField
                variant="outlined"
                id="custom-css-outlined-input"
                label= {t('profileNameInput')}
                className={classes.textFieldLarge}
                value={state.profileName}
                onChange={handleOnChange}
                name = "profileName"
                inputProps={{maxLength: form_validation.profileName.length}}
            />
            <Button 
                variant="contained"
                color="primary"
                type='submit'
                value='Submit'
                style={{ background: '#ff7600', color: 'white', width:'94%' }}
                size='large'
            >
                {t('save')}
            </Button>
        </form>
    );
}

/**
 * Get billing screen
 */
export function Billing(){
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({
        flag:false,
        status: ''
    });
    const [billing, setBilling] = useState([]);
    const [regime, setRegime] = useState([]);

    /**
     * Set data
     */
    const fetchData = () =>{
        fetch(Constants.api + 'webApp/billing/getBilling', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => {
            if(!response.ok) {
                throw response.status;
            }
            else {
                return response.json();
            }
        })
        .then(data => {
            setLoading(false);
            if(data !== undefined){
                setBilling(data.billing);
                setRegime(data.regime);
            }else{
                setBilling([]);
                setRegime([]);
            }
        })
        .catch(error => {
            setError({
                ...error,
                flag: true,
                status: error
            })
        });
    }

    /**
     * Init state
     */
    useEffect(() => {
        fetchData();
    // eslint-disable-next-line
    }, [])

    return(
        (error.flag) ? <ErrorOnLoading type={error.status}/> :
        (loading) ? <Loading /> :
        <BillngTabs
            billing={billing}
            refreshFunction={fetchData}
            regime = {regime}
        />
    );
}

const BillngTabs = ({billing, refreshFunction, regime}) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: '#FFFFFF'
            }
        }
    });

    const useStyles = makeStyles(theme => ({
        tabTitle: {
            color: 'black',
            fontWeight: "bold"
        }
    }));
    const classes = useStyles();
    console.log(billing)
    
    return (
        <div style={{ backgroundColor: 'rgb(var(--secondary-text))' }}>
            <MuiThemeProvider theme={theme}>
                <AppBar position="static" style={{ background: 'red' }}>
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    /* scrollButtons="on" */
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable auto tabs"
                    className={classes.tabTitle}
                    >
                        {
                            billing.map((profile, i) => {
                                return(
                                    <Tab key={`tab${i+1}`} label={`${profile.profileName === null || profile.profileName === "" ? profile.rfc : profile.profileName}`} className={classes.tabTitle} />
                                );
                            })
                        }
                        <Tab key={`tab_new_tab`} label={`${t('billingNewTab')}`} className={classes.tabTitle} />
                    </Tabs>
                </AppBar>
            </MuiThemeProvider>
            <Container style={{ marginTop: 20 }}>
                {
                    billing.map((profile, i) => {
                        return (
                            <Grid key={`grid_tab_${i}`} container spacing={3} direction="row" justifyContent="center" index={i} style={value !== i?{display:"none"}:{}}>
                                <BillingScreen key={`billing${i + 1}`} data={profile} refreshFunction={refreshFunction} regime = {regime} />
                            </Grid>
                        );
                    })
                }
                <Grid key={`grid_tab_new_tab`} container spacing={3} direction="row" justifyContent="center" index={1} style={value !== billing.length?{display:"none"}:{}}>
                    <BillingScreen key={`billing_new_tab`} data={{}} refreshFunction={refreshFunction} regime = {regime} />
                </Grid>
            </Container>
        </div>
    );
}