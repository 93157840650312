import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
/**
 * Component for informartion error
 */
export default function RestrictMessage(props) {
    const { t } = useTranslation();

    return (
        <Container style={{marginTop: 20}}>
            <Alert severity="warning">
                <Typography>
                    {t(props.restrict.reason)}
                </Typography>
            </Alert>
        </Container>
    );
}