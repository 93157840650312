import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation} from 'react-i18next';
import { Constants } from '../utilities/constants';
import Loading from '../utilities/Loading';
import ErrorOnLoading from '../utilities/ErrorOnLoading';

/**
 * Styles for components
 */
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    text: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        marginTop: 190,
        fontSize: 20,
        color: '#37474f'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        //width: '50%',
    }
}));

/**
 * Class online
 */
function ClassOnlineComponent(props) {
    const { t } = useTranslation();
    const classes = useStyles();


    return (
        (props.data.error)? <ErrorOnLoading />: 
        (props.data.loading)? <Loading /> :
        (props.data.url.length === 0)? <p className={classes.text} >{t('noClass')}</p> :
        <div>
            <div className={classes.text}><p>{t('classGoTo')}</p></div>
            <br />
            <a onClick={()=>{console.log('Press')}} href={props.data.url} style = {{textDecoration: 'none'}}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        background: 'orange',
                        color: 'black'
                    }}
                    size='large'
                >
                    {t('goToClass')}
                </Button>
            </a>
        </div>
    );
}

export class ClassOnline extends React.Component {
    
    /**
     * Constructor for class online
     * @param {object} props 
     */
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            url: '',
            error: false

        }
    }

    /**
     * Fetch class using API 
     */
    fetchClass() {
        fetch(Constants.api + 'webApp/class', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                loading: false,
                url: data.url
            })
        })
        .catch(error => {
            this.setState({
                error: true
            })
        });
    }

    /**
     * Init state
     */
    componentDidMount() {
        this.fetchClass();
    }
    
    /**
     * Render component
     */
    render(){
        return(
            <ClassOnlineComponent 
                data = {this.state}
            />
        )
    }
}